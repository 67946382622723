import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import { GlobalData } from '../App';
import { addannouncementApi, allannouncementApi, editannouncementApi, statusannouncementApi } from './serviceapi/Service.home';
import { SweetAlert } from './sweetalert/SweetAlert';
import CustomeTable from './utils/CustomeTable';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Announcement = () => {
  const [value, setValue] = useState('');


  const [openMode , setOpenmode] = useState('all');
  const [EditData , setEditData] = useState([]);



  const [title,setTitle] = useState();
  const [notice,setNotice] = useState();
  const [filterData, setfilterData] = useState([]);

  const [spin , setspin] = useState(false)

  let formRef = useRef();
  /* get api */
  const [preData,setpreData] = useState([]);
  const fetchAllListApi = async () =>{
    let res = await allannouncementApi();
    if(res.status="TXN"){
      setpreData(res.data);
      setfilterData(res.data);
    }
  }
  useEffect(()=>{
    fetchAllListApi();

  }, [])

  /* post api */
  const addListApi = async (e) =>{
    setspin(true);
    e.preventDefault();
    let res = await addannouncementApi({title: title,notice: value});
     if(res.status="TXN"){
      fetchAllListApi();
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true})
      formRef.current.reset();
      setOpenmode('all');
    }
    else{
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
    }
    setspin(false);

  }

  /* put api */
  const putListApi = async (e,data) =>{
    setspin(true);
    e.preventDefault();
    let res = await editannouncementApi({id:data,title: title,notice: value});
    if(res.status="TXN"){
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true})
      fetchAllListApi();
      setOpenmode('all');
    }
    else{
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
    }
    setspin(false);
  }

  /* patch api */
  const statusListApi = async (data,status) =>{
    if(status === 'block') {
      status = 'active'
    }
    else{
      status = 'block' 
    }
    let res = await statusannouncementApi({id:data,status: status});
    if(res.status="TXN"){
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true})
    }
    else{
      SweetAlert({title:'Announcment' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
    }
  }


    /* -- tables -- */

    // const [tableSearch, settableSearch] = useState("")

    // useEffect(()=>{
    //         const result = preData.filter((eq) => {
    //           return eq.title.toLowerCase().match(tableSearch.toLowerCase());
    //         });
    //         setfilterData(result);
    // }, [tableSearch])
            
            
    const columns = [
      {
        name : "S.N",
        cell: (row, index) => index + 1,
        sortable: true,
        width: "80px"
      },
                  {
                    name : "Title",
                    selector : row => row.title,
                    sortable: true,
                  },
                  {
                    name : "Notice",
                    selector : row => <span  dangerouslySetInnerHTML={{ __html:row.notice }}></span> ,
                    sortable: true,
                  },
                  {
                    name : "Status",
                    selector : row => <Form.Check onChange={()=>{statusListApi(row.id,row.status)}} defaultChecked={row.status==="block" ? false : true} type="switch" />,
                    sortable: true,
                  },
                  {
                    name : "Created At",
                    selector : row => row.created_at.slice(0,10),
                    sortable: true,
                  },
                  {
                    name : "Updated At",
                    selector : row => row.updated_at.slice(0,10),
                    sortable: true,
                  },
                  {
                    name : "Action",
                    selector : row => <div  className='icon-tag d-flex align-items-center'><i class="fa-solid fa-user-pen i-x cursor-pointer" onClick={()=>{openEditFn(row); setOpenmode('edit')}}></i></div> ,
                    sortable: true,
                  },
                  
    ]




    const openEditFn = (row) =>{
      setEditData(row);
      setValue(row.notice)
    };
          

    
    let globaldata = useContext(GlobalData);

  return (<>
  
  <div className="container-fluid admin-settings-wrapper">
    <div className="row block1">

    {
          globaldata.fdata==='admin' ? 
          
          <>
          <div className="col-12">
        
        {openMode ==='all' ?  
        <>
       <div className='d-flex justify-content-between mb-2'>  <p>All Announcments</p> <span><button onClick={()=>{setOpenmode('add');setValue('')}} className='btn-green'>Add New</button></span></div>
        <CustomeTable columns={columns} filterData={filterData} search_data={false} />
        
        </>
        : openMode ==='add' ?
 
        <>
        <div className='d-flex justify-content-between mb-2'>  <p>Add Announcement</p> <span><button onClick={()=>{setOpenmode('all'); }} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
             <form action="#" className='row' onSubmit={addListApi} ref={formRef}>
             <div className="form-group my-2">
                 <label htmlFor="">Title</label>
                 <input type="text" name="" id="" className='form-control rounded-0 bd-all' onChange={(e)=>{setTitle(e.target.value)}} />
                 </div>
                 <div className="form-group my-2">
                 <label htmlFor="">Note</label>

                 <div style={{'height' : '250px'}} >
                    <ReactQuill modules={quill_modules} theme="snow" value={value} style={{'height' : '250px'}}  onChange={setValue} className='rounded-0' />
                    </div>
                 </div>
                 <div className="form-group mt-5">
                 {/* <input type="submit" value="Create Now" className='btn-all' /> */}

                <button type='submit'   className='btn-yellow'> Create Now {spin ? <Spinner size='sm' animation="border" variant="light" /> : null}</button>


                 </div>
             </form>
         </div>
        </>
         :
         openMode ==='edit' ?
         <>
        <div className='d-flex justify-content-between mb-2'>  <p>Edit Announcement</p> <span><button onClick={()=>{setOpenmode('all')}} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
             <form action="#" className='row' onSubmit={(e)=>{putListApi(e,EditData.id)}} ref={formRef}>
             <div className="form-group my-2">
                 <label htmlFor="">Title</label>
                 <input type="text" name="" id="" className='form-control rounded-0 bd-all' defaultValue={EditData.title} onChange={(e)=>{setTitle(e.target.value)}} />
                 </div>

                 <div style={{'height' : '250px'}} >
                    <ReactQuill  modules={quill_modules} theme="snow" value={value} style={{'height' : '250px'}}  onChange={setValue} className='rounded-0' />
                    </div>

                 <div className="form-group mt-5">
                <button type='submit'   className='btn-yellow'> Update {spin ? <Spinner size='sm' animation="border" variant="light" /> : null}</button>
                 </div>
             </form>
         </div>
        </>
 
        : null
 
        }
     </div>

          </> : 
          
          <>

          {/* <CustomeTable title='All Announcement' columns={columnsuser} filterData={filterData} search_data={false} /> */}

          </>
    }
     
    </div>

  </div>
  
  
  </>)
}

export default Announcement;


export const quill_modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"],
    [{ 'color': [] }, { 'background': [] }],  
    [{ 'script': 'sub'}, { 'script': 'super' }],  
    ['code']
  ],
  clipboard: {
    matchVisual: false
  }
  };