import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <div className="box d-flex justify-content-center align-items-center bg-3 " style={{
        height : "100vh",
        width : "100vw",
        top : 0,
        left: 0, 
        position: "fixed",
        zIndex : 10000,
                        background: "rgba(255,255,255,0.5)"
                        }}>
       <Spinner size='' variant='warning' color='warning' />
       </div>
  )
}

export default Loading