import React from 'react'
import { Navigate , Outlet } from 'react-router-dom'

const PrivateComponent = () => {
    // let navigate = useNavigate();
    let Auth = localStorage.getItem('access_token')
  return (<>
  
{ Auth ?  <Outlet/> :  <Navigate to='/login' />}


  
  
  </>)
}

export default PrivateComponent