import React, { useContext, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { GlobalData } from '../../App'
import { postSitesettingsApi } from '../serviceapi/Service.home';
import { SweetAlert } from '../sweetalert/SweetAlert';

const Settings = () => {

  let globaldata = useContext(GlobalData);


  let [homeImg , sethomeImg] = useState();
  let [logoImg , setlogoImg] = useState();
  let [favImg , setfavImg] = useState();
  const [spin, setspin] = useState(false);
  let formRef = useRef();


  const imgb6Fn = (e , mode) =>{
    console.log(mode)
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      if(mode === 'homescreen'){
        sethomeImg(reader.result)
      }
      else if(mode === 'logo'){
        setlogoImg(reader.result)
      }
      else{
        setfavImg(reader.result)
      }
    };
  }


  const settingsPostApi = async (e) =>{
    e.preventDefault();
    setspin(true);
    let res = await postSitesettingsApi({
      apptoken: localStorage.getItem('apptoken'),
      user_id: localStorage.getItem('userID'),
      logo: logoImg,
      favicon: favImg,
      loginbgimage: homeImg,
    });

    if(res.status="TXN"){
      SweetAlert({title:'Site Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
      formRef.current.reset();
      globaldata.sitesettingsApi();


      sethomeImg('');
      setlogoImg('');
      setfavImg('');
    }
    else{
      SweetAlert({title:'Site Updation' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
    }     
    setspin(false);
  }
  
  return (
    <Container fluid>
        <Row>
          <Col sm={12}>
            <Form onSubmit={settingsPostApi} ref={formRef}>
              <Form.Group>
                <Form.Label>Homescreen background</Form.Label>
                <Form.Control type='file' onChange={(e)=>{imgb6Fn(e,'homescreen')}} />
                <img className='mt-1' src={globaldata.sitedata.loginbgimage} alt="" style={{'height' : '200px', 'width' : '200px', 'objectFit':'contain'}} />
              </Form.Group>


              <Form.Group>
                <Form.Label>Logo</Form.Label>
                <Form.Control type='file' onChange={(e)=>{imgb6Fn(e,'logo')}} />
                <img className='mt-1' src={globaldata.sitedata.logo} alt="" style={{'height' : '200px', 'width' : '200px', 'objectFit':'contain'}} />
              </Form.Group>


              <Form.Group>
                <Form.Label>Fav Icon</Form.Label>
                <Form.Control type='file' onChange={(e)=>{imgb6Fn(e,'fav')}} />
                <img className='mt-1' src={globaldata.sitedata.favicon} alt="" style={{'height' : '200px', 'width' : '200px', 'objectFit':'contain'}} />
              </Form.Group>


              <Form.Group className='mt-4'>
                <button type='submit' className='btn-yellow'>Save & Changes {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</button>
              </Form.Group>


            </Form>
          </Col>
        </Row>
    </Container>
  )
}

export default Settings