import swal from 'sweetalert';

export const SweetAlert = (props) => {
        swal({
            title: props.title,
            text: props.msg,
            icon: props.icon,
            // button: props.btn,
            buttons: false,
            showCancelButton: false,
            dangerMode : props.dangerMode,
            timer: 1200,
   
          })
}

