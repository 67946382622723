import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { addFaqApi, addNotificationApi, deleteFaqApi, getFaqApi, updateFaqApi } from '../serviceapi/Service.home';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GlobalData } from '../../App';
import { SweetAlert } from '../sweetalert/SweetAlert';
import CustomeTable from '../utils/CustomeTable';
import { SweetAlertSingle } from '../../utils/SweetAlert';
import Select from "react-select";


const Faq = () => {

  const [openMode , setOpenmode] = useState('all');
  const [EditData , setEditData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [spin , setspin] = useState(false)

  /* get api */
  const getApi = async () =>{
    let res = await getFaqApi();
    if(res.status=true){
      setfilterData(res.Data);
    }
  }
  
  useEffect(()=>{
    getApi();
  }, []);


  const deleteApi = async (id) =>{
    let res = await deleteFaqApi({faq_id:id});
    if(res.status=true){
      getApi();
      SweetAlertSingle({title:'faq deleted' , msg : res.message , icon : 'success', showCancelButton:false});
    }
    else{
      SweetAlertSingle({title:'faq failed deleted' , msg : res.message , icon : 'success', showCancelButton:false});
    }
  }
 
    const columns = [
      {
        name : "S.N",
        cell: (row, index) => index + 1,
        sortable: true,
        width: "80px"
      },
                  {
                    name : "Questions",
                    selector : row => row.Questions,
                    sortable: true,
                    width: "680px"
                  },
                  {
                    name : "Created At",
                    selector : row => row.created_at.slice(0,10),
                    sortable: true,
                  },
                  {
                    name : "Action",
                    selector : row => <div  className='icon-tag d-flex align-items-center'>
                    
                    <i class="fa-solid fa-user-pen i-x cursor-pointer" onClick={()=>{openEditFn(row); setOpenmode('edit')}}></i>
                    <i className="fa-solid fa-trash" onClick={()=>{deleteApi(row.id)}}></i>
                    
                    </div> ,
                    sortable: true,
                  },
                  
    ]

    const openEditFn = (row) =>{
      setEditData(row);
    };
    
    let globaldata = useContext(GlobalData);

  return (<>
  
  <div className="container-fluid faq-wrapper">
    <div className="row block1">

    {
         localStorage.getItem('approle')==="admin" ? 
          
          <>
          <div className="col-12">
        
        {openMode ==='all' ?  
        <>
       <div className='d-flex justify-content-between mb-2'>  <p>All Faq</p> <span><button onClick={()=>{setOpenmode('add');}} className='btn-green'>Add New</button></span></div>
        <CustomeTable columns={columns} filterData={filterData} search_data={false} />
        
        </>
        : openMode ==='add' ?
 
        <>
        <div className='d-flex justify-content-between mb-2'>  <p>Add Faq</p> <span><button onClick={()=>{setOpenmode('all'); }} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
        <FaqForm action="add" getApi={getApi} />
         </div>
        </>
         :
         openMode ==='edit' ?
         <>
        <div className='d-flex justify-content-between mb-2'>  <p>Edit Faq</p> <span><button onClick={()=>{setOpenmode('all')}} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
        <FaqForm data={EditData} action="update" getApi={getApi} />

         </div>
        </>
 
        : null
 
        }
     </div>

          </> :  

    
          <Accordion defaultActiveKey={['0']} alwaysOpen>
     {filterData?.map((val,index)=>{
        return(<>
        <Accordion.Item className='mb-3' eventKey={index.toString()}>
        <Accordion.Header className='fs-22-600'>{val.Questions}</Accordion.Header>
        <Accordion.Body>             <p  dangerouslySetInnerHTML={{ __html: val.Answer || '' }}></p></Accordion.Body>
      </Accordion.Item>

        </>)
     }) }
    
    </Accordion>
    }
     
    </div>

  </div>
  
  
  </>)
}

export default Faq;


const FaqForm = ({data,action,getApi}) => {
  const [value, setValue] = useState("");
  let formRef = useRef();

  const [newData, setnewData] = useState({
    Questions: "",
    Answer: ""
  });
  const handelChange = (e) => {
    let name = e.target.name;
    let type = e.target.type;
    let value;
    if (type === "file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    setnewData({ ...newData, [name]: value });
  };

  let submitAction = async (e) => {
    e.preventDefault();
    newData.Answer = value;

    if(action==="add"){
        let res = await addFaqApi(newData);
        if(res.status===true){
          SweetAlertSingle({title:'Faq' , msg : res.message , icon : 'success', showCancelButton:false});
          formRef.current.reset();
          setValue(()=> "")
        }
        else{
          SweetAlertSingle({title:'Faq' , msg : res.message , icon : 'warning', showCancelButton:false})
        }
    }
    else{
        newData.id = data.id;
        let res = await updateFaqApi(newData);
        if(res.statuscode===true){
          SweetAlertSingle({title:'Faq' , msg : res.message , icon : 'success', showCancelButton:false});
          formRef.current.reset();
          setValue(()=> "")
        }
        else{
          SweetAlertSingle({title:'Faq' , msg : res.message , icon : 'warning', showCancelButton:false})
        }
    }
    getApi();
    
  };
  useEffect(() => {
    if (action === "update") {
    setValue(data.Answer);
    newData.Questions = data?.Questions 
    }

   


  }, [data]);

  return (
    <>
      <Container fluid className="openTicket-wrapper">
        <Row className="block1">
          <Col sm={12} className="title-col">
            <Row>
              <Col lg={8} className="mx-auto">
              <>{action !== "update" && <h4>FAQ</h4>}</>

                <Form className="row" onSubmit={submitAction} ref={formRef} >
                  <Form.Group className="form-group  col-12  my-2">
                    <Form.Label htmlFor="" className="label-tag">
                    Questions
                    </Form.Label>
                    <input
                      type="text"
                      id=""
                      className="form-control rounded-0 bd-all"
                      name="Questions"
                      value={newData.Questions}
                      onChange={handelChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group  col-12  my-2">
                    <Form.Label htmlFor="" className="label-tag">
                    Answer
                    </Form.Label>
                    <div style={{ height: "180px" }}>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        style={{ height: "180px" }}
                        onChange={setValue}
                        className="rounded-0"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="form-group col-12 d-flex justify-content-center  my-5">
                    <button className="btn-yellow" type="submit" >
                      Submit
                    </button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};


export const quill_modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"],
    [{ 'color': [] }, { 'background': [] }],  
    [{ 'script': 'sub'}, { 'script': 'super' }],  
    ['code']
  ],
  clipboard: {
    matchVisual: false
  }
  };