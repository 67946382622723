import React, { useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { forgetresetpasswordApi, registerApi } from './serviceapi/Service.home';
import { SweetAlert } from './sweetalert/SweetAlert';
import LogBackground from './utils/LogBackground';
import { SweetAlertSingle } from '../utils/SweetAlert';

const ResetPassword = () => {
  let navigate = useNavigate();
  let params = useParams();

  let [password,setPassword] = useState('');
  const [spin, setspin] = useState(false);

  const postApi = async (e) =>{
    e.preventDefault()
      setspin(true);
      let res = await forgetresetpasswordApi({ token: params.id ,password :password });
      if(res.status==="TXN"){
        setPassword('');
      SweetAlertSingle({title:'Password reset success' , msg : res.message , icon : 'success', showCancelButton:false})
      navigate('/login')  
    }
      else{
      SweetAlertSingle({title:'Password reset failed' , msg : res.message , icon : 'warning', showCancelButton:false})
      }

      setspin(false);
  }


  return (<>
  

  <LogBackground class='RegisterContainer' element = {
        <div className='loginBox'>
          <div className='loginRegisterBtnBox'>
              <small onClick={()=>{navigate('/login')}} className='cursor-pointer'>Back to login</small>
          </div>
          <div className='welcomTxt'>
              <h1 className='headTag'>Reset Password Now!</h1>
              <span className='paraTag'>Please enter to New Password</span>
          </div>
          <form onSubmit={postApi}>
              <div className='frow frow1'>
                  <lable className='LableTag'>Enter New Password</lable>
                  <input type='text' className='mt-3' value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
              </div>


              <Col md={12} className='mt-3' >
                <Button type='submit' className='btn-spin mx-auto' >RESET {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</Button>
              </Col>
  
          </form>
      </div>
  }

  />

 
    
    
    </>)
}

export default ResetPassword