import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {eye} from 'react-icons-kit/feather/eye'
import {eyeOff} from 'react-icons-kit/feather/eyeOff'
import { Icon } from 'react-icons-kit'
import './style/login.css'
import { loginApi } from './serviceapi/Service.home';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { SweetAlert } from './sweetalert/SweetAlert';
import Spinner from 'react-bootstrap/Spinner';
import { Cookies, useCookies } from 'react-cookie';
import { GlobalData } from '../App';
import LogBackground from './utils/LogBackground';


export let userData = null;


const Login = () => {
    let {setuserLogData} = useContext(GlobalData);


    const [cookies, setCookie , removeCookie] = useCookies([]);
    let loc = useLocation()

    const [type, setType]=useState('password');
    const [icon, setIcon]=useState(eyeOff);
  
    const handleToggle=(e)=>{    
        e.preventDefault();

      if(type==='password'){
        setIcon(eye);      
        setType('text');
      }
      else{
        setIcon(eyeOff);     
        setType('password');
      }
    }


    const [rem , setrem] = useState(false)
    const [user, setUser] = useState({
        email : '',
        password : '',
    })

    const handelInp = (e) =>{
        let value;
        let type = e.target.type;
        let name = e.target.name;
    
        if(type==='file'){
            value = e.target.files[0];
        }
        else{
            value = e.target.value
        }
    
        setUser({...user , [name]:value})
    }
    
    const [spin, setspin] = useState(false);

    let loginForm = async (event) =>{
        setspin(true);
        remFn()
        try{
        event.preventDefault();
        let res = await loginApi(user);

        if(res.status==="TXN"){
            setspin(false);
            userData = res;
            SweetAlert({title:'Login Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
            
            localStorage.setItem('access_token' , res.access_token);
            localStorage.setItem('apptoken' , res.apptoken);
            localStorage.setItem('approle' , res.data.user.role?.guard_name);
            localStorage.setItem('userID' , res.user_id);

            if(res.data?.user?.role?.guard_name === 'admin'){
                navigate('/');
            }
            else{
                navigate('/home');
            }
        }
        else{
            setspin(false);
            SweetAlert({title:'Login Status' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true});
        }
        }

        catch(err){
            SweetAlert({title:'Login Status' , msg : err , icon : 'warning', btn : 'close', dangerMode: true})

        }
    }

    let navigate = useNavigate();

    useEffect(()=>{
        if(localStorage.getItem('access_token')){
            navigate('/');
        }
    }, [])


    const remFn = () =>{
        if(rem===true){

            setCookie('email', user.email, { path: '/login' });
            setCookie('password', user.password, { path: '/login' });
        }
        else{

            removeCookie('email',{ path: '/login' });
            removeCookie('password', { path: '/login' });
        }
    }

  return (<>
  
        <LogBackground class='loginContainer' element={
                <div className='loginBox'>
        <div className='loginRegisterBtnBox'>
            <Button className='active'>Login</Button>
            <Button onClick={()=>{navigate('/registration')}}>Register</Button>
        </div>
        <div className='welcomTxt'>
            <h1 className='headTag'>Welcome</h1>
            <span className='paraTag'>Please login to your account</span>
        </div>


        <form onSubmit={loginForm}>
            <div className='frow frow1'>
                <lable className='LableTag'>Username/Email</lable>
                <input className='' type='text' id='formUserName' defaultValue={cookies.email} required name='email' onChange={handelInp} />
            </div>
            <div className='frow frow2'>
                <lable className='LableTag'>Password</lable>
                <div className='passBox'>
                <input type={type} required name='password' defaultValue={cookies.password} onChange={handelInp}/><button><Icon icon={icon}  onClick={(e)=>{handleToggle(e);}} size={20}/></button>
                </div>
            </div>

            <label class="check-wrapper">Remember me
                <input type="checkbox" id='remCheck' onChange={()=>{setrem(!rem)}}  />
                <span class="ck-checkmark"></span>
            </label>

            <div className='frow frow3 '>
                <Button type='submit' className='btn-spin' >LOGIN {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</Button>
            </div>
            <div className="frow text-center">
            <button  className='tx-1 bg-transparent border-0 text-decoration-underline' onClick={()=>{navigate('/forgetpassword')}}>Forgot Password</button>
            </div>
            {/* <div className='frow frow5'>
            
                <p className='paraTag'><span>Terms and Conditions</span> & <span>Privacy Policy</span></p>
            </div> */}
        </form>
    </div>
        }

/>

  
  </>)
}

export default Login