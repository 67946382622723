import React, { useContext, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { registerApi } from './serviceapi/Service.home';
import { SweetAlert } from './sweetalert/SweetAlert';
import Spinner from 'react-bootstrap/Spinner';
import { GlobalData } from '../App';
import LogBackground from './utils/LogBackground';


const Registration = () => {
    let navigate = useNavigate();
    const [spin, setspin] = useState(false);
    let globaldata = useContext(GlobalData);

const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    username : '',
    email : '',
    password : '',
    phone : '',
    profile_image : '',
    role_id : '2'
})

const handelInp = (e) =>{
    let value;
    let type = e.target.type;
    let name = e.target.name;

    if(type==='file'){
        value = e.target.files[0];
    }
    else{
        value = e.target.value
    }

    setUser({...user , [name]:value})
}

const registerFn = async (e) =>{
    setspin(true)
    e.preventDefault();

    if(user.phone.length!== 10){
        setspin(false)
      return  SweetAlert({title:'Phone number error' , msg : "please enter correct phone number" , icon : 'warning', btn : 'close', dangerMode: true})
  
    }
    if(!user.email){
        setspin(false)
      return  SweetAlert({title:'Phone number error' , msg : "please enter email" , icon : 'warning', btn : 'close', dangerMode: true})
  
    }
    if(!user.password){
        setspin(false)
      return  SweetAlert({title:'Phone number error' , msg : "please enter password" , icon : 'warning', btn : 'close', dangerMode: true})
  
    }


    let res = await registerApi(user);

    if(res.status==="TXN"){
        SweetAlert({title:'Registration Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
        navigate('/login');
    }
    else{
        SweetAlert({title:'Registration Status' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
    }
    setspin(false)
}


const [tnc , setTnc] = useState()



  return (<>
  

        <LogBackground class='RegisterContainer' element={

<div className='loginBox'>
                <div className='loginRegisterBtnBox'>
            <button onClick={()=>{navigate('/login')}}>Login</button>
            <button className='active'>Register</button>
                </div>
                <div className='welcomTxt'>
            <h1 className='headTag'>Welcome</h1>
            <span className='paraTag'>Please register to your account</span>
                </div>

                <Form className='row' onSubmit={registerFn}>

                <Col md={6}  className='my-2'>
            <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>First Name</Form.Label>
                <input type='text' id='' required name='firstname' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
                </Col>

                <Col md={6}  className='my-2'>
            <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>Last Name</Form.Label>
                <input type='text' id='' required name='lastname' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
                </Col>



         <Col md={12}  className='my-2'>
         <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>Email</Form.Label>
                <input type='text' id='' required name='email' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
         </Col>

         

         <Col md={6}  className='my-1'>
            <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>Username</Form.Label>
                <input type='text' id='' required name='username' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
         </Col>

         <Col md={6}  className='my-1'>
            <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>Password</Form.Label>
                <input type='password' id='' required name='password' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
         </Col>


         <Col md={12}  className='my-2'>
            <Form.Group className='frow frow1 my-0'> 
                <Form.Label className='LableTag'>Phone Number</Form.Label>
                <input type='text' id='' required name='phone' onChange={handelInp} autoComplete='none'/>
            </Form.Group>
         </Col>



       
       <label class="check-wrapper mt-2">I accept <span><TncTemplate /> <span className='text-danger'>*</span> </span>
                <input type="checkbox" onClick={()=>{setTnc(!tnc)}} id=''  required />
                <span class="ck-checkmark"></span>

               {/* {!tnc ?  <small className='text-danger'>required</small> : null} */}
            </label>
    

       

         <Col md={12} className='mt-3' >
         <Button type='submit' className='btn-spin' >REGISTER {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</Button>
         </Col>

                </Form>
            </div>

        } />
  
  
  </>)
}

export default Registration;


export const TncTemplate = () =>{
    const [show, setShow] = useState(false);
    const [mode, setmode] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let globaldata = useContext(GlobalData);

    const modeFn = (prop) =>{
        setmode(prop);
        handleShow();
    }

    return(<>


    <span><span onClick={()=>{modeFn('tnc')}} >Terms & Condition</span> & <span onClick={()=>{modeFn('pvc')}}>Privacy Policy</span></span>
      <Modal className='tnc-model' centered show={show} onHide={handleClose} size='lg' >
      <Modal.Header>
            {mode==='tnc' ? 'Terms & Conditions' : 'Privacy Policy'}
      </Modal.Header>
        <Modal.Body>
        {mode==='tnc' ? <span dangerouslySetInnerHTML={{ __html:globaldata.sitedata.termsconditions }}></span> : <span dangerouslySetInnerHTML={{ __html:globaldata.sitedata.privacypolicy }}></span>}
        </Modal.Body>
      </Modal>
    </>)
}