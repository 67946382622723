
export const convertDateFormat = (inputDate) => {
  const parts = inputDate.split('/');
  if (parts.length === 3) {
    const [month, day, year] = parts;
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return formattedDate;
  }
  return inputDate; // Return the original input if the format is incorrect
};
