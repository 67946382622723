import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';

const DateRelation = ({ defaultValue,setsearchData }) => {
  const defaultPeriod = defaultValue ? defaultValue.period : '1';
  const defaultStart = defaultValue ? defaultValue.start : getFormattedDate(getDateXDaysAgo(6));
  const defaultEnd = defaultValue ? defaultValue.end : getFormattedDate(new Date());

  const [period, setPeriod] = useState(defaultPeriod);
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);
  const [isValidRange, setIsValidRange] = useState(true);

  const periodOptions = [
    {
      "id": 1,
      "name": "Last 7 Days",
      "counting": "7",
      "ddtype": "1"
    },
    {
      "id": 2,
      "name": "Last 30 Days",
      "counting": "30",
      "ddtype": "1"
    },
    {
      "id": 3,
      "name": "Last Month",
      "counting": "30",
      "ddtype": "1"
    },
    {
      "id": 4,
      "name": "Last 3 Months",
      "counting": "90",
      "ddtype": "1"
    },
    {
      "id": 5,
      "name": "Last 6 Months",
      "counting": "180",
      "ddtype": "1"
    },
    {
      "id": 6,
      "name": "Last 90 Days",
      "counting": "90",
      "ddtype": "1"
    },
    {
      "id": 7,
      "name": "Last 180 Days",
      "counting": "180",
      "ddtype": "1"
    },
    {
      "id": 9,
      "name": "Last One Year",
      "counting": "365",
      "ddtype": "1"
    },
    {
      "id": 'custom',
      "name": "Custom",
      "counting": "custom",
      "ddtype": "1"
    }
  ];

  useEffect(()=>{
    setsearchData((prev) => ({
        ...prev,
        period : defaultPeriod,
        fromdate : defaultStart,
        todate : defaultEnd,
      }));
  }, [])

  useEffect(() => {
    updateDates();
    // setsearchData((prev)=> ({...prev,
    //     period : period,
    //     fromdate : start,
    //     todate : end,
    // }))
  }, [period, defaultValue]); // Update dates when the selected period or default value changes

  const updateDates = () => {
    const today = getFormattedDate(new Date());

    switch (period) {
      case "1":
        setStart(getFormattedDate(getDateXDaysAgo(6)));
        break;
      case "2":
        setStart(getFormattedDate(getDateXDaysAgo(29)));
        break;
        case "3":
          setStart(getFormattedDate(getDateXDaysAgo(29)));
          break;
      case "4":
        setStart(getFormattedDate(getDateXMonthsAgo(3)));
        break;
        case "6":
          setStart(getFormattedDate(getDateXMonthsAgo(3)));
          break;
      case "5":
        setStart(getFormattedDate(getDateXMonthsAgo(6)));
        break;
        case "7":
          setStart(getFormattedDate(getDateXMonthsAgo(6)));
          break;
      case "9":
        setStart(getFormattedDate(getDateXDaysAgo(364)));
        break;
      case "custom":
        setStart(today);
        break;
      default:
        break;
    }

    setEnd(today);
    setIsValidRange(true);
  };

  const handlePeriodChange = (e) => {
    const newPeriod = e.target.value;
    setPeriod(newPeriod);

    setsearchData((prev) => ({
      ...prev,
      period: newPeriod,
    }));
  };
  
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStart(newStartDate);
    setIsValidRange(validateDateRange(newStartDate, end));
  
    setsearchData((prev) => ({
      ...prev,
      fromdate: newStartDate,
    }));
  };
  
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEnd(newEndDate);
    setIsValidRange(validateDateRange(start, newEndDate));
  
    setsearchData((prev) => ({
      ...prev,
      todate: newEndDate,
    }));
  };

  const validateDateRange = (startDate, endDate) => {
    return startDate <= endDate;
  };

  return (

    <>
    {/* <Col   lg={3} md={4} sm={6} xs={12}  className='my-1'>
    <Form.Group>
      <Form.Label  className='my-0' htmlFor="period">Select Period:</Form.Label>
      <Form.Select className='bd-all' id="period" value={period} onChange={handlePeriodChange}>
      {
        periodOptions.map((val,index)=>{
        <option value={val.id} key={index}>{val.name}</option>
        })
      }
        <option value="7">Last 7 Days</option>
        <option value="30">Last 30 Days</option>
        <option value="90">Last 3 Months</option>
        <option value="180">Last 6 Months</option>
        <option value="365">Last Year</option>
        <option value="custom">Custom</option>
      </Form.Select>


      </Form.Group>
    </Col> */}

    <Col lg={3} md={4} sm={6} xs={12} className='my-1'>
        <Form.Group>
          <Form.Label className='my-0' htmlFor="period">Select Period:</Form.Label>
          <Form.Select id="period" className='bd-all' value={period} onChange={handlePeriodChange}>
            {periodOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>


    <Col   lg={3} md={4} sm={6} xs={12}  className='my-1'>
    <Form.Group>
      <Form.Label  className='my-0' htmlFor="start">Start Date:</Form.Label>
      <Form.Control className='bd-all'
        type="date"
        id="start"
        value={start}
        onChange={handleStartDateChange}
        disabled={period !== 'custom'}
      />

      </Form.Group>
    </Col>


  <Col  lg={3} md={4} sm={6} xs={12}  className='my-1'>
    <Form.Group>
      <Form.Label  className='my-0' htmlFor="end">End Date:</Form.Label>
      <Form.Control className='bd-all'
        type="date"
        id="end"
        value={end}
        onChange={handleEndDateChange}
        disabled={period !== 'custom'}
      />

      {/* {!isValidRange && <small style={{ color: 'red' }}>End date must be equal or later than start date.</small>} */}
      </Form.Group>
</Col>
    </>
  );
};

const getFormattedDate = (date) => {
  return date.toISOString().split('T')[0];
};

const getDateXDaysAgo = (x) => {
  const today = new Date();
  today.setDate(today.getDate() - x);
  return today;
};

const getDateXMonthsAgo = (x) => {
  const today = new Date();
  today.setMonth(today.getMonth() - x);
  return today;
};

export default DateRelation;
