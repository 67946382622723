import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomeTable from './utils/CustomeTable';
import { SweetAlert } from './sweetalert/SweetAlert';
import { supportApi, supportDeleteApi, supportStatusApi, supportreplyApi, supportreplylistApi, supportuserlistApi } from './serviceapi/Service.home';
import {ic_message} from 'react-icons-kit/md/ic_message'
import Icon from 'react-icons-kit';
import OpenTicket from './OpenTicket';
import { Button, Form } from 'react-bootstrap';

const Ticket = () => {
    let navigate = useNavigate();
    const [value, setValue] = useState('');
   
    const [editData, seteditData] = useState([]);
    const [actiontype, setactiontype] = useState('all');
    
    const [getData, setgetData] = useState([]);
    let getApi = async () =>{
   
      if(localStorage.getItem('approle')==='admin'){
        let res = await supportApi();
        res.status === 'TXN' && setgetData(res?.data?.support_ticket_data)
      }
      else{
        let res = await supportuserlistApi({user_ticket_id: localStorage.getItem('userID')});
        res.status === true && setgetData(res?.data?.support_ticket_data)    
      }

    }
    useEffect(()=>{
        getApi();
    }, []);


    const columns = [
        {
          name : "S.N",
          cell: (row, index) => index + 1,
          sortable: true,
          width: "80px"
        },
        {
            name : "Department",
            selector : row => row.department,
            sortable: true,
            width: "200px"

          },
          {
            name : "Subject",
            selector : row => row.subject,
            sortable: true,
            width: "350px"

          },
          {
            name : "Priority",
            selector : row => <Button className={`btn-sm text-uppercase  `} variant={(row.priority)?.toLowerCase() === 'high' ? 'danger' :  (row.priority)?.toLowerCase() === 'medium' ? 'warning' : 'info'}>{row.priority}</Button>,
            sortable: true,
            width: "120px"
          },
          {
            name : "Status",
            selector : row => <Button className={`btn-sm text-uppercase  `} variant={(row.status)  === 'Resolve' ? 'success' :  'warning'}>{row.status}</Button>,
            sortable: true,
            width: "150px"
          },

          {
            name : "Change Status",
            selector : row =>  
            <>
           {(row.status) !== 'Resolve' ? <Form.Select onChange={(e)=>{changestatusFn({"ticket_id": row.id, "status": e.target.value })}} value={(row.status)?.toLowerCase()} disabled={(row.status)?.toLowerCase()==='solved'}>
              <option value='pending'>pending</option>
              <option value='solved'>solved</option>
            </Form.Select> : null }
            </>,
            sortable: true,
            width: "180px"
          },
        {
          name : "Action",
          selector : row => 
          <div  className='icon-tag d-flex align-items-center'> 
          { (row.status)  !== 'Resolve' ? 
          <>
          <Icon icon={ic_message} size={16} onClick={()=>{setactiontype('reply'); seteditData(row); replyData(row.id)}}  /> 
            <i class="fas fa-pen hov-r cursor-pointer" onClick={()=>{setactiontype('update');seteditData(row);}}></i> 
          </>
          : null}
            <i class="fas fa-trash bg-hov-r cursor-pointer" onClick={()=>{deleteFn(row.id);}}></i> 
          </div> ,
          sortable: true,
        }
      ]


      const user_columns = [
        {
          name : "S.N",
          cell: (row, index) => index + 1,
          sortable: true,
          width: "80px"
        },
        {
            name : "Department",
            selector : row => row.department,
            sortable: true,
            width: "200px"

          },
          {
            name : "Subject",
            selector : row => row.subject,
            sortable: true,
            width: "350px"

          },
          {
            name : "Priority",
            selector : row => <Button className={`btn-sm text-uppercase  `} variant={(row.priority)?.toLowerCase() === 'high' ? 'danger' :  (row.priority)?.toLowerCase() === 'medium' ? 'warning' : 'info'}>{row.priority}</Button>,
            sortable: true,
            width: "120px"
          },
          {
            name : "Status",
            selector : row => <Button className={`btn-sm text-uppercase  `} variant={(row.status)?.toLowerCase() === 'solved' ? 'success' :  'warning'}>{row.status}</Button>,
            sortable: true,
            width: "150px"
          },
        {
          name : "Action",
          selector : row => 
          <div  className='icon-tag d-flex align-items-center'> 
            <Icon icon={ic_message} size={16} onClick={()=>{setactiontype('reply'); seteditData(row); replyData(row.id)}}  />
            <i class="fas fa-pen hov-r cursor-pointer" onClick={()=>{setactiontype('update');seteditData(row);}}></i> 
            <i class="fas fa-trash bg-hov-r cursor-pointer" onClick={()=>{deleteFn(row.id);}}></i> 
          </div> ,
          sortable: true,
        }
      ]



       /* delete fn */

       const deleteFn = async (data) =>{
        let res = await supportDeleteApi({ticket_id:data});
        if(res.status===true){
          
          getApi();
          SweetAlert({
            title: "Support Deleted",
            msg: "success",
            icon: "success",
            btn: "close",
            dangerMode: true,
          });
        }

      }

      /* changestatus fn */
      const changestatusFn = async (data) =>{
        let res = await supportStatusApi(data);
        if(res.status === true){
          SweetAlert({
            title: "Tickect updation",
            msg: "status updated",
            icon: "success",
            btn: "close",
            dangerMode: true,
          });
          getApi();

        }
        else{
          SweetAlert({
            title: "Tickect updation failed",
            msg: res.message,
            icon: "success",
            btn: "close",
            dangerMode: true,
          });
        }
      }


      /* supportreply fn */
      const [supportreplyData, setsupportreplyData] = useState([]);

      const replyData = async (data) =>{
        let res = await supportreplylistApi({ticket_id : data});
        res.status === true && setsupportreplyData(res.data)
      }


           /* supportreply fn */

        const supportreplyFn = async (e) =>{
      e.preventDefault();

      let res = await supportreplyApi({
      "ticket_id": editData.id,
      "message": value,
      "type": localStorage.getItem('approle') === 'user' ? 'User' : 'Admin',
      "created_by": localStorage.getItem('userID')
    });
      if(res.status===true){
        
        getApi();
        replyData(editData.id);
        setValue('')
        SweetAlert({
          title: "Reply sent",
          msg: "success",
          icon: "success",
          btn: "close",
          dangerMode: true,
        });
      }
  
        }


    
  return (<>
{actiontype==='all' ?  
 
  <div className="container-fluid all-ticket-wrapper" id='all-ticket-wrapper'>
    <div className="row block1">
        {/* <div className="col-md-4 col-12 ms-auto">
        <div className="search-box">
            <input type="text" className='form-control bd-all-1' placeholder='Search Ticket' />
            <button className='btn-sq'><img src="/images/search.svg" alt="img" /></button>
        </div>
        </div> */}

        <div className="col-12">
            <CustomeTable columns={ localStorage.getItem('approle') === 'admin' ?  columns : user_columns} filterData={getData} tableTitle={'Tickets data'}   />
        </div>
    </div>
  </div>
: actiontype==='reply' ?  
  <div className="container-fluid open-chat-wrapper" id='open-chat-wrapper' >
    <div className="row block1">
        <div className="col-12">
            <p className='cursor-pointer' onClick={()=>{setactiontype('all')}} > <i class="fa-solid fa-arrow-left i-x"></i>Back to All Chat  </p>
        </div>

        <div className="col-12">
        <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed bg-light rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      <i class="fa-solid fa-pen i-x"></i> Reply
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body bd-all-1 border-top-0 rounded-0">
        <form action="" onSubmit={supportreplyFn}>
            <div className="form-group col-12 my-2">
            <ReactQuill theme="snow" value={value} onChange={setValue} />
            </div>
            {/* <div className="form-group col-12  mt-2">
                <input type="file" name="" id="" className='form-control  rounded-0' />
            </div> */}
            <div className="form-group col-12  my-3">
            <button className='btn-all' type='submit' >Send</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>
        </div>


        <div className="col-12 my-5">
        {supportreplyData.ticketdata?.length > 0 && 
        
          supportreplyData.ticketdata?.map((val,index) => {
            return(<>

            <div className="card-body mb-3 bd-all-1"  key={index}>
                <div className="title-box bg-light px-2 pt-2 d-flex justify-content-between"> <span className='d-flex'><i class="fa-solid fa-user i-x fs-2 mt-2"></i> <ul className='list-unstyled'>
                    <li>{val.by === 'Admin' ? 'Admin' : supportreplyData.userdata.username }</li>
                    <li> <small>Staff</small> </li>
                </ul> </span> <small className='font-12-400'>{val.timeago}</small> </div>

                <div className="content-box p-2 border-1">
                    <p className='my-2'>{val.message}</p>
                </div>
            </div>
      
            </>)
          })
        }

        </div>
    </div>
  </div>

  :  
  <> 

              <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
                  <h5>Edit support</h5>
                  <button className='btn-green' onClick={()=>{   setactiontype('all'); }}>Back to list</button>
               </div>
            <OpenTicket action='update' data={editData} setactiontype={setactiontype} tabledata={getApi} />


   </>
   
 
  
  }
  
  
  </>)
}

export default Ticket