import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { allpackageApi, deletepackageApi, editpackageApi, getChapterApi } from '../serviceapi/Service.home'
import { SweetAlert } from '../sweetalert/SweetAlert'
import CustomeTable from '../utils/CustomeTable'
import CreatePackages from './CreatePackages'
import { useContext } from 'react'
import { GlobalData } from '../../App'

const AllPackages = () => {

  let {packageData,packageApi} = useContext(GlobalData);

  /* get apit */
  const [preData, setpreData] = useState([])
  const [filterData, setfilterData] = useState([])
  
  /* -- get api -- */
  const getApi = async () =>{
    let res = await allpackageApi();
    if(res.status==="TXN"){
      setpreData(res.data.package_data);
      setfilterData(res.data.package_data);
    }
  }

  useEffect(()=>{
    getApi();
  }, [packageData]);


            /* -- tables -- */

            const [tableSearch, settableSearch] = useState("")

            useEffect(()=>{
                    const result = preData.filter((eq) => {
                      return eq.name.toLowerCase().match(tableSearch.toLowerCase());
                    });
                    setfilterData(result);
            }, [tableSearch])
          
          
            const columns = [
              {
                name : "S.N",
                cell: (row, index) => index + 1,
                sortable: true,
                width: "80px"
              },
                {
                  name : "Package Name",
                  selector : row => row.name,
                  sortable: true,
                  width: "200px"

                },
                {
                  name : "Data Type",
                  selector : row => row.dataType,
                  sortable: true,
                },
                {
                  name : "Type",
                  selector : row => row.packageType,
                  sortable: true,
                },
                {
                  name : "Package Start Date",
                  selector : row => row.package_startDate,
                  sortable: true,
                },
                {
                  name : "Database Start Date",
                  selector : row => row.database_access_startDate,
                  sortable: true,
                },
                
                {
                  name : "Start At",
                  selector : row => row.package_startDate.slice(0,10),
                  sortable: true,
                },
      {
        name : "Action",
        selector : row => <div  className='icon-tag d-flex align-items-center'> <i class="fas fa-pen hov-r cursor-pointer" onClick={()=>{updateFn(row);}}></i> <i class="fas fa-trash bg-hov-r cursor-pointer" onClick={()=>{deleteFn(row.id);}}></i> </div> ,
        sortable: true,
      }
            ]




            /* update package */

          const [validated, setValidated] = useState(false);
      
          const handleSubmit = (event) => {
            const form = event.currentTarget;
            event.preventDefault();
      
            if (form.checkValidity() === false) {
              event.stopPropagation();
            }
            setValidated(true);
          };
      
      
          const [pkType, setpkType] = useState('point')
        
      
      
          const [userChoice, setUserChoice] = useState([]);
          const multiSelectfn =  (item) =>{
              item.forEach(element => {
                  setUserChoice([...userChoice , element.value]);
              });
              
            }
      
            let formRef = useRef();
        
                /* -- post api -- */
                const [postData , setpostData] = useState({
                    "apptoken": "",
                    "user_id": "",
                    "name": "",
                    "dataType": "import",
                    "chaptercode": Array,
                    "packageType": "pointbased",
                    "startDate": "",
                    "endDate": "",
                  "packageid": "",

                  });
                
                    const handelInp = (e) =>{
                    let type = e.target.type;
                    let name = e.target.name;
                    let value;
                    if(type==='file'){
                      value = e.target.files[0];
                    }
                    else{
                      value = e.target.value
                    }
                    setpostData({... postData , [name]:value })
                  }

            const putApi = async (e,id) =>{
              e.preventDefault();

              postData.chaptercode = userChoice;
              postData.apptoken = localStorage.getItem('apptoken');
              postData.user_id = localStorage.getItem('userID');
              postData.packageid = id;
              
              let res = await editpackageApi(postData);
              if(res.status==="TXN"){
                  SweetAlert({title:'Package' , msg : `success, ${res.message}` , icon : 'success', btn : 'close', dangerMode: true})
                  formRef.current.reset();
                  getApi();
                  setpostData({
                  "apptoken": localStorage.getItem('apptoken'),
                  "user_id": localStorage.getItem('userID'),
                  "packageid": "",
                  "name": "",
                  "dataType": "",
                  "chaptercode": Array,
                  "packageType": "",
                  "startDate": "",
                  "endDate": ""
                })
              }
              else{
                  SweetAlert({title:'Package' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
              }
            
            }


            const [mode, setmode] = useState('list');

            const [EditData, setEditData] = useState([]);
            const updateFn = (data) =>{
              setEditData(data);
              setmode('edit')
            }
            



            let [allChapterData,setallChapterData] = useState([]);
            let [ChapterData,setChapterData] = useState([]);
          
          
            const getChapterFn = async () =>{
              let res = await getChapterApi();
              if(res.data){
                setallChapterData(res.data.map((item) => ({ id: item.id, value: item.chaptercode, label: item.chaptercode })));
              }
            }
          
            useEffect(()=>{
              getChapterFn();
            }, []);


            /* delete fn */

            const deleteFn = async (data) =>{
              let res = await deletepackageApi({packageid:data});
              if(res.status===true){
                
                getApi();
                packageApi();

                SweetAlert({
                  title: "Package Deleted",
                  msg: "success",
                  icon: "success",
                  btn: "close",
                  dangerMode: true,
                });
              }

            }

  return (<>
  
  <div className="container-fluid allpackages-wrapper">
            <div className="row block1">
                <div className="col-12">
                {mode ==='list' ? 
                
                <>
                <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
                <h5>All Packages</h5>
                </div>
                  <CustomeTable columns={columns} filterData={preData} settableSearch={settableSearch} tableSearch={tableSearch} />
                </>
                :


               <>
               
               <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
                  <h5>Edit Packages</h5>
                  <button className='btn-green' onClick={()=>{   setmode('list')}}>Back to list</button>
               </div>

                <CreatePackages data={EditData} actiontype="update" tabledata={getApi} setmode={setmode} />
               </>
                }
                </div>
            </div>
        </div>  
  
  
  </>)
}

export default AllPackages