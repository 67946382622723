import React from 'react';
import DataTable from 'react-data-table-component';

const tableCustomStyles = {
  rows: {
    style: {
        minHeight: '65px',
        "&:nth-of-type(even)": {
          backgroundColor: "#f1f1f1",
        },
    },
},
	headCells: {
	  style: {
		fontSize: '16px',
		fontWeight: '500',
		backgroundColor: 'black',
		width: "100%",
    color: "white"
	  },
	},
  }

const CustomeTable = (props) => {
  return (
    <>
  {props.tableTitle ?   <div className="title-box bg-black text-white py-2 px-2 mt-3">{props.tableTitle}</div> : null}
	<DataTable columns={props.columns} data={props.filterData}

      pagination
      fixedHeader
      // fixedHeaderScrollHeight='425px'
      paginationPerPage={10}
	  paginationRowsPerPageOptions={[5, 10, 50, 100]}
      pointerOnHover
      highlightOnHover
      // subHeader={'test'}
      // title={<div className="title-box bg-black text-white">Table Title</div>}
	  selectableRows={props.select}
      onSelectedRowsChange={props.SFN || null}


	  customStyles={tableCustomStyles}
       />
    </>
  )
}

export default CustomeTable
//   paginationDefaultPage={5}
    //   subHeaderComponent = {
	// 	props.search_data===false ? null :   <Form.Control type='text' className='w-25 rounded-1' placeholder='Search...'
    //   	value={props.tableSearch} onChange={(e)=>{props.settableSearch(e.target.value)}}
    //    />
    //   }