import React, { useState } from 'react'
import { useEffect } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { allpackageApi, assignpackageApi, userAllApi, userStatusApi } from '../serviceapi/Service.home'
import CustomeTable from '../utils/CustomeTable';
import AddNewCustomers from './AddNewCustomers';
import { SweetAlert } from '../sweetalert/SweetAlert';

const AllUsers = () => {
 
    const [alluser, setalluser] = useState([])
    const [filterData, setfilterData] = useState([])

    const [Package, setPackage] = useState([])
    
    /* -- get api -- */
    const getPackageApi = async () =>{
      let res = await allpackageApi();
      if(res.status === 'TXN'){
        setPackage(res.data.package_data);
      }
    }
  



    
    const allusersApi = async () =>{
        let res = await userAllApi();
        if(res.status===200){
            setalluser(res.data);
            setfilterData(res.data);
        }

    }

    useEffect(()=>{
        allusersApi();
    }, [])

 


    const [editMode  , seteditMode] = useState('all');
    
    const [SingleUser  , setSingleUser] = useState([]);
    const openEditFn = (props) =>{
        setSingleUser(props);
        seteditMode('edit')
    }




    const backFn = (props) =>{
        seteditMode('all')
    }


    const changeUserStatus = async (id,status) =>{
        let newstatus;
        if(status==="active"){
            newstatus = "block"
        } 
        else{
            newstatus = "active"
        }
        let res = await userStatusApi(id,newstatus);
        allusersApi();
    }


     /* -- tables -- */

     const [tableSearch, settableSearch] = useState("")

     useEffect(()=>{
                        const result = alluser.filter((eq) => {
                          return eq.email.toLowerCase().match(tableSearch.toLowerCase());
                        });
                        setfilterData(result);
     }, [tableSearch])
    
    
     const columns = [
      
                    {
                      name : "S.N",
                      cell: (row, index) => index + 1,
                      sortable: true,
                      width: "80px"
                    },
                    {
                        name : "First Name",
                        selector : row => row.firstname || 'N/A',
                        sortable: true,
                        width: "150px"

                      },
                      {
                        name : "Last Name",
                        selector : row => row.lastname || 'N/A',
                        sortable: true,
                        width: "150px"

                      },
                    {
                      name : "Username",
                      selector : row => row.username,
                      sortable: true,
                      width: "150px"

                    },
                    {
                      name : "Email",
                      selector : row => row.email,
                      sortable: true,
                      width: "180px"

                    },
                    {
                      name : "Role",
                      selector : row => row.role.guard_name,
                      sortable: true,
                    },
            
                    {
                        name : "Assign Package",
                        selector : row => <div  className='icon-tag d-flex align-items-center'><i class="fa-solid fa-user-pen i-x cursor-pointer" onClick={()=>{handleShow(); setSingleUser(row);getPackageApi(); }}></i> </div> ,
                        sortable: true,
                        width: "170px"

                      },
                    {
                      name : "Action",
                      selector : row => <div  className='icon-tag d-flex align-items-center'><i class="fa-solid fa-user-pen i-x cursor-pointer" onClick={()=>{openEditFn(row)}}></i> <Form.Check onChange={()=>{changeUserStatus(row.id,row.status)}} defaultChecked={row.status==="active" ? true : false} type="switch" /></div> ,
                      sortable: true,
                    },
                    
     ]
    
/* model */

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const [selectedPackage, setselectedPackage] = useState("");
const handleSubmit = async (event,mode) => {
     
    event.preventDefault();
  

      let res = await assignpackageApi({    
      "apptoken": localStorage.getItem('apptoken'),
      "customerid": SingleUser.id,
      "packageid": selectedPackage
      })


      if(res.status==='TXN'){
          SweetAlert({title:'Package' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
          allusersApi();
          handleClose();
      }
      else{
       SweetAlert({title:'Package' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
      }


  
  };

  return (<>
   <Container fluid className="container-fluid add-new-wrapper" id='all-role-wrapper'>
            <Row className="row block1">

  {
    editMode === 'edit' ? 
        <Col sm={12}>
        <Row className=''> 
        <Col className='me-auto'><button className='btn-green' onClick={()=>{backFn()}}>Go Back</button></Col>
        </Row>
        <Col>


            <AddNewCustomers 
            uid={SingleUser.id}
            firstname={SingleUser.firstname}
            lastname={SingleUser.lastname}
            email={SingleUser.email}
            username={SingleUser.username}
            role={SingleUser.role.guard_name}
            package={SingleUser.package}
            phone={SingleUser.phone}
            company={SingleUser.company}
            designation={SingleUser.designation}
            companyaddress={SingleUser.companyaddress}
            sales={SingleUser.sales}
            mode={editMode}
            seteditMode={seteditMode}
            allusersApi={allusersApi}
             />


        </Col>
        </Col>
    :

    editMode === 'all' ? 
    <Col sm={12} className="col-12">

    <CustomeTable  select={false} columns={columns} filterData={filterData} settableSearch={settableSearch} tableSearch={tableSearch} />

    </Col>  


    :
    <Col sm={12}>
        <Row className=''> 
        <Col className='me-auto'><Button className='btn btn-primary' onClick={()=>{backFn()}}>Go Back</Button></Col>
        </Row>
        <Col>


            <AddNewCustomers 
                  uid={SingleUser.id}

            firstname={SingleUser.firstname}
            lastname={SingleUser.lastname}
            email={SingleUser.email}
            username={SingleUser.username}
            role={SingleUser.role.guard_name}
            package={SingleUser.package}
            phone={SingleUser.phone}
            company={SingleUser.company}
            designation={SingleUser.designation}
            companyaddress={SingleUser.companyaddress}
            sales={SingleUser.sales}
            mode={editMode}
            seteditMode={seteditMode}
             />


        </Col>
        </Col>

  }


  <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Package</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form action="#" className="row" autoCapitalize='off' onSubmit={(e)=>{handleSubmit(e)}}  >
 


                        <div className="form-group col-12 my-md-3 my-2">
                            <label htmlFor="" defaultValue={SingleUser.package} >Select Package</label>
                            <select name="" id="" className="form-select rounded-0 bd-all w-100"  onChange={(e)=>{setselectedPackage(e.target.value)}} >
                                <option value="" disabled selected >--select--</option>
                                {Package?.map((val)=>{
                                    return(<>
                                <option value={val.id} key={val.id}>{val.name}</option>

                                    </>)
                                })}
                            </select>
                        </div>
 

                        <div className="form-group col-12 my-md-3 my-2">
                        <button className="btn-yellow" type='submit'>Update</button>
                        </div>
                    </Form>
        
        </Modal.Body>
      </Modal>
  
 
              
            </Row>
  </Container> 
  
  
  </>)
}

export default AllUsers