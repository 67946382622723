import React, { useEffect, useState } from 'react'
import { allannouncementApi } from '../serviceapi/Service.home';

const AnnounceMarquee = () => {


      /* get api */
  const [preData,setpreData] = useState([]);
  const fetchAllListApi = async () =>{
    let res = await allannouncementApi();
    if(res.status="TXN"){
      setpreData(res.data);
    }
  }
  useEffect(()=>{
    fetchAllListApi();

  }, []);

  


  return (<>
  


  
  <marquee scrollamount="4" className="" scrolldelay="40" onmouseover="this.stop();" onmouseout="this.start();" class="text-white my-auto">
                <ul class="news1-ul d-flex align-items-center list-unstyled">

                {
                    preData?.map((val,index)=>{
                        return (<div className='' key={index}>
                          { val.status==="active" ?  <li className='my-auto ms-5'  dangerouslySetInnerHTML={{ __html:val.notice }}></li> : null }
                        </div>)
                    })
                }
                 
                </ul>
            </marquee>

  
  
  </>)
}

export default AnnounceMarquee