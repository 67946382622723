import React, { useEffect, useState } from 'react';
import { getaccesslogApi } from '../serviceapi/Service.home'
import CustomeTable from '../utils/CustomeTable'
import { formatDate } from '../../utils/formatDate';

const AccessLog = () => {

  let [preData,setpreData] = useState([]);
  let [alldata,setalldata] = useState([]);


  const getApi = async () =>{
    console.log('entred api')
    let res = await getaccesslogApi(`user_id=${localStorage.getItem('userID')}`);
    if(res.data){
      setpreData(res.data.logindata);
      setalldata(res.data.userdata);

    }
  }

  useEffect(()=>{
    getApi();
  }, []);


       const columns = [

                      {
                          name : "Date",
                          selector : row => formatDate(row.created_at) || 'N/A',
                          sortable: true,
                         width: "170px"

                        },
                        {
                          name : "User",
                          selector : row => row.email || 'N/A',
                          sortable: true,
                         width: "180px"

                        },
                        {
                            name : "IP",
                            selector : row => row.IpAddress || 'N/A',
                            sortable: true,
                            width: "150px"

                          },
                          {
                            name : "City",
                            selector : row => row.city || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Country",
                            selector : row => row.country || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Browser",
                            selector : row => row.item_desc || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "URL",
                            selector : row => row.item_desc || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Params",
                            selector : row => row.item_desc || 'N/A',
                            sortable: true,
                          },
       ]


  return (<>
  
  
  <div className="container-fluid add-new-wrapper">
            <div className="row block1">
                <div className="col-12">
                <CustomeTable columns={columns} filterData={preData} tableTitle={'Access log data'} />
                </div>
            </div>
  </div>
  
  </>)
}

export default AccessLog