import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Form,Card, Col, Modal, Spinner } from 'react-bootstrap';
import { searchhistorybyfolderApi, showhistorydataApi, userFolderApi, userFolderCreateApi, userFolderDeleteApi, userFolderUpdateApi } from '../serviceapi/Service.home';
import { SweetAlert } from '../sweetalert/SweetAlert';
import { GlobalData } from '../../App';
import { useNavigate, useParams } from 'react-router-dom';
import CustomeTable from '../utils/CustomeTable';
import { SweetAlertConfirm, SweetAlertFolderConfirm } from '../../utils/SweetAlert';

const Workspace = () => {
    let {worksapceSearch , setworksapceSearch} = useContext(GlobalData);
    const queryParams = new URLSearchParams(window.location.search);
    let folderInfo = queryParams.get('folder');

    let navigate = useNavigate();
    const [spin1, setspin1] = useState(false);
    const [spin, setspin] = useState(false);

    const [switchData, setswitchData] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [myfolders , setMyfolders] = useState([]);
    const [myfoldersData , setMyfoldersData] = useState([]);
    const [searchFilter , setsearchFilter] = useState({
        folder :  "all",
        days : 30
    });


    const myFoldersApi = async () =>{
        setspin1(true);
        let res = await userFolderApi();
        if(res.status==='TXN'){
            setMyfolders(res.data);
        }
        else{
            setMyfolders([]);
        }
        setspin1(false);
    }

    useEffect(()=>{
        myFoldersApi();
    } , []);
    


    const showHistory = async () =>{
        setspin(true);
        searchFilter.folder = queryParams.get('folder') || "all";
        let res = await searchhistorybyfolderApi(searchFilter);
        if(res.status===true){
            setMyfoldersData(res.data.Searchdata);
        }
        else{
            setMyfoldersData([]);
        }
        setspin(false);
    }

    useEffect(()=>{
        showHistory();
    } , [searchFilter.days,folderInfo]);


    const [createFolder,setcreateFolder] = useState('')
    const myFoldersCreateApi = async (e) =>{
        e.preventDefault();

        let res = await userFolderCreateApi( createFolder);

        if(res.status==='TXN'){
            SweetAlert({title:'Folder Creation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
            setShow(false);
            myFoldersApi();
        }
        else{
            SweetAlert({title:'Folder Creation' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true});
        }
    }

        const gotoSearch = (newData) =>{
            setworksapceSearch((prev)=> ({...prev , status:true , data : newData}));

            const url = `/home?workspace=true` +
            `&searchid=${encodeURIComponent(newData.id)}` +
            `&datatype=${encodeURIComponent(newData.datatype)}` +
            `&multiplesearchtext=${encodeURIComponent(newData.multiplesearchtext || '')}` +
            `&multiplesearchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +
            `&multipleoperator=${encodeURIComponent(newData.multipleoperator || '')}` +
            `&operator=${encodeURIComponent(newData.operator || '')}` +

            `&searchtext=${encodeURIComponent(newData.searchtext || '')}` +
            `&searchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +


            `&period=${encodeURIComponent(newData.period || '')}` +
            `&fromdate=${encodeURIComponent(newData.fromdate || '')}` +
            `&todate=${encodeURIComponent(newData.todate || '')}` +
            `&searchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}` +
            `&multiplesearchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}`;
        
          // Navigate to the new URL
          navigate(url);
        }
        

        const columns = [
            {
              name : "S.N",
              cell: (row, index) => index + 1,
              sortable: true,
              width: "80px"
            },
            {
                name : "Data type",
                selector : row =>  <strong className='text-decoration-underline my-0'  onClick={()=> gotoSearch(row)}>{row.Folder}</strong> ,
                sortable: true,
                width: "200px"
    
              },
            {
                name : "Data type",
                selector : row => row.datatype,
                sortable: true,
                width: "200px"
    
              },
              {
                name : "Search field",
                selector : row => row.multiplesearchfield,
                sortable: true,
                width: "200px"
              },
              {
                name : "Period",
                selector : row => row.period,
                sortable: true,
                width: "200px"
              },
              {
                name : "From date",
                selector : row => row.fromdate,
                sortable: true,
                width: "200px"
              },
              {
                name : "To date",
                selector : row => row.todate,
                sortable: true,
                width: "200px"
              },
              {
                name : "Search data",
                selector : row => row.searchdata,
                sortable: true,
                width: "200px"
              },
              {
                name : "Search operator",
                selector : row => row.multiplesearchoperator,
                sortable: true,
                width: "200px"
              },
              {
                name : "Search text",
                selector : row => row.multiplesearchtext,
                sortable: true,
                width: "200px"
              },
              {
                name : "Generated On",
                selector : row => row.GeneratedOn,
                sortable: true,
                width: "300px"
              }
          ]

          

  return (
    <>
    


      <div className="container-fluid worksapce-container">
        <div className="row block1">

        {
     spin1 ? 
     <Col xs={12} className='d-flex align-items-center justify-content-center'>
<Spinner variant='warning' />
</Col>
      : 

      
      <div className="col-12">
             
             <div className="row">
             <div className="create-box col-sm-2 col-6" onClick={handleShow}>
                 <div className="icon-box">
                     <img src="/images/add.svg" alt="img" style={{height : 50, width : 50}} className='img-fluid' />
                 </div>
                 <div className="paraBox">
                     <p>Create Floder</p>
                 </div>
             </div>
             <MyFolders allfolder={myfolders} callFolder={myFoldersApi} />
             </div>


 

           <Col xs={12} className="search-data-filter-box mb-2">
             <Form className="row">
                 <Form.Group className='col-md-2'>
                 <Form.Label>Days</Form.Label>
                     <Form.Select value={searchFilter.days} className='bd-all' onChange={(e)=>{setsearchFilter((prev)=> ({...prev , days : e.target.value}))}}>
                         <option value={7}>7 days</option>
                         <option value={30}>30 days</option>
                         <option value={180}>180 days</option>
                         <option value={365}>1 Year</option>
                     </Form.Select>
                 </Form.Group>

                 <Form.Group className='col-md-2 d-flex align-items-end'>
                
                     <button type='button'  onClick={()=>{setswitchData((prev) => !prev)}} className="btn-green p-2 px-3">
                     {switchData ? 
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 3h18v18H3zM21 9H3M21 15H3M12 3v18"/></svg>
                     : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>}
                     </button>
                 </Form.Group>
             </Form>
           </Col>

         <>
            {
                switchData ? 

                <>
                
            <CustomeTable columns={columns} filterData={myfoldersData}  tableTitle={'Workspace data'}  />


                 </>
                :

              <>
              {
         myfoldersData.length> 0 && 
         <Col xs={12}>
         <Card>
             <Card.Header className='bg-light'>Overview</Card.Header>
             <Card.Body>
                   
{spin ? 

<Col xs={12} className='d-flex align-items-center justify-content-center'>
<Spinner variant='warning' />
</Col> : 

<div className="container-fluid worksapce-container">
 <div className="row block1">
     {
         myfoldersData.map((val,index)=>{
             return(<>
                 <div className="col-md-4 col-sm-6 " key={index}>
                     <div className="card-body  my-0 ">
             <div className="fav-box bg-light w-100 p-3">
                 <ul className='list-unstyled' style={{fontSize : '14px'}}>
                   <li><h5><strong className='text-decoration-underline cursor-pointer' onClick={()=> gotoSearch(val)} >{val.multiplesearchtext}</strong></h5></li>
                   <li>Database Type: {val.datatype}</li>
                   <li>{val.GeneratedOn}</li>
                   <li>Shipments: {val.shipments}</li>
                   <li>Keyword: <small>{val.multiplesearchtext}</small></li>
                 </ul>
                 {/* <div className="remove-tag tx-red">Remove</div> */}
             </div>
                     </div>
                 </div>
             </>)
         })
     }
 </div>
</div>}
             </Card.Body>
           </Card>
         </Col>
         
         }

              </>
            }
         </>
             
             <Modal show={show} onHide={handleClose}>
 <Modal.Header closeButton>
   <Modal.Title>Folder</Modal.Title>
 </Modal.Header>
 <Modal.Body>
 
 <form action="#" onSubmit={myFoldersCreateApi}>
         <div className="form-group  my-3">
             <label htmlFor="" className="label-tag">Name</label>
             <input type="text" name="" id="" className="form-control bd-all rounded-0" onChange={(e)=>{setcreateFolder(e.target.value)}} />
         </div>
         <div className="form-group my-3">
             <input type="submit" value="Create Folder" className='btn-yellow' />
         </div>

     </form>
     
     </Modal.Body>
             </Modal>

     
     </div>
    }
        </div>
    </div>

    
    </>
  )
}

export default Workspace;


export const MyFolders = (props) =>{
    let navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const folderInfo = queryParams.get('folder');
    const [folderId,setfolderId] = useState('');
    let did;
    function deleteFn(data){
        did =  data;
        SweetAlertFolderConfirm(myFoldersDeleteApi);
    }

    const myFoldersDeleteApi = async () =>{
        let res = await userFolderDeleteApi(did);

        if(res.status === 'TXN'){
            SweetAlert({title:'folder Deletion' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
            props.callFolder();
        }
        else{
            SweetAlert({title:'folder Deletion' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true});
        }
    }

    const [FolderName,setFolderName] = useState('');
    const myFoldersUpdateApi = async (e , folderid) =>{
        e.preventDefault();
     
        let res = await userFolderUpdateApi({ folderid: folderid, name: FolderName});
        if(res.status===true){
            SweetAlert({title:'Folder Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
            props.callFolder();
        }
        else{
            SweetAlert({title:'Folder Updation' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true});
        }
    }


    /* open folder */
  
    const openfolderApi = async (folderid) =>{
    }



   return(<>


    <div className="folder-box col-sm-2 col-6" onClick={()=> {navigate(`/MyWorkspace?folder=all`)}}>
    <div className={`icon-box ${folderInfo==="all" ? "bg-light" : ""}  d-flex flex-column h-100`}>
    <div className="icon-box">
            <img src="/images/folder2.png" alt="img"  style={{height : 50, width : 50}} className='img-fluid'  />
        </div>
        <div className="paraBox">
            <p>All Workspaces</p>
        </div>
    </div>
       
    </div>


    {
        props.allfolder?.map((val,index)=>{
            return(<>
                <React.Fragment key={index}>
                <div className={`folder-box col-sm-2 col-6  `} >
                <div className={`icon-box ${folderInfo == val.id ? "bg-light" : ""}  d-flex flex-column h-100`}>
                        <div className="icon-box" onClick={()=> {navigate(`/MyWorkspace?folder=${val.id}`)}}>
                            <img src="/images/folder2.png" alt="img"  style={{height : 50, width : 50}} className='img-fluid'  onClick={()=>{openfolderApi(val.id)}} />
                        </div>
                        <div className="paraBox"  onClick={()=> {navigate(`/MyWorkspace?folder=${val.id}`)}}>
                            <p>{val.name}</p>
                        </div>
                        { val.status !=="default" && <div  className='icon-tag'> 
                        <i class="fa-solid fa-pen cursor-pointer"  data-bs-toggle="modal" data-bs-target={`#folder${val.id} `}></i> 
                        <i class="fa-regular fa-trash-can cursor-pointer" onClick={()=>{deleteFn(val.id)}}></i>
                        </div>}

                        </div>
                </div>
            <div class="modal fade" id={`folder${val.id}`} tabIndex={val.id} aria-labelledby={val.slug} aria-hidden="true"  >
  <div className="modal-dialog">
    <div className="modal-content bd-all">
      <div className="modal-header">
        <h5 class="modal-title" id="exampleModal8Label">Edit</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
            <form action="#" onSubmit={(e)=>{myFoldersUpdateApi(e, val.id)}}>
                <div className="form-group  my-3">
                    <label htmlFor="" className="label-tag">Name</label>
                    <input type="text" name="" id="" className="form-control bd-all rounded-0" defaultValue={val.name} onChange={(e)=>{setFolderName(e.target.value)}} />
                </div>
                <div className="form-group my-3">
                    <input type="submit" value="Save" className='btn-yellow' />
                </div>

            </form>
      </div>
    </div>
  </div>
            </div>
</React.Fragment>
            </>)
        })
    }

    </>)
}

