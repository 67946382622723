import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { supportCreateApi, supportPutApi, supportStatusApi, userAllApi } from './serviceapi/Service.home';
import { SweetAlert } from './sweetalert/SweetAlert';
import Select from 'react-select';


const OpenTicket = (props) => {
    const [value, setValue] = useState('');
    let formRef = useRef();


    const [newData , setnewData] = useState({
        subject : "",
        department : "",
        priority : "",
        message : "",
        user_id : "" ,
        attachment : ""
    });
    const handelChange = (e) =>{
        let name = e.target.name;
        let type = e.target.type;
        let value;
        if(type==='file'){
            value = e.target.files[0]
        }
        else{
            value = e.target.value;
        }
        setnewData({...newData , [name] : value})
    }

    let submitAction = async (e) =>{
        e.preventDefault();
        newData.message = value;
        

        if(props.action==='update'){
            newData.ticket_id = props.data.id;

            let res = await supportPutApi(newData);
            if(res.status==="TXN"){
                SweetAlert({title:'Ticket updated' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
            
                setnewData({
                    subject : "",
                    department : "",
                    priority : "",
                    message : "",
                    user_id : localStorage.getItem('userID') ,
                    attachment : ""
                });
                setValue('');
                props.tabledata();
                props.setactiontype('all');
                formRef.current.reset();
            }
            else{
                SweetAlert({title:'Ticket not created' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
            }
        }
        else if(props.action==='status'){
            newData.ticket_id = props.data.id;
            newData.status = newData.priority ||  props.data.priority

            let res = await supportStatusApi(newData);
            if(res.status==="TXN"){
                SweetAlert({title:'Ticket updated' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
        
                setnewData({
                    subject : "",
                    department : "",
                    priority : "",
                    message : "",
                    user_id : "" ,
                    attachment : ""
                });
                setValue('');
                props.setactiontype('all')
                formRef.current.reset();
            }
            else{
                SweetAlert({title:'Ticket not created' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
            }



        }
        else{
            if(localStorage.getItem('approle') === "user"){
                newData.user_id = localStorage.getItem('userID');
            }
            let res = await supportCreateApi(newData);
        if(res.status==='TXN'){
            SweetAlert({title:'Ticket created' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
            formRef.current.reset();
            setnewData({
                subject : "",
                department : "",
                priority : "",
                message : "",
                user_id : localStorage.getItem('userID') ,
                attachment : ""
            });
            setValue('')
        }
        else{
            SweetAlert({title:'Ticket not created' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
        }
        }

       
    }


    useEffect(()=>{
        if(props.action==='update'){
            setValue(props.data.message);
            setnewData(props.data);

        }
    }, [props]);


const [alluser,setalluser] = useState([])
    const allusersApi = async () =>{
        console.log(props , 'opopo')
      
        if( localStorage.getItem('approle') === "admin" ){
            let res = await userAllApi();
            if(res.status===200){
                setalluser(res.data);
            }

            let filterdata = res.data?.filter((item)=> item.id == props.data?.id);

            setdefaultUser(()=>({
                value: filterdata[0]?.email,
                label: `${filterdata[0]?.username} (${filterdata[0]?.email})`,
                id : filterdata[0]?.id
            }))

        console.log(filterdata , 'filterdata')

        }

    }

    useEffect(()=>{
        allusersApi();
    }, []);



const [defaultUser,setdefaultUser] = useState({
    value: "",
    label: "",
    id : ""
})
    
    useEffect(()=>{
        let filterdata = alluser?.filter((item)=> item.id == props.data?.id);
        console.log(filterdata , 'filterdata')
    }, [props])
    const options = alluser?.map((user) => ({
        value: user.email,
        label: `${user.username} (${user.email})`,
        id : user.id
      }));



  return (<>
  
  
    <Container fluid className="openTicket-wrapper">
        <Row className="block1">
            <Col sm={12} className="title-col">
         
            <Row>
            <Col lg={8} className="mx-auto">
                <>
                {props.action !== 'update' && <h4>Open a new ticket</h4>}
                </>

                <Form action="" className='row' onSubmit={submitAction} ref={formRef}>
                    <Form.Group className="form-group  col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag'>Subject</Form.Label>
                        <input type="text" id="" className="form-control rounded-0 bd-all"  name='subject' defaultValue={props.action==='update' ? props.data.subject : ''} onChange={handelChange} />
                    </Form.Group>

                    <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag'>Department</Form.Label>
                        <Form.Select id="" className=" rounded-0 bd-all" name='department'  defaultValue={props.action==='update' ? props.data.department : ''}  onChange={handelChange}>
                        <option value="" disabled>-- select department --</option>
                            <option value="sales department">Sales Department</option>
                            <option value="technical department">Technical Department</option>
                            <option value="general inquery">General Inquery</option>
                            <option value="billing department">Billing Department</option>

                        </Form.Select>
                    </Form.Group>
                    </Col>
                    <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag'>Priority</Form.Label>
                        <Form.Select id="" className="rounded-0 bd-all" name='priority' defaultValue={props.action==='update' ? props.data.priority : ''} onChange={handelChange}>
                            <option value="high">High</option>
                            <option value="medium" selected>Medium</option>
                            <option value="low">Low</option>
                        </Form.Select>

                    </Form.Group>
                    </Col>

                    <Col lg={localStorage.getItem('approle') === "admin" ? 6 : 12}>
                    <Form.Group className="form-group col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag'>Attachment</Form.Label>
                        <Form.Control type='file' className="rounded-0 bd-all"  name='attachment' onChange={handelChange} />
                    </Form.Group>
                    </Col>

                  {localStorage.getItem('approle') === "admin" ? 
                  
                  <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag'>User</Form.Label>
                        <Select
                        options={options}
                        defaultValue={defaultUser}
                        onChange={(val)=> setnewData((prev)=>({...prev , user_id : val.id})) }
                    
                        isSearchable
                        placeholder={`search by email id`}
                        className='rounded-0 bd-all'
                    />


                    </Form.Group>
                    </Col> : null}

                    {props.action==='update' &&
                    <Col lg={12}>
                    <Form.Group className="form-group col-12  my-2">
                    <Form.Label htmlFor="" className='label-tag w-100'>Attachment</Form.Label>
                        
                        <img src={props.data.attachment} alt="img" className='img-fluid' srcset="" />
                    </Form.Group>
                    </Col>}

                    <Form.Group className="form-group  col-12  my-2" >
                    <Form.Label htmlFor="" className='label-tag'>Message</Form.Label>
                    <div style={{'height' : '180px'}} >
                    <ReactQuill theme="snow" value={value} style={{'height' : '180px'}}  onChange={setValue} className='rounded-0' />
                    </div>
                    </Form.Group>

                    <Form.Group className="form-group col-12 d-flex justify-content-center  my-5">
                    <button className="btn-yellow" type='submit'>Submit</button>
                    </Form.Group>

                </Form>
            </Col>
            </Row>


            </Col>

       
        </Row>
    </Container>

  </>)
}

export default OpenTicket