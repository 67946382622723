import React from 'react'

const RoleForm = () => {
  return (<>
  
    <form action="" className='row'>
                <div className="my-3 form-group col-md-6">
                    <label htmlFor="" className="label-tag">Role Name</label>
                    <input type="text" name="" id="" className="form-control rounded-0 bd-all" />
                </div>
                <div className="my-3 form-group col-12">
                    <label htmlFor="" className="label-tag">Permission</label>
                    <div className="row">
                        <div className="col-md-4 col-6">
                        <label class="check-wrapper">Role one
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role one
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role one
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role one
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>
                        </div>
                        <div className="col-md-4 col-6">
                        <label class="check-wrapper">Role Two
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Two
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Two
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Two
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>
                        </div>

                        <div className="col-md-4 col-6">
                        <label class="check-wrapper">Role Three
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Three
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Three
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>

                        <label class="check-wrapper">Role Three
                            <input type="checkbox" />
                            <span class="ck-checkmark"></span>
                        </label>
                        </div>

                    </div>
                </div>

                <div className="form-group">
                    <input type="submit" value="Save" className='btn-all' />
                </div>
            </form>
  
  </>)
}

export default RoleForm