import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
    confirmUploadDataApi,
  deleteXlApi,
  deletekeywordsUploadDataApi,
  exportUploadDataApi,
  getKeywordsXlApi,
  getXlApi,
  importUploadDataApi,
  keywordsUploadDataApi,
} from "../serviceapi/Service.home";
import { SweetAlert } from "../sweetalert/SweetAlert";
import Spinner from "react-bootstrap/Spinner";
import { useEffect } from "react";
import CustomeTable from "../utils/CustomeTable";
import { formatDate } from "../../utils/formatDate";
import { SweetAlertConfirm } from "../../utils/SweetAlert";

const UploadData = () => {
  const [spin, setspin] = useState(false);
  const [spin1, setspin1] = useState(false);
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);


  const [uploaddatastate, setuploaddatastate] = useState(false);

  const [importStore, setimportStore] = useState("");
  const [exportStore, setexportStore] = useState("");
  const [keywordsStore, setkeywordsStore] = useState("");
  let impRef = useRef();
  let expRef = useRef();
  let formRef = useRef();

  const importApi = async (e) => {
    e.preventDefault();
    setspin(true);
    try {


    let findDuplicate = preData?.some((item)=> item.file === importStore.name );
    if(findDuplicate){
        SweetAlertConfirm(uploadConfirmImportData);
        setProgress1(0);

    }
    else{
        uploadImportData();
    }


    setProgress1(0);

    } catch (err) {
      console.log(err);
    }
    setProgress1(0);
    setspin(false);
  };

  const exportApi = async (e) => {
    setspin1(true);
    e.preventDefault();
    try {

        let findDuplicate = preData?.some((item)=> item.file === exportStore.name );
        if(findDuplicate){
            SweetAlertConfirm(uploadConfirmExportData);
        }
        else{
            uploadExportData();
        }


        setProgress2(0);

    } catch (err) {
      console.log(err);
    }
    setProgress2(0);
    setspin1(false);
  };


  const uploadExportData = async () =>{
    let res = await exportUploadDataApi(exportStore, setProgress2);
    if (res.status === "TXN") {
      SweetAlert({
        title: "Data Upload",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      expRef.current.reset();
      setexportStore("");
      getXlDataApi();
    } else {
      SweetAlert({
        title: "Data Upload",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }

  const uploadImportData = async () =>{
    let res = await importUploadDataApi(importStore, setProgress1);
    if (res.status === "TXN") {
      SweetAlert({
        title: "Data Upload",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      impRef.current.reset();
      setimportStore("");
      getXlDataApi();
    } else {
      SweetAlert({
        title: "Data Upload",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }

  const keywordsUploadApi = async () =>{
    let res = await keywordsUploadDataApi(keywordsStore, setProgress3);
    if (res.status === "TXN") {
      SweetAlert({
        title: "Keywords Upload",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      impRef.current.reset();
      setkeywordsStore("");
      getXlDataApi();
    } else {
      SweetAlert({
        title: "Keywords Upload",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }

  const deletekeywordsUploadApi = async (id) =>{
    let res = await deletekeywordsUploadDataApi({id});
    if (res.status === true) {
      SweetAlert({
        title: "Keywords Deleted",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      getXlDataApi();
    } else {
      SweetAlert({
        title: "Keywords failed to delete",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }

  const uploadConfirmImportData = async () =>{
    let res = await confirmUploadDataApi(importStore, setProgress1);
    if (res.status === true) {
      SweetAlert({
        title: "Data Upload",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      impRef.current.reset();
      setimportStore("");
      getXlDataApi();
      setProgress1(0);
    } else {
      SweetAlert({
        title: "Data Upload",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }
  const uploadConfirmExportData = async () =>{
    let res = await confirmUploadDataApi(exportStore, setProgress2);
    if (res.status === true) {
      SweetAlert({
        title: "Data Upload",
        msg: "success",
        icon: "success",
        btn: "close",
        dangerMode: true,
      });
      expRef.current.reset();
      setexportStore("");
      getXlDataApi();
      setProgress2(0);
    } else {
      SweetAlert({
        title: "Data Upload",
        msg: res.message,
        icon: "warning",
        btn: "close",
        dangerMode: true,
      });
    }
  }



  const [preData, setpreData] = useState([]);
  const [keywordsData, setkeywordsData] = useState([]);

  const getXlDataApi = async () => {
    try {
      let res = await getXlApi();
      if (res.status === "TXN") {
        setpreData(res.data);
      } else {
        console.log(res.message);
      }


      let res1 = await getKeywordsXlApi();
      if (res1.status === true) {
        setkeywordsData(res1.data);
      } else {
        console.log(res1.message);
      }


    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getXlDataApi();
  }, []);

  const deleteXlDataApi = async (id) => {
    try {
      let res = await deleteXlApi(id);
      if (res.status === "TXN") {
        SweetAlert({
          title: "Xl Sheet",
          msg: res.message,
          icon: "success",
          btn: "close",
          dangerMode: true,
        });
        getXlDataApi();
      } else {
        SweetAlert({
          title: "Xl Sheet",
          msg: res.message,
          icon: "warning",
          btn: "close",
          dangerMode: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* -- tables -- */

  const columnsSearch = [
    {
      name: "S.N",
      cell: (row, index) => index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "File Name",
      selector: (row) => row.file,
      sortable: true,
      width: "500px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Uploaded At",
      selector: (row) => formatDate(row.created_at),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="icon-tag d-flex align-items-center">
          {" "}
          <i
            class="far fa-trash-alt hov-r cursor-pointer"
            onClick={() => {
              deleteXlDataApi(row.id);
            }}
          ></i>{" "}
        </div>
      ),
      sortable: true,
    },
  ];

  const columnsKeywords = [
    {
      name: "S.N",
      cell: (row, index) => index + 1,
      sortable: true,
      width: "80px",
    },
    {
      name: "key Name",
      selector: (row) => row.keys,
      sortable: true,
      width: "auto",
    },
    {
      name: "Action",
      width: "100px",
      selector: (row) => (
        <div className="icon-tag">
          {" "}
          <i
            class="far fa-trash-alt hov-r cursor-pointer"
            onClick={() => {
              deletekeywordsUploadApi(row.id);
            }}
          ></i>{" "}
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      <Container fluid className="add-new-wrapper">

        <Row>
        <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Search Data">
      <Row className="row block1">
          <Col>
            <button
              className="btn-green"
              onClick={() => {
                setuploaddatastate(!uploaddatastate);
              }}
            >
              {uploaddatastate === false ? "Uploda Data" : "Close"}
            </button>

            {uploaddatastate === true ? (
              <>
                <Row>
                  <Col lg={6} className="my-2">
                    <Form onSubmit={importApi} ref={impRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Import Data</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              setimportStore(e.target.files[0]);
                            }}
                          />
                          {progress1 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress1}
                              label={`${progress1}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                          {/* <Button className='btn' type='submit'>Upload</Button> */}
                          <button type="submit" className="btn-yellow">
                            Upload{" "}
                            {spin ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>

                  <Col lg={6} className="my-2">
                    <Form onSubmit={exportApi} ref={expRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Export Data</Form.Label>
                          <Form.Control
                            type="file"
                            ref={expRef}
                            onChange={(e) => {
                              setexportStore(e.target.files[0]);
                            }}
                          />

                          {progress2 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress2}
                              label={`${progress2}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                          {/* <Button className='btn' type='submit'>Upload</Button> */}
                          <button type="submit" className="btn-yellow">
                            Upload{" "}
                            {spin1 ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={12} className="my-4">
            <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
              <h5>All Upload Data</h5>
            </div>
            <CustomeTable
              columns={columnsSearch}
              filterData={preData}
              search_data={false}
            />
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="profile" title="Keywords">
      <Row className="row block1">
          <Col>
            <button
              className="btn-green"
              onClick={() => {
                setuploaddatastate(!uploaddatastate);
              }}
            >
              {uploaddatastate === false ? "Uploda Data" : "Close"}
            </button>

            {uploaddatastate === true ? (
              <>
                <Row>
                  <Col lg={6} className="my-2">
                    <Form onSubmit={keywordsUploadApi} ref={expRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Upload keywords</Form.Label>
                          <Form.Control
                            type="file"
                            ref={expRef}
                            onChange={(e) => {
                              setkeywordsStore(e.target.files[0]);
                            }}
                          />

                          {progress3 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress3}
                              label={`${progress3}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                          <button type="submit" className="btn-yellow">
                            Upload{" "}
                            {spin1 ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={12} className="my-4">
            <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
              <h5>All Keywords</h5>
            </div>
            <CustomeTable
              columns={columnsKeywords}
              filterData={keywordsData}
              search_data={false}
            />
          </Col>
        </Row>
      </Tab>
    </Tabs>
        </Row>
        {/* <Row className="row block1">
          <Col>
            <Button
              className="btn-all"
              onClick={() => {
                setuploaddatastate(!uploaddatastate);
              }}
            >
              {uploaddatastate === false ? "Uploda Data" : "Close"}
            </Button>

            {uploaddatastate === true ? (
              <>
                <Row>
                  <Col lg={6} className="my-2">
                    <Form onSubmit={importApi} ref={impRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Import Data</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              setimportStore(e.target.files[0]);
                            }}
                          />
                          {progress1 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress1}
                              label={`${progress1}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Button type="submit" className="btn-spin">
                            Upload{" "}
                            {spin ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>

                  <Col lg={6} className="my-2">
                    <Form onSubmit={exportApi} ref={expRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Export Data</Form.Label>
                          <Form.Control
                            type="file"
                            ref={expRef}
                            onChange={(e) => {
                              setexportStore(e.target.files[0]);
                            }}
                          />

                          {progress2 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress2}
                              label={`${progress2}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Button type="submit" className="btn-spin">
                            Upload{" "}
                            {spin1 ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>

                  <Col lg={6} className="my-2">
                    <Form onSubmit={exportApi} ref={expRef}>
                      <Col md={12}>
                        <Form.Group className="my-3">
                          <Form.Label>Upload keywords</Form.Label>
                          <Form.Control
                            type="file"
                            ref={expRef}
                            onChange={(e) => {
                              setexportStore(e.target.files[0]);
                            }}
                          />

                          {progress2 ? (
                            <ProgressBar
                              className="rounded-1 mt-2"
                              now={progress2}
                              label={`${progress2}%`}
                            />
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="my-3">
                     
                          <Button type="submit" className="btn-spin">
                            Upload{" "}
                            {spin1 ? (
                              <Spinner
                                animation="border"
                                className="mx-1"
                                size="sm"
                              />
                            ) : null}
                          </Button>
                        </Form.Group>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={12} className="my-4">
            <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
              <h5>All Upload Data</h5>
            </div>
            <CustomeTable
              columns={columns}
              filterData={preData}
              search_data={false}
            />
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default UploadData;
