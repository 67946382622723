
import React, { useState } from 'react';
import Select, { components } from 'react-select';


const Tagselector = (props) => {
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleTagsChange = (selectedOptions) => {
    props.setTags(selectedOptions);
  };

  const handleCreateTag = () => {
    if (inputValue) {
      const newTag = {
        value: inputValue,
        label: inputValue,
      };

      props.setTags([...props.tags, newTag]);
      setInputValue('');
    }
  };

  const formatCreateTagLabel = (inputValue) => (
    <div>
      <span>create :</span>
      <small className="create-tag-label ms-1 text-danger text-underline text-underline cursor-pointer"><u>{inputValue}</u></small>
    </div>
  );

  const { MenuList } = components;

  const customMenuList = ({ children, ...rest }) => {
    const isExistingTag = props.tags?.some((tag) => tag.value === inputValue);
    const isValueInSuggestions = props.suggestions?.some((suggestion) => suggestion.value === inputValue);

    return (
      <MenuList {...rest}>
        {children}
        {!isExistingTag && !isValueInSuggestions && inputValue && (
          <div
            className="create-tag-option  px-2 "
            onMouseDown={handleCreateTag}
          >
            &nbsp; {formatCreateTagLabel(inputValue)}
          </div>
        )}
      </MenuList>
    );
  };



  return (
    <Select
      isMulti
      options={props.suggestions}
      value={props.tags}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleTagsChange}
      placeholder="Type to add tags..."
      formatCreateLabel={(inputValue) => `create : "${inputValue}"`}
      isClearable
      className="search-inp bd-all w-100"
      components={{ MenuList: customMenuList }}
      isDisabled={props.isDisabled}
    />
  );
};

export default Tagselector;
