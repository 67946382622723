import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { allpackageApi, allUserUpdateApi, assignpackageApi, profileUpdateApi, userRegApi } from '../serviceapi/Service.home';
import { SweetAlert } from '../sweetalert/SweetAlert';

const AddNewCustomers = (props) => {


    let userID = props.uid;
  
    const [validated, setValidated] = useState(false);
    const [selectedPackage, setselectedPackage] = useState("");
    const [searchPoints, setsearchPoints] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [updateUser, setUpdateUser] = useState(
        {
            "apptoken": localStorage.getItem('apptoken'),
            "user_id": props.uid,
            "firstname": props.firstname,
            "lastname": props.lastname,
            "phone": props.phone,
            // "profile_image": props.profile_image,
            "designation":props.designation,
            "sales_executive": props.sales_executive,
            "company": props.company
        }
    )
    const handleInp = (e) =>{
        let name = e.target.name;
        let value = e.target.value;

        setUpdateUser({...updateUser, [name] : value})

    }


    const handleSubmit = async (event,mode) => {
     
      event.preventDefault();
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        }
   
   
        if(props.mode==="edit"){
            updateUser.user_id = userID
            let res = await allUserUpdateApi(updateUser);
        if(res.status="TXN"){
            SweetAlert({title:'User Updation' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
            props.allusersApi();
            props.seteditMode('all');
        }
        else{
            SweetAlert({title:'User Updation' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
        }
        }

      if(mode==='assign'){

        let res = await assignpackageApi({    
        "apptoken": localStorage.getItem('apptoken'),
        "customerid": userID,
        "packageid": selectedPackage,
        "searchingpoints": searchPoints,
        "startDate": startDate,
        "endDate": endDate})
        if(res.status==='TXN'){
            SweetAlert({title:'Package' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
            props.seteditMode('all');
            props.allusersApi();

        }
        else{
         SweetAlert({title:'Package' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
        }
      }
  
      setValidated(true);
    };

    
  

    const [Package, setPackage] = useState([])

    
    /* -- get api -- */
    const getApi = async () =>{
      let res = await allpackageApi();
      if(res.status === 'TXN'){
        setPackage(res.data);
      }
    }
  
    useEffect(()=>{
      getApi();
    }, []);


   /* -- createuser api -- */
   const [newUser, setnewUser] = useState({
    firstname : "",
    lastname : "",
    username : "",
    email : "",
    password : "",
    phone : "",
    role_id : "2"
   });

   const handelInp = (e) =>{
    let name = e.target.name;
    let value = e.target.value;
    setnewUser({...newUser , [name]:value})

   }



   let formRef = useRef();

   const createuserApi = async (e) =>{
    e.preventDefault();

       let res = await userRegApi(newUser);
       if(res.status === 'TXN'){
        formRef.current.reset()
        SweetAlert({title:'New User' , msg : 'Registration Success' , icon : 'success', btn : 'close', dangerMode: true})
        props.allusersApi();
        props.seteditMode('all');
    }
       else{
        SweetAlert({title:'New User' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
       }
     }

  return (<>
  

        <div className="container-fluid add-new-wrapper">
            <div className="row block1">
                <div className="col-12">
                   {props.mode==='edit' ?
                   
                    <Form action="#" className="row" autoCapitalize='off'  noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="firstname">First Name</label>
                            <input type="text" name="firstname" id="" defaultValue={props.firstname} className="form-control rounded-0 bd-all" onChange={handleInp} />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="lastname">Last Name</label>
                            <input type="text" name="lastname" id="" defaultValue={props.lastname} className="form-control rounded-0 bd-all" onChange={handleInp} />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Username</label>
                            <input type="username" name="username" id="" defaultValue={props.username} className="form-control rounded-0 bd-all" disabled />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Email</label>
                            <input type="email" name="email" id="" defaultValue={props.email} className="form-control rounded-0 bd-all" disabled />
                        </div>
{/* 
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Add Role</label>
                            <select name="" id="" defaultValue={props.role} className="form-select rounded-0 bd-all">
                                <option value="" disabled >--select--</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </select>
                        </div> */}


                        {/* <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="" defaultValue={props.package} >Select Package</label>
                            <select name="" id="" className="form-select rounded-0 bd-all">
                                <option value="" disabled >--select--</option>
                                {Package?.map((val)=>{
                                    return(<>
                                <option value={val.id} key={val.id}>{val.name}</option>

                                    </>)
                                })}
                            </select>
                        </div> */}

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Contact Number</label>
                            <input type="number" name="phone" id="" defaultValue={props.phone} className="form-control rounded-0 bd-all" onChange={handleInp} />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Company Name</label>
                            <input type="text" name="company" id="" defaultValue={props.company} className="form-control rounded-0 bd-all" onChange={handleInp} />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Designation<span className="tx-red">*</span> </label>
                            <input type="text" name="designation" id="" defaultValue={props.designation} className="form-control rounded-0 bd-all" onChange={handleInp} />
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>
                        {/* <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Company Address<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" defaultValue={props.companyaddress} className="form-control rounded-0 bd-all" onChange={handleInp} />
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div> */}

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Sales Executive</label>
                            <input type="text" name="sales_executive" id="" defaultValue={props.sales} className="form-control rounded-0 bd-all" onChange={handleInp} />
                        </div>

                        <div className="form-group col-12 my-md-3 my-2">
                        <button className="btn-yellow" type='submit'>{props.mode ==='edit'? 'Update User' : 'Add New'}</button>
                        </div>
                    </Form>
                    
                    :
                    props.mode==='assign' ?
                    <Form action="#" className="row" autoCapitalize='off'  noValidate validated={validated} onSubmit={(e)=>{handleSubmit(e , props.mode)}}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Name</label>
                            <input type="text" name="" id="" defaultValue={`${props.firstname} ${props.lastname}`} className="form-control rounded-0 bd-all" disabled />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Username</label>
                            <input type="username" name="" id="" defaultValue={props.username} className="form-control rounded-0 bd-all" disabled />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Email</label>
                            <input type="email" name="" id="" defaultValue={props.email} className="form-control rounded-0 bd-all" disabled />
                        </div>


                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="" defaultValue={props.package} >Select Package</label>
                            <select name="" id="" className="form-select rounded-0 bd-all" onChange={(e)=>{setselectedPackage(e.target.value)}}>
                                <option value="" disabled >--select--</option>
                                {Package?.map((val)=>{
                                    return(<>
                                <option value={val.id} key={val.id}>{val.name}</option>

                                    </>)
                                })}
                            </select>
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Searching Points</label>
                            <input type="" name="" id="" defaultValue={props.searchPoints} className="form-control rounded-0 bd-all" onChange={(e)=>{setsearchPoints(e.target.value)}} />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Start Date</label>
                            <input type="date" name="" id="" defaultValue={props.startDate} className="form-control rounded-0 bd-all"  onChange={(e)=>{setstartDate(e.target.value)}} />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">End Date</label>
                            <input type="date" name="" id="" defaultValue={props.endDate} className="form-control rounded-0 bd-all"  onChange={(e)=>{setendDate(e.target.value)}} />
                        </div>

                        <div className="form-group col-12 my-md-3 my-2">
                        <button className="btn-all" type='submit'>Update</button>
                        </div>
                    </Form>

                    :

                    <Form action="#" className="row" autoCapitalize='off' ref={formRef}  noValidate validated={validated} onSubmit={createuserApi}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">First Name</label>
                            <input type="text" name="firstname" id="" defaultValue={props.firstname} className="form-control rounded-0 bd-all" onChange={handelInp} req />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Last Name</label>
                            <input type="text" name="lastname" id="" defaultValue={props.lastname} className="form-control rounded-0 bd-all" onChange={handelInp} required />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Username</label>
                            <input type="text" name="username" id="" defaultValue={props.username} className="form-control rounded-0 bd-all" onChange={handelInp} required />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Email</label>
                            <input type="email" name="email" id="" defaultValue={props.email} className="form-control rounded-0 bd-all" onChange={handelInp} required />
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">password</label>
                            <input type="text" name="password" id="" defaultValue={props.password} className="form-control rounded-0 bd-all" onChange={handelInp} required />
                        </div>

                        {/* <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Add Role</label>
                            <select name="" id="" defaultValue={props.role} className="form-select rounded-0 bd-all">
                                <option value="" disabled selected>--select--</option>
                                <option value="admin">Admin</option>
                                <option value="user">User</option>
                            </select>
                        </div> */}


                        {/* <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="" defaultValue={props.package} >Select Package</label>
                            <select name="" id="" className="form-select rounded-0 bd-all">
                                <option value="" disabled selected>--select--</option>
                                {Package?.map((val)=>{
                                    return(<>
                                <option value={val.id} key={val.id}>{val.name}</option>

                                    </>)
                                })}
                            </select>
                        </div> */}

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Contact Number</label>
                            <input type="text" name="phone" id="" defaultValue={props.phone} className="form-control rounded-0 bd-all" onChange={handelInp} required />
                        </div>
                        {/* <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Company Name</label>
                            <input type="text" name="" id="" defaultValue={props.company} className="form-control rounded-0 bd-all" />
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Designation<span className="tx-red">*</span> </label>
                            <input type="text" name="" id="" defaultValue={props.designation} className="form-control rounded-0 bd-all" required />
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Company Address<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" defaultValue={props.companyaddress} className="form-control rounded-0 bd-all" required />
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Sales Executive</label>
                            <input type="text" name="" id="" defaultValue={props.sales} className="form-control rounded-0 bd-all" />
                        </div> */}

                        <div className="form-group col-12 my-md-3 my-2">
                        <button className="btn-yellow" type='submit'>{props.mode ==='edit'? 'Update User' : 'Add User'}</button>
                        </div>
                    </Form>
                    
                    }
                </div>
            </div>
        </div>
  
  
  </>)
}

export default AddNewCustomers