import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';

const CreateChapter = () => {

    
    
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
  



  return (<>
  
  
  

  <div className="container-fluid add-new-wrapper">
            <div className="row block1">
                <div className="col-12">
                    <Form action="#" className="row" autoCapitalize='off'  noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Chapter Name<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Country</label>
                            <select name="" id="" className="form-select rounded-0 bd-all">
                                <option value="" disabled >--select--</option>
                                <option value="">--select--</option>
                                <option value="">--select--</option>
                            </select>
                        </div>


                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Chapter Code<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">ITCHS<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>

                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Chapter Discription</label>
                            <textarea name="" id=""  className="form-control rounded-0 bd-all" ></textarea>
                           
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Policy</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Social Welfare Subcharge</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">GST</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Total Duity %</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">SEQC</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Length</label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Import File</label>
                            <input type="file" name="" id="" className="form-control rounded-0 bd-all" required/>
                        </div>
                        <div className="form-group  col-12 my-md-3 my-2">
                        <button className="btn-all" type='submit'>Save</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
  
  
  </>)
}

export default CreateChapter