import React from 'react'

const SearchPage = () => {
  return (<>
  
  <div className="container-fluid admin-settings-wrapper">
    <div className="row block1">
        {/* <div className="col-12">
            <h4>Search Any Details</h4>

            <form action="" className='row'>
                <div className="form-group searchBox my-2 d-flex">
                <select name="" id="" className='form-select rounded-0 bd-all'>
                    <option value="">Product Name</option>
                    <option value="">HSN</option>
                </select>
                <input type="text" className='form-control rounded-0 bd-all'  placeholder='Search Details' />
                <button className='btn-all'>Search</button>
                </div>
            </form>
        </div> */}
    </div>
  </div>
  
  
  </>)
}

export default SearchPage