import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LogBackground from './utils/LogBackground';
import { forgetpasswordApi } from './serviceapi/Service.home';
import { Button, Spinner } from 'react-bootstrap';
import { SweetAlertSingle } from '../utils/SweetAlert';


const ForgetPassword = () => {
    let navigate = useNavigate();

    let [email,setEmail] = useState('');
    const [spin, setspin] = useState(false);

    const postApi = async () =>{
        setspin(true);
        let res = await forgetpasswordApi({email :email });
        if(res.status==="TXN"){
        setEmail('');
        SweetAlertSingle({title:'Password reset token success' , msg : res.message , icon : 'success', showCancelButton:false})
        }
        else{
        SweetAlertSingle({title:'Password reset token failed' , msg : res.message , icon : 'warning', showCancelButton:false})
        }

        setspin(false);
    }
  return (<>
  

  <LogBackground class='RegisterContainer' element={    
  
  <div className='loginBox'>
        <div className='loginRegisterBtnBox'>
            <small onClick={()=>{navigate('/login')}} className='cursor-pointer'>Back to login</small>
        </div>
        <div className='welcomTxt'>
            <h1 className='headTag'>Forget Password ?</h1>
            <span className='paraTag'>Please enter to your account</span>
        </div>
        <form>
            <div className='frow frow1'>
                <lable className='LableTag'>Enter email address</lable>
                <input type='text' className='mt-3' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
            </div>
     
                <button type='button' onClick={()=>{postApi()}} className='btn-spin' >RESET PASSWORD {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</button>
     

        </form>
    </div>
    
    } />


  
  
  </>)
}

export default ForgetPassword