import Swal from 'sweetalert2'


export const SweetAlertConfirm = (callbackFn) =>{
        Swal.fire({
            title: 'File already exits, do you want to overwrite?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            timer : 3000,
            showCancelButton: true,
            confirmButtonColor: '#009646',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
          }).then((result) => {
            if (result.isConfirmed) {
              callbackFn();
            }
          })
}

export const SweetAlertFolderConfirm = (callbackFn) =>{
  Swal.fire({
      title: 'do you want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      timer : 3000,
      showCancelButton: true,
      confirmButtonColor: '#009646',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        callbackFn();
      }
    })
}


export const SweetAlertSingle = ({title,text,icon,showCancelButton}) =>{
        Swal.fire({
            title,
            text,
            icon,
            timer : 3000,
            showCancelButton,
            confirmButtonColor: '#000000',
          })

}


