import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CustomeTable from '../utils/CustomeTable';
import { showhistorydataApi, userdashbaorddataApi } from '../serviceapi/Service.home';
import { useNavigate } from 'react-router-dom';

const UserDashboard = () => {
  let navigate= useNavigate();
  const gotoSearch = (newData) =>{
    const url = `/home?workspace=true` +
    `&searchid=${encodeURIComponent(newData.id)}` +
    `&datatype=${encodeURIComponent(newData.datatype)}` +
    `&multiplesearchtext=${encodeURIComponent(newData.multiplesearchtext || '')}` +
    `&multiplesearchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +
    `&multipleoperator=${encodeURIComponent(newData.multipleoperator || '')}` +
    `&operator=${encodeURIComponent(newData.operator || '')}` +

    `&searchtext=${encodeURIComponent(newData.searchtext || '')}` +
    `&searchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +

    `&period=${encodeURIComponent(newData.period || '')}` +
    `&fromdate=${encodeURIComponent(newData.fromdate || '')}` +
    `&todate=${encodeURIComponent(newData.todate || '')}` +
    `&searchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}` +
    `&multiplesearchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}`;

  // Navigate to the new URL
  navigate(url);
}
    const [searchFilter , setsearchFilter] = useState({
        type : "Recentsearchdata",
        days : 30
    });
    let [preData,setpreData] = useState([]);
    let [dashboardData,setdashboardData] = useState([]);

    let [spin,setspin] = useState([]);
  
  

    const showHistory = async () =>{
        let res = await showhistorydataApi(searchFilter);
        if(res.status===true){
            setpreData(res.data.logindata);
        }
        else{
            setpreData([]);
        }
    }

    const getApi = async () =>{
        setspin(true);
        let res = await userdashbaorddataApi(searchFilter);
        if(res.status==="TXN"){
            setdashboardData(res.data);
        }

        else{
            setdashboardData([]);
        }
        setspin(false);
    }

    useEffect(()=>{
        showHistory();
        getApi()
    } , []);
  
    const columns = [
        {
          name : "S.N",
          cell: (row, index) => index + 1,
          sortable: true,
          width: "80px"
        },
        {
            name : "Data type",
            selector : row =>  <strong className='text-decoration-underline my-0'  onClick={()=> gotoSearch(row)}>{row.datatype}</strong>,
            sortable: true,
            width: "200px"

          },
          {
            name : "Search field",
            selector : row => row.multiplesearchfield,
            sortable: true,
            width: "200px"
          },
          {
            name : "Period",
            selector : row => row.period,
            sortable: true,
            width: "200px"
          },
          {
            name : "From date",
            selector : row => row.fromdate,
            sortable: true,
            width: "200px"
          },
          {
            name : "To date",
            selector : row => row.todate,
            sortable: true,
            width: "200px"
          },
          {
            name : "Search data",
            selector : row => row.searchdata,
            sortable: true,
            width: "200px"
          },
          {
            name : "Search operator",
            selector : row => row.multiplesearchoperator,
            sortable: true,
            width: "200px"
          },
          {
            name : "Search text",
            selector : row => row.multiplesearchtext,
            sortable: true,
            width: "200px"
          },
          {
            name : "Generated On",
            selector : row => row.GeneratedOn,
            sortable: true,
            width: "300px"
          }
      ]


//       <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//       width="50" height="50" viewBox="0 0 1280.000000 640.000000"
//       preserveAspectRatio="xMidYMid meet">
//      <g transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
//      fill="#dddddd" stroke="none">
//      <path d="M9638 6075 c-772 -93 -1504 -467 -2317 -1184 -198 -174 -334 -303
//      -657 -624 -230 -229 -264 -258 -281 -249 -11 6 -100 93 -199 194 -1144 1171
//      -1886 1664 -2745 1822 -560 103 -1141 38 -1669 -186 -885 -376 -1533 -1167
//      -1714 -2093 -41 -207 -51 -318 -51 -565 0 -392 54 -719 176 -1065 338 -964
//      1145 -1627 2164 -1779 634 -95 1337 26 1957 335 243 121 448 250 703 441 321
//      239 556 450 1061 950 176 175 327 318 334 318 8 0 77 -66 154 -146 172 -179
//      592 -584 770 -743 820 -731 1442 -1055 2226 -1163 58 -8 194 -12 350 -12 213
//      0 279 4 400 23 650 100 1205 379 1659 832 457 457 742 1045 823 1699 19 155
//      16 563 -6 720 -78 561 -275 1067 -568 1457 -95 128 -294 332 -425 435 -364
//      290 -837 486 -1378 570 -172 26 -594 34 -767 13z m-6331 -1199 c336 -71 676
//      -233 1010 -481 119 -88 297 -234 413 -339 146 -133 880 -850 880 -860 0 -16
//      -640 -649 -795 -788 -492 -438 -947 -715 -1375 -839 -202 -58 -321 -74 -565
//      -74 -190 0 -235 3 -334 23 -590 119 -1022 504 -1210 1077 -160 489 -130 953
//      90 1385 252 496 706 824 1266 915 63 10 146 13 293 10 172 -3 225 -8 327 -29z
//      m6863 19 c327 -46 605 -171 841 -378 439 -384 649 -1063 517 -1677 -134 -625
//      -615 -1131 -1229 -1295 -148 -39 -279 -55 -454 -55 -310 0 -559 62 -870 215
//      -327 160 -642 391 -1037 761 -248 232 -743 719 -746 734 -3 16 466 495 668
//      681 682 630 1221 933 1800 1014 130 18 382 18 510 0z"/>
//      </g>
// </svg>

    const data = [
        {
            title : "No of Downloads",
            count : "90/100",
            color: "#dddddd",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#dddddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            
            
        },
        {
            title : "No of search",
            count : `${dashboardData.UserInfo?.used_search} / 1000`,
            color: "#ffba0d",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#dddddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

        },
        {
            title : "total workspace",
            count : `${dashboardData.FolderCount} / 1000`,
            color : "#7a1ded",
            icon: <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#dddddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>

        },
        {
            title : "support tickets",
            count : dashboardData.TicketCount || '0',
            color : "#ef605a",
            icon:  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="#dddddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

        },
    ]
  return (
    <Container>
        <Row>
         {data.map((val,index) =>
         {
          return(
            <Col key={index} lg={3} md={4} sm={6} className='my-lg-3 my-2'>
                <div className="box d-flex align-items-center justify-content-between p-4 rounded-2" style={{ borderLeft : `5px solid ${val.color}`, boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px"}}>
                    <ul className='list-unstyled me-3'>
                        <li><h6><strong className='text-uppercase'>{val.title}</strong></h6></li>
                        <li><strong>{val.count}</strong></li>
                    </ul>
                    <div className="icon">
                    {val.icon}
                    </div>
                </div>
            </Col>
          )}
         
         )  }



<Col xs={12} className='my-5'>
<CustomeTable columns={columns} filterData={preData}   tableTitle={'Recent Search'} />
</Col>
        </Row>
    </Container>
  )
}

export default UserDashboard