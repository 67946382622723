import React, { useEffect, useState } from 'react'
import { getDownloadHistoryApi } from '../serviceapi/Service.home'
import CustomeTable from '../utils/CustomeTable'
import {download} from 'react-icons-kit/fa/download';
import Icon from 'react-icons-kit';
import {ic_remove_red_eye} from 'react-icons-kit/md/ic_remove_red_eye'
import { useNavigate } from 'react-router-dom';
const MyDownloads = () => {
  let navigate= useNavigate();
  const gotoSearch = (newData) =>{
    const url = `/home?workspace=true` +
    `&searchid=${encodeURIComponent(newData.id)}` +
    `&datatype=${encodeURIComponent(newData.datatype)}` +
    `&multiplesearchtext=${encodeURIComponent(newData.multiplesearchtext || '')}` +
    `&multiplesearchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +
    `&multipleoperator=${encodeURIComponent(newData.multipleoperator || '')}` +
    `&operator=${encodeURIComponent(newData.operator || '')}` +

    `&searchtext=${encodeURIComponent(newData.searchtext || '')}` +
    `&searchfield=${encodeURIComponent(newData.multiplesearchfield || '')}` +

    `&period=${encodeURIComponent(newData.period || '')}` +
    `&fromdate=${encodeURIComponent(newData.fromdate || '')}` +
    `&todate=${encodeURIComponent(newData.todate || '')}` +
    `&searchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}` +
    `&multiplesearchoperator=${encodeURIComponent(newData.multiplesearchoperator || '')}`;

  // Navigate to the new URL
  navigate(url);
}
  let [preData,setpreData] = useState([]);
  let [filterData,setfilterData] = useState([]);


  const getChapterFn = async () =>{
    let res = await getDownloadHistoryApi();
    if(res.data){
      setpreData(res.data.logindata);
      setfilterData(res.data);
    }
  }

  useEffect(()=>{
    getChapterFn();
  }, []);

  const columns = [        
                          {
                            name : "Query Id",
                            cell: (row, index) => index + 1,
                            sortable: true,
                            width: "150px"
                          },
                          {
                          name : "Workspace Name",
                          selector : row =>  row.Folder || 'N/A',
                          sortable: true,
                         width: "180px"

                          },
                          {
                          name : "Data Type",
                          selector : row =>  row.datatype || 'N/A',
                          sortable: true,
                         width: "180px"

                          },
                          {
                          name : "Shipments",
                          selector : row => row.item_desc || 'N/A',
                          sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Points Deducted",
                            selector : row => row.PointDeducted || 'N/A',
                            sortable: true,
                         width: "170px"

                          },
                          {
                            name : "Shipments",
                            selector : row => row.item_desc || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Username",
                            selector : row => row.item_desc || 'N/A',
                            sortable: true,
                         width: "150px"

                          },
                          {
                            name : "Generated On",
                            selector : row => row.GeneratedOn || 'N/A',
                            sortable: true,
                         width: "240px"

                          },
                          {
                            name : "Download Type",
                            selector : row => row.User_Agent || 'N/A',
                            sortable: true,
                         width: "160px"

                          },
                          {
                            name : "Download Link",
                            selector : row => <> <Icon size={20} onClick={()=> gotoSearch(row)} icon={ic_remove_red_eye} className='text-warning' title='visit' /> <a href={row.link}><Icon size={20} icon={download} className='text-warning' title='download' /></a>
                            
                            
                             </>,
                            sortable: true,
                         width: "150px"

                          },
  ]


  return (<>
  
  
  <div className="container-fluid add-new-wrapper">
            <div className="row block1">
                <div className="col-12">
                <CustomeTable columns={columns} filterData={preData} tableTitle={'My Downloads'}  />
                </div>
            </div>
  </div>
  
  </>)
}

export default MyDownloads