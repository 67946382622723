import React from 'react'
import RoleForm from './RoleForm'
import Select from 'react-select'
const UserRole = () => {

    
    let addnewrole = () =>{
        document.getElementById('all-role-wrapper').style.display = 'none'
        document.getElementById('add-new-role-wrapper').style.display = 'block'

    }

    let allrolelist = () =>{
        document.getElementById('all-role-wrapper').style.display = 'block'
        document.getElementById('add-new-role-wrapper').style.display = 'none'

    }


  return (<>
  
  <div className="container-fluid add-new-wrapper" id='all-role-wrapper'>
            <div className="row block1">
            <div className="col-12 my-3">
                <button className='btn-all' onClick={()=>{addnewrole()}}>Add New Role</button>
            </div>
                <div className="col-12">
                <div className="table-res my-2">
            
            <table className='table'>
                <thead className='bd-all'>
                    <tr>
                        <td> <div> SN </div></td>
                        <td> <div> Role</div></td>
                        <td> <div> Created Date </div></td>
                        <td> <div> Action </div></td>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td><div>02</div></td>
                        <td><div>Super Admin</div></td>
            
                        <td><div>31/08/2022</div></td>
                        <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x" data-bs-toggle="modal" data-bs-target="#exampleModal1"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>

                    </tr>
                    <tr>
                        <td><div>01</div></td>
                        <td><div>Admin</div></td>
            
                        <td><div>31/08/2022</div></td>
                                                    <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>

                    </tr>
                    <tr>
                        <td><div>03</div></td>
                        <td><div>Engineer</div></td>
            
                        <td><div>31/08/2022</div></td>
                                                    <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>

                    </tr>

                    
                    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bd-all">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModal1Label">Edit Role</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <RoleForm/>
      </div>
    </div>
  </div>
</div>
                </tbody>

            </table>
        </div>
                </div>
            </div>
        </div>  


    <div className="container-fluid" id='add-new-role-wrapper'  style={{'display' : 'none'}}>
        <div className="row block2">
        <div className="col-12">
            <p className='cursor-pointer'> <a href="#" className='tx-blue' onClick={()=>{allrolelist()}}><i class="fa-solid fa-arrow-left i-x"></i>Back to All Role</a> </p>
        </div>

        <div className="col-12">
        <RoleForm/>
        </div>
        </div>
    </div>
  
  </>)
}

export default UserRole