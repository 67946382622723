import React, { useContext} from 'react'
import { GlobalData } from '../App'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar, { user_menu } from './sidebar/Sidebar'
import Header from './Header'
import { NavLink, useLocation } from 'react-router-dom'


const Dashboard = ({children}) => {

let {sidebarshow , setsidebarShow} = useContext(GlobalData);
let location = useLocation();
  return (<>

    <Container fluid className='dashboard-main-container px-0 overflow-hidden'>
      <Row className='dashboard-main-row'>
        <Col xs={12}  className='dashboard-header-col'><Header show={sidebarshow}   setShow={setsidebarShow} /></Col>

      
        {localStorage.getItem('approle')==='user' ? 
        <Col xs={12} className='mx-auto'>


        <div className="container-fluid px-0 d-lg-block d-none header-2 sticky-top bg-white">
        <nav className="navbar navbar-expand-lg ">
                  <div className="container nav-container">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">

                      {
                        user_menu.map((val,index)=>{
                          return(<>
                            {
                              val.submenu ? 

                              <li className="nav-item my-auto dropdown-x">
                          <a className="nav-link" >
                            {val.name} <img src="asset/arrow-down.png" className="ms-1" alt="" style={{height: 12, width: 12}} />
                          </a>
                          <ul className="dropdown-menu-x py-0">
                            <div className="content-body">
                            {
                              val.submenu?.map((sval,sindex)=>{
                                return <NavLink to={sval.path} key={sindex} className="dropdown-x-item my-auto" href="about.html">{sval.name}</NavLink>
                              })
                            }
                
                            </div>
                          </ul>
                        </li>

                        :

                        <li className="nav-item my-auto">
                          <NavLink to={val.path} key={index} className="nav-link" aria-current="page" href="index.html">{val.name}</NavLink>
                        </li>
                            }
                          </>)
                        })
                      }

                      </ul>

                    </div>
                  </div>
        </nav>
    </div>


          {/* <div className="box d-flex flex-row">
            {
              user_menu.map((val,index)=>{
                return <div className="navlinkItem py-3 border-end ps-3"><NavLink key={index} to={val.path} className="text-decoration-none text-black me-4">{val.name}</NavLink></div>
              })
            }
          </div> */}
          </Col>
           :  null }
      
        <Col xs={12}  className='dashboard-main-content-col d-flex'>
          {localStorage.getItem('approle')==="admin"  ? <div className="dashboard-sidebar-wrapper">
          <Sidebar  show={sidebarshow} />
          </div> :  null  }
          <div className="dashboard-content-wrapper w-100 p-3" style={{height : '90vh' , overflowY : 'scroll'}}>
          {children}
          </div>
        </Col>
      </Row>
    </Container>
  
  
  </>)
}

export default Dashboard;







