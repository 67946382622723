import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { GlobalData } from '../../App';

const LogBackground = (props) => {
    let globaldata = useContext(GlobalData);
  return (
    <Container fluid className={`container-fluid  ${props.class}`} style={{'backgroundImage' : `url(${globaldata.sitedata.loginbgimage || '../../../public/images/bgOverlay.png' })`}}>
    <Row className='row block1'>
  
    <Col sm={12} className='loginCol'>
  
          <div className='loginRegisterBox d-flex'>
              <div className='imgBox'>
                  <img src={globaldata.sitedata.logo} alt='logo' className='d-none' />
              </div>
                {
                    props.element
                }
          </div>
  
    </Col>
  
    </Row>
  
  
    </Container>
  )
}

export default LogBackground