import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./style/dashboard.css";
import "./style/navbar.css";
import { Icon } from "react-icons-kit";
import { GlobalData } from "../App";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Accordion,
  Button,
  Dropdown,
  Modal,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import AnnounceMarquee from "./announcment/Announce.jsx";
import { admin_menu, user_menu } from "./sidebar/Sidebar";
import { ic_menu } from "react-icons-kit/md/ic_menu";
import { ic_notifications } from "react-icons-kit/md/ic_notifications";

const Header = (props) => {
  const myaccountdata = useContext(GlobalData);
  let { profilFn, myprofile } = useContext(GlobalData);
  let navigate = useNavigate();

  // let profileFnn = () => {
  //   let items = document.querySelectorAll(".nav-link-main");
  //   items.forEach(function (item) {
  //     item.classList.remove("active");
  //   });

  //   let items2 = document.querySelectorAll(".tab-pane-main");
  //   items2.forEach(function (item00) {
  //     item00.classList.remove("active");
  //     item00.classList.remove("show");
  //   });

  //   let allUsersTabPane = document.getElementById("myaccount-tab-pane");
  //   allUsersTabPane.classList.add("active");
  //   allUsersTabPane.classList.add("show");
  // };

  // const [MyProfileData, setMyProfileData] = useState("");

  useEffect(() => {
    profilFn();
  }, []);

  const logoutFn = () => {
    navigate("/login");
    localStorage.clear("access_token");
    localStorage.clear("apptoken");
    localStorage.clear("userID");
    profilFn();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let [webmenu, setwebmenu] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("approle") === "admin") {
      setwebmenu(admin_menu);
    }
    if (localStorage.getItem("approle") === "user") {
      setwebmenu(user_menu);
    }
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between top-header-box px-3"
        style={{ height: "100%" }}
      >
        <div className="logoBox">
          <div className="my-auto logo-alt">
            <img
              src={myaccountdata.sitedata.logo}
              alt=""
              className="img-fluid"
              style={{userSelect : 'nones'}}
            />
          </div>
          
        </div>
        {myaccountdata.fdata === "user" ? <AnnounceMarquee /> : null}

        <div className="profileBox d-none d-lg-flex justify-content-between align-items-center">
          {localStorage.getItem("approle") === "user" && (
            <div className="notificationBox mx-2 d-flex justify-content-between align-items-center">
              <div className="imgBox bellBox cursor-pointer mx-2">
                <div className="drop-title">
                  <img src="/images/diskdownload.svg" alt="img" className="" />
                  <div className="countBox">
                    {myprofile.package_data?.remaining_no_of_download || 0}
                  </div>
                </div>
              </div>

              <div className="imgBox msgBox cursor-pointer mx-2">
                <div className="drop-title">
                  <img src="/images/search-black.svg" alt="img" className="" />
                  <div className="countBox">
                    {myprofile.package_data?.remaining_no_of_search || 0}
                  </div>
                </div>
              </div>
              <div className="bell-box notification-conent">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="bg-transparent text-black"
                    id="dropdown-basic"
                  >
                    <Icon icon={ic_notifications} size={30} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <ul className="list-unstyled">
                      <li>
                        <div className="d-flex justify-content-between">
                          <span className="date-tag">24 Feb 2023 </span>
                          <span className="status-tag">10:00 AM</span>
                        </div>
                        <div className="tx-ep-2">
                          Lorem ipsum dolor sit amet.orem ipsum dolor sit
                          amet.orem ipsum dolor sit amet.
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <span className="date-tag">24 Feb 2023</span>
                          <span className="status-tag">10:00 AM</span>
                        </div>
                        <div className="tx-ep-2">
                          Lorem ipsum dolor sit amet.orem ipsum dolor sit
                          amet.orem ipsum dolor sit amet.
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <span className="date-tag">24 Feb 2023</span>
                          <span className="status-tag">10:00 AM</span>
                        </div>
                        <div className="tx-ep-2">
                          Lorem ipsum dolor sit amet.orem ipsum dolor sit
                          amet.orem ipsum dolor sit amet.
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <span className="date-tag">24 Feb 2023</span>
                          <span className="status-tag">10:00 AM</span>
                        </div>
                        <div className="tx-ep-2">
                          Lorem ipsum dolor sit amet.orem ipsum dolor sit
                          amet.orem ipsum dolor sit amet.
                        </div>
                      </li>
                      <li>
                        <div className="d-flex justify-content-between">
                          <span className="date-tag">24 Feb 2023</span>
                          <span className="status-tag">10:00 AM</span>
                        </div>
                        <div className="tx-ep-2">
                          Lorem ipsum dolor sit amet.orem ipsum dolor sit
                          amet.orem ipsum dolor sit amet.
                        </div>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}

          <div className="adminBox dropdown">
            <div
              className=" ms-3 d-flex justify-content-between align-items-center"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ul className="list-unstyled">
                <li className="mt-3">
                  Hello,<strong>{myprofile.user?.firstname}</strong>
                </li>
              </ul>
              <div className="imgBox ms-2">
                <img
                  src={myprofile.user?.profile_image}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>

            <div class="dropdown-menu " style={{zIndex: 10000}}>
              <NavLink
                to={"/adminsettings"}
                className="text-decoration-none text-black"
              >
                <p> My Settings</p>
              </NavLink>
              <p
                onClick={() => {
                  logoutFn();
                }}
              >
                Logout
              </p>
            </div>
          </div>
        </div>

        <Button
          variant="primary"
          className="d-lg-none d-block rounded-0"
          onClick={handleShow}
        >
          <Icon icon={ic_menu} size={24} />
        </Button>
      </div>

     

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Main Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-unstyled  sidebar-menu-list-box">
            {webmenu?.map((val, index) => {
              return (
                <>
                  {val.submenu ? (
                    <>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <Icon icon={val.icon} size={24} key={index} />
                            {props.show && (
                              <div className="menu-name ms-2">
                                <span className="my-auto">{val.name}</span>
                              </div>
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            {val.submenu?.map((sval, sindex) => {
                              return (
                                <>
                                  <ul className="list-unstyled">
                                    <NavLink
                                      to={sval.path}
                                      key={sindex}
                                      className={
                                        "text-decoration-none text-black navlink-item"
                                      }
                                    >
                                      <li
                                        className=""
                                        onClick={() => {
                                          handleClose();
                                        }}
                                      >
                                        <div className="box d-flex align-items-center">
                                          <div className="icon-box">
                                            <Icon
                                              icon={sval.icon}
                                              size={24}
                                              title="Tickets"
                                            />
                                          </div>
                                          {props.show && (
                                            <div className="menu-name ms-2">
                                              <span className="my-auto">
                                                {sval.name}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </li>
                                    </NavLink>
                                  </ul>
                                </>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  ) : (
                    <>
                      <NavLink
                        to={val.path}
                        className={
                          "text-decoration-none text-black navlink-item"
                        }
                      >
                        <li
                          className="navmenu-linklist"
                          onClick={() => {
                            handleClose();
                          }}
                        >
                          <div className="box d-flex align-items-center">
                            <div className="icon-box">
                              <Icon icon={val.icon} size={24} />
                            </div>
                            {props.show && (
                              <div className="menu-name ms-2">
                                <span className="my-auto">{val.name}</span>
                              </div>
                            )}
                          </div>
                        </li>
                      </NavLink>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

       {/* <Notification />  */}
    </>
  );
};

export default Header;



const Notification = () =>{
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return(<>
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0">
        <strong className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit, eaque?</strong>
        </Modal.Header>
        <Modal.Body>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi veritatis laudantium eos quo! Facere maiores, ipsa at officia, commodi neque delectus sit totam inventore eius nisi quasi est consequuntur earum corrupti dignissimos voluptas minus similique provident quod repudiandae veritatis? Cumque tenetur, earum ex iusto rem nam deserunt labore corrupti quia ducimus quas ad unde esse veritatis dolor molestiae! Dolores non laboriosam earum recusandae placeat velit numquam architecto, quis itaque debitis aut facilis temporibus inventore consequuntur quod nihil error. Possimus vel sed dicta nam aliquid debitis nobis inventore rem, sint est, voluptate quidem tenetur necessitatibus. Ducimus debitis mollitia aliquam tenetur, quam temporibus quod, veritatis laboriosam impedit eius quas consectetur, quia saepe dolores maiores officiis perferendis quaerat? Velit quaerat numquam perspiciatis modi, nulla a in repudiandae fugiat cum vitae perferendis! Numquam earum dignissimos ipsam rem reprehenderit. Eveniet quia obcaecati quae ipsam ab, blanditiis distinctio tempore dignissimos unde eum, natus quaerat voluptatum! Nostrum.</p>
        </Modal.Body>
      </Modal>
  </>)
}