import React from 'react'
import '../style/workspace.css'
import Workspace from './Workspace'

const MyWorkspace = () => {
  return (
    <>
        <Workspace/>
    </>
  )
}

export default MyWorkspace