import React, { useEffect, useState } from 'react'
import { autocompleteApi } from '../serviceapi/Service.home';
import Tagselector from './Tagselector';

const Test = () => {
    const [suggestions, setSuggestions] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        const getAutocomplete = async () => {
          try {
            const res = await autocompleteApi({
              datatype: 'indiaimport',
              searchfield: '11',
              user_id: '31'
            });
    
            if (res.status) {
              setSuggestions(res.data.map((item) => ({
                id: item.id,
                value: item.value,
                label: item.value
              })));
            }
          } catch (error) {
            console.error('Error fetching autocomplete suggestions:', error);
          }
        };
    
        getAutocomplete();
      }, []);


      console.log(tags , 'testing tags data herer')
  return (<>
  <p>Test digng</p>

  <Tagselector suggestions={suggestions} setSuggestions={setSuggestions} tags={tags} setTags={setTags}   />
  
  
  
  </>)
}

export default Test