import React, { PureComponent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GlobalData } from '../App';

const data = [
  { 
    id:1,
    name: 'Jan',
    pv: 2400,
    amt: 2400,
  },
  { 
    id:2,
    name: 'Feb',
    pv: 1398,
    amt: 2210,
  },
  { 
    id:3,
    name: 'Mar',
    pv: 9800,
    amt: 2290,
  },
  { 
    id:4,
    name: 'Apr',
    pv: 3908,
    amt: 2000,
  },
  { 
    id:5,
    name: 'May',
    pv: 4800,
    amt: 2181,
  },
  { 
    id:6,
    name: 'June',
    pv: 3800,
    amt: 2500,
  },
  { 
    id:7,
    name: 'July',
    pv: 4300,
    amt: 2100,
  },
  { 
    id:8,
    name: 'Aug',
    pv: 4800,
    amt: 2181,
  },
  { 
    id:9,
    name: 'Sep',
    pv: 3800,
    amt: 2500,
  },
  { 
    id:10,
    name: 'Oct',
    pv: 4300,
    amt: 2100,
  },
  { 
    id:11,
    name: 'Nov',
    pv: 3800,
    amt: 2500,
  },
  { 
    id:12,
    name: 'Dec',
    pv: 4300,
    amt: 2100,
  },
];
const Home = () => {
let {fdata} = useContext(GlobalData)
  let navigate = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem('approle') === 'user') {
      navigate('/home');
    }
    
  }, [fdata])
  return (<>
    

    <div className="container-fluid home-wrapper">
        <div className="row block1">
            <div className="col-md-3 col-sm-6 col-6 my-md-0 my-2">
                <div className="card-body">
                    <p>Number of Searches</p>
                    <h2>36.1K</h2>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 my-md-0 my-2">
                <div className="card-body">
                <p>Provider</p>
                    <h2>168,856</h2>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 my-md-0 my-2">
                <div className="card-body">
                <p>New Booking</p>
                    <h2>73</h2>
                </div>
            </div>
            <div className="col-md-3 col-sm-6 col-6 my-md-0 my-2">
                <div className="card-body">
                <p>New User</p>
                    <h2>4865</h2>
                </div>
            </div>

        </div>

        <div className="row block2">
        <div className="col-12">
        <ul className="list-unstyled">
            <li className='font-24-500'>Monthly Statics</li>
            <li className='my-2'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem </li>
            <li className='d-flex justify-content-between'><span>Load Everage</span> <span className='font-24-500'>20%</span> </li>
        </ul>


        </div>
            <div className="col-12 barCol table-xl-res">
            {/* <BarChart
          width={1100}
          height={320}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#022279" background={{ fill: '#eee' }} />
          <Bar dataKey="uv" fill="#FFB600" />
        </BarChart> */}

        <BarChart
          width={1000}
          height={320}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={60}
        >
          <XAxis dataKey="name" scale="point" padding={{ left: 50, right: 10 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="pv"  background={{ fill: '#eee' }}> 
          {data.map((val) => (
            
                <Cell cursor="pointer" fill={(val.id)%2 === 0 ? '#FFB600' : '#022279'} key={`cell-${val.id}`} />
              ))}
          </Bar>
        </BarChart>

            </div>
        </div>
    </div>
     
    
    
    </>)
}

export default Home