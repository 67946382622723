// import React, { useState } from 'react';
// import CheckboxTree from 'react-checkbox-tree';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// const Demo = () => {
//   const [checked, setChecked] = useState([]);
//   const [expanded, setExpanded] = useState([]);



//   const nodes = [
//     {
//       value: '40',
//       label: '40',
//       count: "",
//       totle: "",
//       children: [
//         {
//           value: '0405',
//           label: '0405',
//           children: [
//             { value: '04050401', label: '04050401' },
//             { value: '04050402', label: '04050402' },
//           ],
//         },
//         {
//           value: '0406',
//           label: '0406',
//           children: [
//             { value: '04050877', label: '04050877' },
//             { value: '04050878', label: '04050878' },
//           ],
//         },
//       ],
//     },
//     {
//       value: '76',
//       label: '76',
//       children: [
//         {
//           value: '7605',
//           label: '7605',
//           children: [
//             { value: '76057788', label: '76057788' },
//             { value: '76057787', label: '76057787' },
//           ],
//         },
//       ],
//     },
//   ];

//   return (<>
//     <CheckboxTree
//       nodes={nodes}
//       checked={checked}
//       expanded={expanded}
//       onCheck={(newChecked) => setChecked(newChecked)}
//       onExpand={(newExpanded) => setExpanded(newExpanded)}
//       showNodeIcon={false}
//     />


//     <button onClick={()=>{console.log(checked , 'checked value')}}>click</button>
//   </>);
// };

// export default Demo;




// import React, { useState, useEffect } from 'react';

// const Demo = () => {
//   const [period, setPeriod] = useState('7');
//   const [start, setStart] = useState(getFormattedDate(getDateXDaysAgo(6)));
//   const [end, setEnd] = useState(getFormattedDate(new Date()));

//   useEffect(() => {
//     updateDates();
//   }, [period]); // Update dates when the selected period changes

//   const updateDates = () => {
//     const today = getFormattedDate(new Date());

//     switch (period) {
//       case '7':
//         setStart(getFormattedDate(getDateXDaysAgo(6)));
//         break;
//       case '30':
//         setStart(getFormattedDate(getDateXDaysAgo(29)));
//         break;
//       case '90':
//         setStart(getFormattedDate(getDateXMonthsAgo(3)));
//         break;
//       case '180':
//         setStart(getFormattedDate(getDateXMonthsAgo(6)));
//         break;
//       case '365':
//         setStart(getFormattedDate(getDateXDaysAgo(364)));
//         break;
//       case 'custom':
//         setStart(today);
//         break;
//       default:
//         break;
//     }

//     setEnd(today);
//   };

//   const handlePeriodChange = (e) => {
//     setPeriod(e.target.value);
//   };

//   const handleStartDateChange = (e) => {
//     setStart(e.target.value);
//   };

//   const handleEndDateChange = (e) => {
//     setEnd(e.target.value);
//   };

//   return (
//     <div>
//       <label htmlFor="period">Select Period:</label>
//       <select id="period" value={period} onChange={handlePeriodChange}>
//         <option value="7">Last 7 Days</option>
//         <option value="30">Last 30 Days</option>
//         <option value="90">Last 3 Months</option>
//         <option value="180">Last 6 Months</option>
//         <option value="365">Last Year</option>
//         <option value="custom">Custom</option>
//       </select>

//       <br />

//       <label htmlFor="start">Start Date:</label>
//       <input
//         type="date"
//         id="start"
//         value={start}
//         onChange={handleStartDateChange}
//         disabled={period !== 'custom'}
//       />

//       <br />

//       <label htmlFor="end">End Date:</label>
//       <input
//         type="date"
//         id="end"
//         value={end}
//         onChange={handleEndDateChange}
//         disabled={period !== 'custom'}
//       />
//     </div>
//   );
// };

// const getFormattedDate = (date) => {
//   return date.toISOString().split('T')[0];
// };

// const getDateXDaysAgo = (x) => {
//   const today = new Date();
//   today.setDate(today.getDate() - x);
//   return today;
// };

// const getDateXMonthsAgo = (x) => {
//   const today = new Date();
//   today.setMonth(today.getMonth() - x);
//   return today;
// };

// export default Demo;



import React, { useState, useEffect } from 'react';

const Demo = ({ defaultValue }) => {
  const defaultPeriod = defaultValue ? defaultValue.period : '7';
  const defaultStart = defaultValue ? defaultValue.start : getFormattedDate(getDateXDaysAgo(6));
  const defaultEnd = defaultValue ? defaultValue.end : getFormattedDate(new Date());

  const [period, setPeriod] = useState(defaultPeriod);
  const [start, setStart] = useState(defaultStart);
  const [end, setEnd] = useState(defaultEnd);
  const [isValidRange, setIsValidRange] = useState(true);

  useEffect(() => {
    updateDates();
  }, [period, defaultValue]); // Update dates when the selected period or default value changes

  const updateDates = () => {
    const today = getFormattedDate(new Date());

    switch (period) {
      case '7':
        setStart(getFormattedDate(getDateXDaysAgo(6)));
        break;
      case '30':
        setStart(getFormattedDate(getDateXDaysAgo(29)));
        break;
      case '90':
        setStart(getFormattedDate(getDateXMonthsAgo(3)));
        break;
      case '180':
        setStart(getFormattedDate(getDateXMonthsAgo(6)));
        break;
      case '365':
        setStart(getFormattedDate(getDateXDaysAgo(364)));
        break;
      case 'custom':
        setStart(today);
        break;
      default:
        break;
    }

    setEnd(today);
    setIsValidRange(true);
  };

  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStart(newStartDate);
    setIsValidRange(validateDateRange(newStartDate, end));
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEnd(newEndDate);
    setIsValidRange(validateDateRange(start, newEndDate));
  };

  const validateDateRange = (startDate, endDate) => {
    return startDate <= endDate;
  };

  return (
    <div>
      <label htmlFor="period">Select Period:</label>
      <select id="period" value={period} onChange={handlePeriodChange}>
        <option value="7">Last 7 Days</option>
        <option value="30">Last 30 Days</option>
        <option value="90">Last 3 Months</option>
        <option value="180">Last 6 Months</option>
        <option value="365">Last Year</option>
        <option value="custom">Custom</option>
      </select>

      <br />

      <label htmlFor="start">Start Date:</label>
      <input
        type="date"
        id="start"
        value={start}
        onChange={handleStartDateChange}
        disabled={period !== 'custom'}
      />

      <br />

      <label htmlFor="end">End Date:</label>
      <input
        type="date"
        id="end"
        value={end}
        onChange={handleEndDateChange}
        disabled={period !== 'custom'}
      />

      {!isValidRange && <p style={{ color: 'red' }}>End date must be equal or later than start date.</p>}
    </div>
  );
};

const getFormattedDate = (date) => {
  return date.toISOString().split('T')[0];
};

const getDateXDaysAgo = (x) => {
  const today = new Date();
  today.setDate(today.getDate() - x);
  return today;
};

const getDateXMonthsAgo = (x) => {
  const today = new Date();
  today.setMonth(today.getMonth() - x);
  return today;
};

export default Demo;


