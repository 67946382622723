import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { GlobalData } from '../App';
import { profileUpdateApi, resetpasswordApi} from './serviceapi/Service.home';
import { SweetAlert } from './sweetalert/SweetAlert';

const AdminSettings = () => {
  let formRef = useRef(); 


  const myaccountdata = useContext(GlobalData);
  const [MyProfileData , setMyProfileData] = useState('')


  useEffect(()=>{
    if(myaccountdata.myprofile){
      setMyProfileData(myaccountdata.myprofile)
    }
  } , [myaccountdata]);


  const [updateProfileData, setupdateProfileData ] = useState({

    firstname : '',
    lastname : '',
    email : '',
    username : '',
    phone  : '',
    profile_image : '',
    designation : '',
    sales_executive : '',
    company : ''

  })

  const handelInp = (e) =>{
    let type = e.target.type;
    let name = e.target.name;
    let value;

    if(type==='file'){
    // value= e.target.files[0];  


    }
    else{
      value = e.target.value;
    }

    setupdateProfileData({...updateProfileData , [name]:value})
  }

  let [loadimg , setLoadimg] = useState();

  const imgFn = (e) =>{
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
   
    reader.onload = () => {
      setLoadimg(reader.result)
    };
  }


  const [oldPassword , setoldPassword] = useState('')
  const [newPassword , setnewPassword] = useState('')
  const [spin , setspin] = useState(false);
  const [spin1 , setspin1] = useState(false);



  const updateProfile = async (e) =>{
    setspin(true);
    e.preventDefault();

   updateProfileData.profile_image = loadimg;
    
    let res = await profileUpdateApi(updateProfileData);

    if(res.status==="TXN"){
      SweetAlert({title:'Profile Updation' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
      myaccountdata.callprofile();
      myaccountdata.profilFn();

  }
  else{
      SweetAlert({title:'Profile Updation' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
  }
  setspin(false);

  }

  const updatePasswordFn = async (e) =>{
    e.preventDefault();

    setspin1(true);
    let res = await resetpasswordApi(oldPassword,newPassword);
    if(res.status==="TXN"){
      formRef.current.reset();
      setoldPassword('');
      setnewPassword('');
      SweetAlert({title:'Reset Password Status' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true})
      }
  else{
      SweetAlert({title:'Reset Password Status' , msg : res.message , icon : 'warning', btn : 'close', dangerMode: true})
  }
  setspin1(false);
  }



  const imgUp = (e) =>{



  }

  return (<>
  
  <Container fluid className="admin-settings-wrapper">
    <Row className="block1">
        <Col lg={10} className="mx-auto">
       
              <h4>Admin Details</h4>

<Form action="" className='row' onSubmit={updateProfile}>

<Row>
<Col md={6}>
  <div className="img-box rounded-circle bg-light d-flex align-items-center justify-content-center "  style={{'height' : '100px' , 'width' : '100px'}} >
  <img src={MyProfileData.user?.profile_image} alt="img"  className='rounded-circle img-fluid'  style={{'height' : '100px' , 'width' : '100px'}}   />
  </div>
</Col>
</Row>

<Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">First Name</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='Enter Your Full Name' defaultValue={MyProfileData.user?.firstname} name='firstname' onChange={handelInp} />
    </Form.Group>
</Col>

<Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Last Name</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='Enter Your Full Name' defaultValue={MyProfileData.user?.lastname} name='lastname' onChange={handelInp} />
    </Form.Group>
</Col>

<Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">User Name</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='Enter Your Full Name' defaultValue={MyProfileData.user?.username} name='username' onChange={handelInp} />
    </Form.Group>
</Col>



<Col md={6}>
    <Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Email</Form.Label>
    <Form.Control type="email" className='form-control rounded-0 bd-all' defaultValue={MyProfileData.user?.email} placeholder='Enter Your Email' name='email' onChange={handelInp} />
    </Form.Group>
    </Col>
    <Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Number</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='Enter Your Full Name' defaultValue={MyProfileData.user?.phone} name='phone' onChange={handelInp} />
    </Form.Group>
</Col>
    <Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Change Profile Image</Form.Label>
    <Form.Control type="file" className='form-control rounded-0 bd-all'   placeholder='Enter Your Full Name'  name='profile_image' onChange={(e)=>{handelInp(e); imgFn(e)}} />
    </Form.Group>
</Col>

<Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Designation</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='' defaultValue={MyProfileData.user?.designation} name='designation' onChange={handelInp} />
    </Form.Group>
</Col>
    <Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Sales Executive</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='' defaultValue={MyProfileData.user?.sales_executive}  name='sales_executive' onChange={handelInp} />
    </Form.Group>
</Col>

<Col md={6}>
<Form.Group className="form-group my-2">
    <Form.Label htmlFor="">Company</Form.Label>
    <Form.Control type="text" className='form-control rounded-0 bd-all'   placeholder='' defaultValue={MyProfileData.user?.company}  name='company' onChange={handelInp} />
    </Form.Group>
</Col>


    <Form.Group className="form-group my-md-4 my-3">
    {/* <Form.Control type="submit" value="Update Account"  /> */}
    <button type='submit'   className='btn-yellow'> Update Account {spin ? <Spinner size='sm' animation="border" variant="dark" /> : null}</button>
    </Form.Group>

  
</Form>
            
        </Col>

        <Col lg={10} className="mt-2 mx-auto">
    
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0" className='rounded-0'>
        <Accordion.Header>Change Password</Accordion.Header>
        <Accordion.Body>
        <Row>
 <Form className='' ref={formRef} onSubmit={updatePasswordFn}>
        <Form.Group className="form-group my-2">
                <Form.Label htmlFor="">Old Password</Form.Label>
                <Form.Control type="text" className='form-control rounded-0 bd-all' placeholder='Enter Your Password' onChange={(e)=>{setoldPassword(e.target.value)}} required />
                </Form.Group>

                <Form.Group className="form-group my-2">
                <Form.Label htmlFor="">New Password</Form.Label>
                <Form.Control type="text" className='form-control rounded-0 bd-all' placeholder='Enter Your New Password' onChange={(e)=>{setnewPassword(e.target.value)}} required />
                </Form.Group>

                <Form.Group className="form-group my-md-4">
                <button type='submit'   className='btn-yellow'> Reset Password {spin1 ? <Spinner size='sm' animation="border" variant="light" /> : null}</button>
                </Form.Group>
</Form>
 </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
     
 </Col>  


      <div class="modal fade" id="upgradePlanProfile" tabIndex="-1" aria-labelledby="upgradePlanProfileLabel" aria-hidden="true">
  <div className="modal-dialog  modal-xl">
    <div className="modal-content bd-all">
      <div className="modal-header">
        <h1 class="modal-title fs-5" id="upgradePlanLabel">Subscription Plan</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
            <div className="card-body">
            <div className="title-box">
            <h4>One Subscription - Multiple Users - 78+ Countries And More Added Every Month!</h4>
<small>Honest usage based pricing - irrespective of numbers of countries or users.</small>
            </div>
            <div className="row my-4">
                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
  <li className="list-group-item list-group-item-action active">Subscription Plan 1</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Free Trial</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>

                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
  <li className="list-group-item list-group-item-action active">Subscription Plan 2</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Start Up</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>

                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
                  
  <li className="list-group-item list-group-item-action active">Subscription Plan 3</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Standared</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>
            </div>
            </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
      </div>

    </Row>
  </Container>
  
  
  </>)
}

export default AdminSettings