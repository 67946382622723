import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';

const Permission = () => {

            
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
    };
  

  return (<>
  
  <div className="container-fluid add-new-wrapper">
            <div className="row block1">
            <div className="col-12">
                    <Form action="#" className="row" autoCapitalize='off'  noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Permission Name<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Role<span className="tx-red">*</span></label>
                            <select name="" id="" className="form-select rounded-0 bd-all" required>
                                <option value="">Role 1</option>
                                <option value="">Role 2</option>
                                <option value="">Role 3</option>
                                <option value="">Role 4</option>
                            </select>
                            <small class="invalid-feedback text-danger">This is field is required</small>

                        </div>

                        <div className="form-group  col-12 my-md-3 my-2">
                        <button className="btn-all" type='submit'>Save Permission</button>
                        </div>
                    </Form>
                </div>

                <div className="col-12">
                <div className="table-res my-2">
            
            <table className='table'>
                <thead className='bd-all'>
                    <tr>
                        <td> <div> SN </div></td>
                        <td> <div> Name</div></td>
                        <td> <div> Role</div></td>
                        <td> <div> Action </div></td>
                    </tr>
                </thead>
                
                <tbody>
                    <tr>
                        <td><div>02</div></td>
                        <td><div>Page Managment</div></td>
                        <td><div className=''><button className="btn-tp tx-green">Super Admin</button></div></td>
                        <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"  data-bs-toggle="modal" data-bs-target="#exampleModal5"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>
                    </tr>
                    <tr>
                        <td><div>01</div></td>
                        <td><div>Page Managment</div></td>
                        <td><div><button className="btn-tp tx-green">Admin</button></div></td>
                        <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>
                    </tr>
                    <tr>
                        <td><div>03</div></td>
                        <td><div>Page Managment</div></td>
                        <td><div><button className="btn-tp tx-green">Engineer</button></div></td>
                        <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>
                    </tr>

                    <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModal5Label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bd-all">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModal5Label">Edit Premission</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <Form action="#" className="row" autoCapitalize='off'  noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Permission Name<span className="tx-red">*</span></label>
                            <input type="text" name="" id="" className="form-control rounded-0 bd-all" required/>
                            <small class="invalid-feedback text-danger">This is field is required</small>
                        </div>
                        <div className="form-group col-md-6 col-12 my-md-3 my-2">
                            <label htmlFor="">Role<span className="tx-red">*</span></label>
                            <select name="" id="" className="form-select rounded-0 bd-all" required>
                                <option value="">Role 1</option>
                                <option value="">Role 2</option>
                                <option value="">Role 3</option>
                                <option value="">Role 4</option>
                            </select>
                            <small class="invalid-feedback text-danger">This is field is required</small>

                        </div>

                        <div className="form-group  col-12 my-md-3 my-2">
                        <button className="btn-all" type='submit'>Save Permission</button>
                        </div>
                    </Form>
      </div>
    </div>
  </div>
</div>

                </tbody>

            </table>
        </div>
                </div>
            </div>
        </div>  
  
  
  </>)
}

export default Permission