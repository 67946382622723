import React, { useEffect, useState } from 'react'
import { getChapterApi } from '../serviceapi/Service.home'
import CustomeTable from '../utils/CustomeTable'
import ChapterForm from './ChapterForm'
import CreateChapter from './CreateChapter'

const AllChapter = () => {

  let [preData,setpreData] = useState([]);
  let [filterData,setfilterData] = useState([]);


  const getChapterFn = async () =>{
    let res = await getChapterApi();
    if(res.data){
      setpreData(res.data);
      setfilterData(res.data);
    }
  }

  useEffect(()=>{
    getChapterFn();
  }, []);


       /* -- tables -- */

       const [tableSearch, settableSearch] = useState("")

       useEffect(()=>{
                          const result = preData.filter((eq) => {
                            return eq.chaptercode.match(tableSearch) || eq.item_desc.toLowerCase().match(tableSearch.toLowerCase());
                          });
                          setfilterData(result);
       }, [tableSearch])
      
      
       const columns = [
        
                      {
                        name : "S.N",
                        cell: (row, index) => index + 1,
                        sortable: true,
                        width: "80px"
                      },
                      {
                          name : "Chapter code",
                          selector : row => row.chaptercode || 'N/A',
                          sortable: true,
                         width: "150px"

                        },
                        {
                          name : "Chapter description",
                          selector : row => row.item_desc || 'N/A',
                          sortable: true,
                        },
       ]


  return (<>
  
  
  <div className="container-fluid add-new-wrapper">
            <div className="row block1">
                <div className="col-12 my-4">

                <div className="table-title d-flex justify-content-between align-items-center flex-wrap">
                <h5>All Chapter</h5>
                </div>

                <CustomeTable columns={columns} filterData={filterData} settableSearch={settableSearch} tableSearch={tableSearch} />

                </div>
            </div>
  </div>
  
  </>)
}

export default AllChapter