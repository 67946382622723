import React, { useContext, useEffect, useState } from 'react'
import Icon from 'react-icons-kit'
import {ic_home} from 'react-icons-kit/md/ic_home';
import {ic_move_to_inbox} from 'react-icons-kit/md/ic_move_to_inbox';
import {ic_person} from 'react-icons-kit/md/ic_person'
import {ic_next_week} from 'react-icons-kit/md/ic_next_week'
import {ic_rss_feed_twotone} from 'react-icons-kit/md/ic_rss_feed_twotone';
import {ic_credit_card} from 'react-icons-kit/md/ic_credit_card';
import {ic_headset_mic} from 'react-icons-kit/md/ic_headset_mic'
import { NavLink, useNavigate } from 'react-router-dom';
import { Accordion, Col } from 'react-bootstrap';
import { ic_forward_to_inbox } from 'react-icons-kit/md/ic_forward_to_inbox';
import { ic_live_help_outline } from 'react-icons-kit/md/ic_live_help_outline';
import {ic_inventory} from 'react-icons-kit/md/ic_inventory'
import {ic_inbox_outline} from 'react-icons-kit/md/ic_inbox_outline'
import {ic_list_alt} from 'react-icons-kit/md/ic_list_alt'
import {ic_bookmark} from 'react-icons-kit/md/ic_bookmark'
import {ic_create_new_folder} from 'react-icons-kit/md/ic_create_new_folder'
import {ic_settings} from 'react-icons-kit/md/ic_settings'
import {ic_web_outline} from 'react-icons-kit/md/ic_web_outline'
import {ic_published_with_changes} from 'react-icons-kit/md/ic_published_with_changes';
import {ic_groups} from 'react-icons-kit/md/ic_groups'
import {ic_group_add} from 'react-icons-kit/md/ic_group_add'
import {ic_group} from 'react-icons-kit/md/ic_group'
import {ic_admin_panel_settings} from 'react-icons-kit/md/ic_admin_panel_settings';
import {ic_add_task} from 'react-icons-kit/md/ic_add_task';
import {download} from 'react-icons-kit/fa/download';
import {ic_login} from 'react-icons-kit/md/ic_login'
import { GlobalData } from '../../App';
import {ic_notifications} from 'react-icons-kit/md/ic_notifications'
import {question} from 'react-icons-kit/icomoon/question'
const Sidebar = (props) => {



let [webmenu , setwebmenu] = useState([]);


useEffect(()=>{

  
    if(localStorage.getItem('approle') === 'admin'){
        setwebmenu(admin_menu)
    }
    if(localStorage.getItem('approle') === 'user'){
        setwebmenu(user_menu)
    }
}, [])



  return (
    <div className={`dashboard-sidebar-col d-lg-block d-none p-0 h-100 ${props.show ? 'open-menu' : 'close-menu'}` }  >
        <ul className="list-unstyled  sidebar-menu-list-box">

        {
            webmenu?.map((val,index)=>{
                return(<>
                    {
                        val.submenu ? 
                        
                        <>  

                        <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> <Icon icon={val.icon} size={24} key={index} />  
                    {props.show && <div className="menu-name ms-2">
                        <span className='my-auto'>{val.name}</span>
                    </div>}
                    
                    </Accordion.Header>
                    <Accordion.Body>
                    {
                        val.submenu?.map((sval,sindex)=>{
                            return(<> 

                                <ul className="list-unstyled">
                                    <NavLink to={sval.path} key={sindex} className={'text-decoration-none text-black navlink-item'} >
<li className=''>
<div className="box d-flex align-items-center">

<div className="icon-box">
<Icon icon={sval.icon} size={24}  title='Tickets'  />
</div>
{props.show &&  <div className="menu-name ms-2">
  <span className='my-auto'>{sval.name}</span>
</div>}

</div>
</li>
                                    </NavLink>
                                </ul>
                            </>)
                        })
                       }
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
                        
                       
                
                </> 
                        
                        :  
                        
                        <> 
                        <NavLink to={val.path} className={'text-decoration-none text-black navlink-item'} >
            <li className='navmenu-linklist'>
                <div className="box d-flex align-items-center">
                    
                    <div className="icon-box">
                        <Icon icon={val.icon} size={24} />
                    </div>
                    {props.show && <div className="menu-name ms-2">
                        <span className='my-auto'>{val.name}</span>
                    </div>}
                 
                </div>
                </li>
                </NavLink>
                        </> 
                    }

                </>)
            })
        }
           

        </ul>
    </div>
  )
}

export default Sidebar;



export const admin_menu = [
    {
        id : 0,
        name : 'Home',
        icon : ic_home,
        path : '/'
    },
    {
        id : 1,
        name : 'My Account',
        icon : ic_person,
        path : '/AdminSettings'
    },
    {
        id : 2,
        name : 'Announcement',
        icon : ic_rss_feed_twotone,
        path : '/Announcement'
    },
    {
        id : 3,
        name : 'Users',
        icon : ic_groups,
        path : '',
        submenu : [
            {
                id : 0,
                name : 'Add New Users',
                icon : ic_group_add,
                path : '/AddNewCustomers',
            },
            {
                id : 0,
                name : 'All Users',
                icon : ic_group,
                path : '/AllUsers',
            },
            // {
            //     id : 0,
            //     name : 'Role',
            //     icon : ic_admin_panel_settings,
            //     path : '/UserRole',
            // }
        ]
    },
    {
        id : 4,
        name : 'Package',
        icon : ic_inventory,
        path : '/Home',
        submenu : [
            {
                id : 0,
                name : 'Create Packages',
                icon : ic_inbox_outline,
                path : '/CreatePackages',
            },
            {
                id : 1,
                name : 'All Packages',
                icon : ic_list_alt,
                path : '/AllPackages',
            }
        ]
    },
    {
        id : 3,
        name : 'Subscription',
        icon : ic_credit_card,
        path : '/Subscription',
      
    },
    {
        id : 5,
        name : 'Chapters',
        icon : ic_bookmark,
        path : '/AllChapter'
    },
    {
        id : 6,
        name : 'Supports',
        icon : ic_headset_mic,
        path : '',
        submenu : [
            {
                id : 0,
                name : 'Tickets',
                icon : ic_forward_to_inbox,
                path : '/Ticket',
            },
            {
                id : 1,
                name : 'Open Ticket',
                icon : ic_live_help_outline,
                path : '/OpenTicket',
            }
        ]
    },
    {
        id : 9,
        name : 'Notification',
        icon : ic_notifications,
        path : '/notification'
    },
    {
        id : 9,
        name : `FAQ's`,
        icon : question,
        path : '/faq'
    },
    {
        id : 7,
        name : 'Upload Data',
        icon : ic_create_new_folder,
        path : '/UploadData'
    },
    {
        id : 8,
        name : 'Settings',
        icon : ic_settings,
        path : '/Home',
        submenu : [
            {
                id : 0,
                name : 'Site Settings',
                icon : ic_web_outline,
                path : '/Settings',
            },
            {
                id : 1,
                name : 'Terms & Conditions',
                icon : ic_published_with_changes,
                path : '/Terms',
            },
            {
                id : 2,
                name : 'Privacy Policy',
                icon : ic_published_with_changes,
                path : '/Privacy',
            }
        ]
    },

]


export const user_menu = [
    {
        id : 0,
        name : 'Dashboard',
        icon : ic_home,
        path : '/'
    },
    {
        id : 0,
        name : 'Search',
        icon : ic_home,
        path : '/home'
    },
    {
        id : 1,
        name : 'Workspace',
        icon : ic_move_to_inbox,
        path : '/MyWorkspace?folder=all'
    },
    {
        id : 7,
        name : 'My Downloads',
        icon : download,
        path : '/my-downloads',
      
    },
    {
        id : 3,
        name : 'Subscription',
        icon : ic_credit_card,
        path : '/Subscription',
      
    },
    {
        id : 8,
        name : 'Access Log',
        icon : ic_login,
        path : '/access-log',
      
    },
    {
        id : 8,
        name : `FAQ's`,
        icon : ic_login,
        path : '/faq',
      
    },

    {
        id : 2,
        name : 'My Account',
        icon : ic_person,
        path : '/AdminSettings'
    },
    
  
    {
        id : 6,
        name : 'Supports',
        icon : ic_headset_mic,
        path : '/Ticket',
        submenu : [
            {
                id : 0,
                name : 'Tickets',
                icon : ic_forward_to_inbox,
                path : '/Ticket',
            },
            {
                id : 1,
                name : 'Open Ticket',
                icon : ic_live_help_outline,
                path : '/OpenTicket',
            }
        ]
    },

    

  
   

]