import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { aftersearchFilterApi, datafilterApi, downnloaddataApi, savedataApi, searchApi, searchHistoryDataApi, searchHsnApi, suggestionApi, userFolderApi } from '../serviceapi/Service.home';
import Tagselector from '../utils/Tagselector';
import CustomeTable from '../utils/CustomeTable';
import { SweetAlert } from '../sweetalert/SweetAlert';
import { useContext } from 'react';
import { GlobalData } from '../../App';
import Slider from '@mui/material/Slider';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import DateRelation from '../utils/DateRelation';

const AdvanceSearch = () => {
  let [searchtoggle,setsearchtoggle] = useState(true);
  let [showSearch,setShowSearch] = useState(false);
  let {profilFn,siteloading , setsiteloading} = useContext(GlobalData);

  // Get the query string from the current URL
  const queryParams = new URLSearchParams(window.location.search);
  const workspace = queryParams.get('workspace');

    /* ========= search parameters api call start ========= */
    const [FilterPeriod, showFilterPeriod] = useState([]);
    const [FilterField, showFilterField] = useState([]);
    const [FilterOperator, showFilterOperator] = useState([]);
    const [SearchOperator, showSearchOperator] = useState([]);
  
    const filterApi = async () => {
      let res1 = await datafilterApi(1);
      if (res1.status==="TXN") {
        showFilterPeriod(res1.data);
      }
  
      let res2 = await datafilterApi(2);
      if (res2.status==="TXN") {
        showFilterField(res2.data);
      }
  
      let res3 = await datafilterApi(3);
      if (res3.status==="TXN") {
        showFilterOperator(res3.data);
      }
  
      let res4 = await datafilterApi(4);
      if (res4.status==="TXN") {
        showSearchOperator(res4.data);
      }
    };
  
    useEffect(() => {
      filterApi();
    }, []);

  /* ===== date function  ====== */
  // let startdateRef = useRef();
  // let enddateRef = useRef();

  // const [Predate, setPredate] = useState();
  // let [currdate, setcurrDate] = useState();

  // const [customdate, setcustomdate] = useState(false);
  // const peridoFn = (date) => {
  //   if (Predate !== enddateRef.current.value) {
  //     setcustomdate(true);
  //   }
  //   setPredate(moment().subtract(date, "day").format("YYYY-MM-DD"));
  //   setcustomdate(false);
  // };
  // const handlestartChange = (event) => {
  //   if (Predate !== enddateRef.current.value) {
  //     setcustomdate(true);
  //   }

  //   setPredate(event.target.value);
  // };

  // const dateFinder = (data) => {
  //   var curr = new Date();
  //   curr.setDate(curr.getDate());
  //   setcurrDate(curr.toISOString().substring(0, 10));
  // };

  // useEffect(() => {
  //   dateFinder();
  // }, []);

  /* ===== hide and show search function  ====== */

    // const handelDraft = () => {
    //   var sel = document.getElementById("select");
    //   var selected = sel.options[sel.selectedIndex];
    //   var extra = selected.getAttribute("data-foo");
    //   searchData.period = extra;
    // };

    /* search data */
    const [searchData, setsearchData] = useState({
      user_id: localStorage.getItem("userID"),
      datatype: "indiaimport",
      period: "",
      searchtext: "",
      fromdate: "",
      todate: "",
      searchfield: "",
      operator: "",
      hsn: "",
      apptoken: localStorage.getItem("apptoken"),
      searchtype: "",
    });

    const handelInp = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      setsearchData({ ...searchData, [name]: value });
    };

    const [searchResultData, setsearchResultData] = useState([]);
    const [afterSearchFilters, setafterSearchFilters] = useState([]);

    const [countData, setcountData] = useState([]);
    const [spin, setspin] = useState(false);
    const [rspin, setrspin] = useState(false);

    const workspacesearch = async () =>{
      setspin(true);
      setrspin(true);
      setsiteloading(true);

      let multiplesearchfield = queryParams.get('multiplesearchfield');
      let multiplesearchtext = queryParams.get('multiplesearchtext')?.split(',');
      let period = queryParams.get('period');
      let datatype = queryParams.get('datatype');
      let fromdate = queryParams.get('fromdate');
      let todate = queryParams.get('todate');
      let multiplesearchoperator = queryParams.get('multiplesearchoperator');
      let multipleoperator = queryParams.get('multipleoperator')?.split(',');
      let operator = multipleoperator[0];
      let searchfield  = queryParams.get('searchfield');
      let searchoperator  = queryParams.get('searchoperator');

      setsearchData((prev)=> (
        {...prev , 
      datatype: datatype,
      period: period,
      searchtext: multiplesearchtext,
      fromdate: fromdate,
      todate: todate,
      searchfield: multiplesearchfield,
      operator: operator,
      apptoken: localStorage.getItem("apptoken"),
      searchtype: "",
        }
      ));
      searchData.period = period

      let res = await searchHistoryDataApi({
        user_id: localStorage.getItem("userID"),
        datatype,
        searchtype: "redirect",
        period,
        fromdate,
        todate,
        searchtext: multiplesearchtext,
        searchfield,
        searchoperator,
        multipleoperator,
        operator,
        multiplesearchtext: multiplesearchtext,
        multiplesearchfield: multiplesearchfield?.split(','),
        multiplesearchoperator : multiplesearchoperator?.split(','),
        hsn : "",
        apptoken: localStorage.getItem("apptoken"),
      });

      if (res.status === "TXN") {
        console.log(res, 'searchHistoryDataApi')
        SweetAlert({
          title: "Search Data",
          msg: "success",
          icon: "success",
          btn: "close",
          dangerMode: true,
        });
    
        setShowSearch(true);
        setsearchResultData(res.data);
        setcountData(res.countingdata);
        setsearchtoggle(false);

      } else {
        SweetAlert({
          title: "Search Data",
          msg: res.message,
          icon: "warning",
          btn: "close",
          dangerMode: true,
        });
        setShowSearch(false);
      }
      
      let res2 = await aftersearchFilterApi({
        user_id: localStorage.getItem("userID"),
        datatype,
        searchtype: "redirect",
        period,
        fromdate,
        todate,
        searchtext: multiplesearchtext,
        searchfield,
        searchoperator,
        multipleoperator,
        operator,
        multiplesearchtext: multiplesearchtext,
        multiplesearchfield: multiplesearchfield?.split(','),
        multiplesearchoperator : multiplesearchoperator?.split(','),
        hsn : "",
        apptoken: localStorage.getItem("apptoken"),
      });
      if(res2.status==="TXN"){
        setafterSearchFilters(res2.countingdata)
      }
      setspin(false);
      setrspin(false);
      setsiteloading(false);

    // Iterate over the items array and set default values
    const defaultItems = items.map((item, index) => {
      return {
        ...item,
        searchfield: multiplesearchfield?.[index] || '',  // Set default value from query parameters
        operator: multipleoperator?.[index] || '',      // Set default value from query parameters
        searchtext: [{ label: multiplesearchtext?.[index] || '' }],  // Set default value from query parameters
        multiplesearchtext: [{ label: multiplesearchtext?.[index] , value: multiplesearchtext?.[index] || '' }], 
      };
    });

    // Set the default items in the state
    setItems(defaultItems);
    }

  useEffect( ()=>{
    if(workspace){
    workspacesearch();
  }
}, [workspace])
  
    const searchFn = async () => {
      setspin(true);
      // Copy the searchData to prevent unintended mutations
      const updatedSearchData = { ...searchData };
      // if (!updatedSearchData.todate) {
      //   updatedSearchData.todate = startdateRef.current.value;
      // }
      // if (!updatedSearchData.fromdate) {
      //   updatedSearchData.fromdate = enddateRef.current.value || Predate;
      // }
      updatedSearchData.operator = items[0]?.operator;
      updatedSearchData.searchfield = items[0]?.searchfield;
      updatedSearchData.searchtext = items[0]?.searchtext;

      if (items.length > 0) {
        updatedSearchData.multiplesearchoperator = items?.map((item) => item.searchoperator);
        updatedSearchData.multiplesearchfield = items?.map((item) => item.searchfield);
        updatedSearchData.multipleoperator = items?.map((item) => item.operator);
        updatedSearchData.multiplesearchtext = items?.flatMap((item) =>
          item.searchtext?.map((text) => text.label)
        );
      }

      // Update the state with the modified searchData
      setsearchData(updatedSearchData);
    
      // Now, searchData has been updated correctly before calling the API
      let res = await searchApi(updatedSearchData);
      let res2 = await aftersearchFilterApi(updatedSearchData);
      if(res2.status==="TXN"){
        setafterSearchFilters(res2.countingdata);
      }

      if (res.status === "TXN") {
        SweetAlert({
          title: "Search Data",
          msg: "success",
          icon: "success",
          btn: "close",
          dangerMode: true,
        });
        setShowSearch(true);
        setsearchResultData(res.data);
        setcountData(res.countingdata);
        setsearchtoggle(false);
        profilFn();
      } else {
        SweetAlert({
          title: "Search Data",
          msg: res.message,
          icon: "warning",
          btn: "close",
          dangerMode: true,
        });
        setShowSearch(false);
      }
      setspin(false);
    };

    /* repeated form data */
    const defaultItem = {
      id: 1,
      searchfield: "",
      searchoperator: "",
      operator: "",
      searchtext: [],
    };

    const [items, setItems] = useState([defaultItem]);

  const [tableSelectedRow, settableSelectedRow] = useState("");
  const tableSelection = (data) =>{
    settableSelectedRow(data);
}

    /* ===== autocomplete  ====== */
    let [suggestionData,setsuggestionData] = useState([]);

    const getComplete = async () =>{

    let res = await suggestionApi({
        datatype : searchData.datatype,
        user_id : localStorage.getItem('userID')
    });

    if(res.status==="true"){
      setsuggestionData(res.data);
    }
    else{
      setsuggestionData([]);
    }

    }

    useEffect(()=>{
      getComplete();
    }, []);

    /* price range state and function */
    const [pricerange, setpricerange] = React.useState([0, 10000000000]);
    const handlepriceChange = (event, newpricerange) => {
      setpricerange(newpricerange);
    };

    /* react check box tree states */
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    /* filter changes states */
    const [changeData, setChangeData] = useState({
      hsFilter: "",
      FilterimporterCity: "",
      originCountry: "",
      originPort: "",
      Filterdate: "",
      destinationPort: "",
      shipmentMode: "",
      importerName: "",
      stdUnit : "",
      fromRange : "",
      toRange : ""
    })

    /* filter function */
    const handelFliterChanges = (data,name) =>{
      // setChangeData((prev)=> ({...prev , [name] : data}));

      changeData[name] = data;
      searchData.fromRange =pricerange[0];
      searchData.toRange =pricerange[1];

      searchData.stdUnit = changeData.stdUnit;
      searchData.originCountry = changeData.originCountry;
      searchData.destinationPort = changeData.destinationPort;
      searchData.originPort = changeData.originPort;
      searchData.shipmentMode = changeData.shipmentMode;
      searchData.importerName = changeData.importerName;
      searchData.Filterdate = changeData.Filterdate;
      searchData.FilterimporterCity = changeData.FilterimporterCity;
      searchData.hs08 = changeData.hsFilter;
      
      setsiteloading(true);
      searchFn();
      setsiteloading(false);

    }

    const resetFilters = async () =>{
      setsiteloading(true);

      searchData.fromRange =0;
      searchData.toRange =10000000000;
      searchData.stdUnit = [];
      searchData.originCountry = [];
      searchData.destinationPort = [];
      searchData.originPort =  [];
      searchData.shipmentMode = [];
      searchData.importerName = [];
      searchData.Filterdate = [];
      searchData.FilterimporterCity = [];
      searchData.hs08 = [];

      setChecked([]);
      setExpanded([]);

      setChangeData({
        hsFilter: "",
        FilterimporterCity: "",
        originCountry: "",
        originPort: "",
        Filterdate: "",
        destinationPort: "",
        shipmentMode: "",
        importerName: "",
        stdUnit : "",
        fromRange : "",
        toRange : ""
      });
      searchFn();
      setsiteloading(false);

    } 

    const [sideFilter, setsideFilter ] = useState(true);

  return (<>
        {/* ============ search box start ============ */}
        <Card className="w-100">
        <Card.Header className="d-flex justify-content-between align-items-center" >
          <span>Search Box</span>
          <span>
            <button
              style={{ fontSize: "14px"}}
              onClick={() => {
                setsearchtoggle(!searchtoggle);
              }}
              className={searchtoggle ? "btn-sm-yellow" : 'btn-sm-green'} >
              {searchtoggle ? "close search" : "open search"}
            </button>
          </span>
        </Card.Header>

       {searchtoggle && 

       <Card.Body>
          <Form className='row'>
              <Col lg={3} md={4} sm={6} xs={12}  className='my-1'>
                <Form.Group>
                  <Form.Label className='my-0'>Data Type</Form.Label>
                  <Form.Select name='datatype' className='bd-all' value={searchData.datatype} onChange={handelInp}>
                  <option value="indiaimport">India Import</option>
                  <option value="indiaexport">India Export</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <DateRelation setsearchData={setsearchData}  defaultValue={
               workspace ? {period : searchData.period , start : searchData.fromdate , end: searchData.todate}  : null } />

              {/* <Col lg={3} md={4} sm={6} xs={12}  className='my-1'>
                <Form.Group>
                  <Form.Label className='my-0'>Period</Form.Label>
                  <Form.Select
                  name="period"
                  id="select"
                  defaultValue={searchData.period}
                  className="form-select bd-all "
                  
                 
                  onChange={(e) => {
                    dateFinder(e.target.value);
                    peridoFn(e.target.value);
                    handelDraft();
                  }}
                >
                  <option value="" disabled>
                    select
                  </option>

      

                  {FilterPeriod?.map((val) => {
                    return (
                      <option
                        value={Number(val.counting)}
                        data-foo={val.id}
                        key={val.id}
                      >
                        {val.name}
                      </option>
                    );
                  })}
                  <option value="custom" selected={customdate} data-foo={10}>
                    custom
                  </option>

                </Form.Select>
                </Form.Group>
              </Col>

              <Col lg={3} md={4} sm={6} xs={12}  className='my-1'>
                <Form.Group>
                  <Form.Label htmlFor="" className="my-0 label-tag">
                    Start Date
                  </Form.Label>

                  <input
                    type="date"
                    name="fromdate"
                    id=""
                    className="form-control bd-all "
                    value={Predate}
                    
                    onChange={(e) => {
                      handlestartChange(e);
                    }}
                    ref={enddateRef}
                  />
                </Form.Group>
              </Col>

              <Col lg={3} md={4} sm={6} xs={12}  className='my-1'>
                <Form.Group>
                  <Form.Label htmlFor="" className="my-0 label-tag">
                    End Date
                  </Form.Label>
                  <input
                    type="date"
                    name="todate"
                    id=""
                    className="form-control bd-all "
                    value={currdate}
                    ref={startdateRef}
                  />
                </Form.Group>
              </Col> */}

                <Repeater 
                FilterPeriod = {FilterPeriod}
                FilterField = {FilterField}
                FilterOperator = {FilterOperator}
                SearchOperator = {SearchOperator}
                items={items} setItems={setItems}
                defaultItem={defaultItem}
                searchData={searchData}  setsearchData={setsearchData}
                suggestionData={suggestionData}
                />


<Col xs={12} className='d-flex align-items-center justify-content-center'>
  <button
  className='btn-yellow'
  type='button' onClick={()=>{searchFn()}} > SEARCH {spin ? ( <Spinner animation="border" className="mx-1" size="sm" /> ) : null} </button>
</Col>
          </Form>
       </Card.Body>
        }
      </Card>
      {/* ============ search box ends ============ */}



            {/* ============ search result box start ============ */}

            {showSearch ? 
      
      <div className="advance-search-result-wrapper row">
      <CountBox 
        startdate={searchData.fromdate} 
        enddate={searchData.todate} 
        shipments={countData.shipments} 
        exportername={countData.exportername} 
        importername={countData.importername} 
        hscode={countData.hscode} 
        origincountry={countData.origincountry} 
        destinationport={countData.destinationport} 
        tableSelectedRow={tableSelectedRow} 
        tablefulldata={searchResultData} 
        searchData={searchData} 
      />

      <Col lg={sideFilter ? 3 : 1} className="filter-col bg-light mb-3">
          <Card className=''>
          <Card.Header className='bg-gray d-flex justify-content-between align-items-center' style={{backgroundColor : 'black' , color : 'white', fontWeight : 500}}>
            <span>Filters</span>
            <span>
              <svg className='cursor-pointer' onClick={()=> {setsideFilter((prev)=> !prev)}} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d={sideFilter ? `M15 18l-6-6 6-6` : `M9 18l6-6-6-6` }/></svg>
              {sideFilter && <button onClick={resetFilters} className='btn btn-sm btn-danger'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"> <path d="M2.5 2v6h6M21.5 22v-6h-6"/><path d="M22 11.5A10 10 0 0 0 3.2 7.2M2 12.5a10 10 0 0 0 18.8 4.2"/></svg></button>}
            </span>
          </Card.Header>
          
           {rspin ? 
           <div className="box d-flex justify-content-center align-items-center py-5">
           <Spinner size='sm' />
           </div> : 

           <>
           {sideFilter &&   <Accordion flush>

{afterSearchFilters?.priceRange?.min && 
<Accordion.Item eventKey="0">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}>
      <span style={{fontWeight: 500}}>Price range</span>
    </Accordion.Header>
    <Accordion.Body>
    <Slider
      value={pricerange}
      onChange={handlepriceChange}
      min={0}
      max={10000000000}
    />


<button size='sm' className='ms-auto btn-green' onClick={()=>{handelFliterChanges()}}>Apply</button>
    </Accordion.Body>
  </Accordion.Item> }

 {afterSearchFilters?.hsFilter && 
 
 <Accordion.Item eventKey="1">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>HS Code </span></Accordion.Header>
    <Accordion.Body>
    <CheckBoxTreeBox data={afterSearchFilters?.hsFilter}
    name={"hsFilter"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item> 
  }

  {afterSearchFilters?.FilterimporterCity && 
  
  <Accordion.Item eventKey="2">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Importer City</span></Accordion.Header>
    <Accordion.Body className="p-0 pt-2">
    <CheckBoxTreeBox searchbox={true} data={afterSearchFilters?.FilterimporterCity}
    name={"FilterimporterCity"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
  
    </Accordion.Body>
  </Accordion.Item>
  
  }

  {
    afterSearchFilters?.originCountry &&
  
  <Accordion.Item eventKey="3">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Origin Country</span></Accordion.Header>
    <Accordion.Body className="p-0 pt-2">
    <CheckBoxTreeBox searchbox={true} data={afterSearchFilters?.originCountry}
    name={"originCountry"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />

    </Accordion.Body>
  </Accordion.Item>
  }

  {afterSearchFilters?.originPort &&
  
  <Accordion.Item eventKey="4">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Origin Port</span></Accordion.Header>
    <Accordion.Body className="p-0 pt-2">
    <CheckBoxTreeBox searchbox={true} data={afterSearchFilters?.originPort}
    name={"originPort"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item>}

  {afterSearchFilters?.Filterdate &&
  
  <Accordion.Item eventKey="5">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Date</span></Accordion.Header>
    <Accordion.Body>
    <CheckBoxTreeBox data={afterSearchFilters?.Filterdate}
    name={"Filterdate"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item>}

  {afterSearchFilters?.destinationPort &&
  
  <Accordion.Item eventKey="6">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Destination Port</span></Accordion.Header>
    <Accordion.Body  className="p-0">
    <CheckBoxTreeBox searchbox={true} data={afterSearchFilters?.destinationPort}
    name={"destinationPort"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item>}
  {afterSearchFilters?.shipmentMode &&
  
  <Accordion.Item eventKey="7">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Shipment Mode</span></Accordion.Header>
    <Accordion.Body  className="p-0">
    <CheckBoxTreeBox data={afterSearchFilters?.shipmentMode}
    name={"shipmentMode"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item>}

  {afterSearchFilters?.importerName &&
  
  <Accordion.Item eventKey="8">
    <Accordion.Header style={{boxShadow : 'none' ,  fontWeight: 600}}><span style={{fontWeight: 500}}>Importer Name</span></Accordion.Header>
    <Accordion.Body  className="p-0">
    <CheckBoxTreeBox searchbox={true} data={afterSearchFilters?.importerName}
    name={"importerName"}
    change={setChangeData} handelChange={handelFliterChanges}
    checked={checked}
    setChecked={setChecked}
    expanded={expanded}
    setExpanded={setExpanded}
     />
    </Accordion.Body>
  </Accordion.Item>}

</Accordion>}
           </> }
          </Card>

          </Col>
          <Col lg={sideFilter ? 9 : 11} className="table-col p-0">
          <CustomeTable
                columns={ searchData.datatype === 'indiaimport' ?  columnsImp : columnsExp}
                filterData={searchResultData}
                select={true}
                SFN={tableSelection}
                search_data={false}
              />
          </Col>
        </div> 
        
        : null}
      {/* ============ search result box ends ============ */}
  
  </>)
}

export default AdvanceSearch;


const CheckBoxTreeBox = ({
  data,
  change,
  handelChange,
  name,
  checked,
  setChecked,
  expanded,
  setExpanded,
  searchbox
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredData = data.filter((node) =>
    node.value.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    {searchbox && <div className="box p-2">
     <input type="text"

    style={{

      height   : 40,
      padding  : 4

    }}

     placeholder="Search..."
     onChange={(e) => setSearchQuery(e.target.value)}
     className='w-100'

    />

     </div>}

      <CheckboxTree
        nodes={filteredData}
        checked={checked}
        expanded={expanded}
        onCheck={(newChecked) => setChecked(newChecked)}
        onExpand={(newExpanded) => setExpanded(newExpanded)}
        showNodeIcon={false}
      />

      <div className="box d-flex justify-content-end pe-3">
        <button
          size="sm"
          className="ms-2 mb-2 mt-2 btn-sm-green"
          onClick={() => {
            handelChange(checked, name);
          }} >
          Apply
        </button>
        <button size="sm" className="ms-2 mb-2 mt-2 btn-sm-green bg-danger"
          onClick={() => { setChecked(); handelChange([], name); }} >
          Reset
        </button>
      </div>
    </>
  );
};


// const CheckBoxTreeBox = ({data,change,handelChange,name,
//   checked,
//   setChecked,
//   expanded,
//   setExpanded,
// }) => {

//   return (<>
//     <CheckboxTree
//       nodes={data}
//       checked={checked}
//       expanded={expanded}
//       onCheck={(newChecked) => setChecked(newChecked)}
//       onExpand={(newExpanded) => setExpanded(newExpanded)}
//       showNodeIcon={false}
//     />

    
//    <div className="box d-flex justify-content-end pe-3">
//    <button size='sm' className='ms-2 mb-2 mt-2  btn-sm-green' onClick={()=>{
//       handelChange(checked,name)
//       }}>Apply</button>
//       <button size='sm' className='ms-2 mb-2 mt-2  btn-sm-green bg-danger' onClick={()=>{
//       handelChange(checked,name)
//       }}>Reset</button> 
//    </div>
//   </>);
// };

const Repeater = ({
FilterField ,
FilterOperator ,
SearchOperator ,
items, setItems,
defaultItem,
searchData, 
setsearchData,
suggestionData
}) => {



  const handleAdd = () => {
    const lastId = items[items.length - 1].id;
    setItems([...items, {
      id: lastId + 1,
      searchfield:"",
      searchoperator: "",
      operator: "",
      searchtext:  [],
    }]);
  };

  const handleDelete = (id) => {
    if (items.length === 1) {
      return; // Prevent deletion if there's only one item
    }

    const updatedItems = items.filter(item => item.id !== id);
    setItems(updatedItems);
  };

  const handleReset = () => {
    setItems([defaultItem]);
  };

  const handleChange = (id, name, value) => {
    const updatedItems = items?.map(item => item.id === id ? { ...item, [name]: value } : item );
    setItems(updatedItems); };


  /* autocomplete suggestion */
  const getFilteredSuggestions = (selectedSearchField) => {
    let filteredSuggestions = [];
  
    switch (selectedSearchField) {
      case '11':
        filteredSuggestions = suggestionData.productDescription;
        break;
      case '12':
        filteredSuggestions = suggestionData.hsCodeArr;
        break;
      case '13':
        filteredSuggestions = suggestionData.importerName;
        break;
      case '17':
        filteredSuggestions = suggestionData.exporterName;
        break;
        case '21':
        filteredSuggestions = suggestionData.importerCityArr;
        break;
        case '22':
        filteredSuggestions = suggestionData.importerStateArr;
        break;
        case '23':
        filteredSuggestions = suggestionData.stdUnitArr;
        break;
        case '24':
        filteredSuggestions = suggestionData.unitArr;
        break;
        case '25':
        filteredSuggestions = suggestionData.unitFcArr;
        break;
        case '26':
        filteredSuggestions = suggestionData.destinationPortArr;
        break;
        case '27':
        filteredSuggestions = suggestionData.shipmentModeArr;
        break;
        case '28':
        filteredSuggestions = suggestionData.originCountryArr;
        break;
        case '29':
        filteredSuggestions = suggestionData.originPortArr;
        break;
      default:
        break;
    }
  
    return filteredSuggestions?.map((item) => ({
      label: item.value, // Use 'value' as the label
      value: item.value, // Use 'value' as the value
    }));
  };
  


  /* model */

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [popupaction, setpopupaction] = useState('main');
  const [popupselectedidaction, setpopupselectedidaction] = useState(1);

  const handleShow = (data) => {
    console.log(data , 'handleShow')
    setpopupselectedidaction(()=> data )
    setShow(true);
  };


  /* ====== === search hsn === ====== */
  const [hsnData, setHsnData] = useState([]);
  const [hsnSelect, sethsnSelect] = React.useState('1');

  const [newData, setnewData] = useState({
    type: '',
    searchtext: '',
  });

  useEffect(()=>{
      if (/^\d/.test(newData.searchtext)) {
        console.log('Value starts with a number');
        sethsnSelect('2');
      } else {
        console.log('Value starts with a string');
        sethsnSelect('1');
      }
  } , [newData.searchtext])
  const handelHnschange = (e) =>{
    let value = e.target.value;
    let name = e.target.name;
    setnewData({...newData , [name]:value });
  }

  const [hsnspin, sethsnspin] = useState(false);
  let formmodelRef = useRef();
  let typeRef = useRef();
  let searchRef = useRef();

  const searchHsn = async () => {
    sethsnspin(true);
    newData.type = hsnSelect ; 
    let res = await searchHsnApi(newData);
    if (res.status === "TXN") {
      setHsnData(res.data);
    }
    sethsnspin(false);
  };

  /* table */

  // let repo;
  const handleCheckChange = (state) => {
    let repo = state.selectedRows?.map((val)=> ({label: hsnSelect=== '1' ?  val.desc : val.hsncode , value: hsnSelect=== '1' ? val.desc : val.hsncode}));

    console.log(repo, 'repo 01' , popupselectedidaction, 'popupselectedidaction 01' ,items , 'items 01')

    // Find the corresponding item by its id and update its searchtext
    const updatedItems = items?.map((item) =>
      item.id === popupselectedidaction ? { ...item, searchtext: repo } : item
    );

    // Update the state with the modified items
    setItems(updatedItems);

    console.log(repo, 'repo 02' , popupselectedidaction, 'popupselectedidaction 02' ,items , 'items 02')



  };

  const hsncolumns = [
    {
      name: "HS Code",
      selector: (row) => row.hsncode,
      sortable: true,
      width: "120px",
      headerStyle: (selector, id) => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      name: "Discription",
      selector: (row) => row.desc,
      sortable: true,
    },
  ];

  const addHsnFn = () => {
  };
  


    /* ========= custome tables ========= */


    



  return (<>

    <Col xs={12}>
      {items?.map((item,index) => (
        <Row key={item.id}>

        {index !== 0 && 
        ( <Col lg={3} md={4} sm={6} xs={12} className='my-1'>
     
              <Form.Group>
                <Form.Label htmlFor="" className="my-0 label-tag">
                  Search Operator
                </Form.Label>
                <Form.Select
                          name="searchoperator"
                          id=""
                          className="bd-all col-lg-3"
                          onChange={(e) => handleChange(item.id, e.target.name , e.target.value)}
                          defaultValue={item.searchfield} // Use defaultValue here

                        >
                          <option value="" disabled selected>
                            select
                          </option>

                          {SearchOperator?.map((val) => {
                            return (
                              <option value={val.id} key={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </Form.Select>
              </Form.Group>
           
          </Col>  )}

          <Col lg={3} md={4} sm={6} xs={12} className='my-1'>
      <Form.Group>
        <Form.Label htmlFor="" className="my-0 label-tag">
          Search Field
        </Form.Label>
        <Form.Select
                className="bd-all "
                name="searchfield"
                onChange={(e) => handleChange(item.id, e.target.name , e.target.value)}
                defaultValue={item.searchfield} // Use defaultValue here

              >
                <option value="" disabled selected>
                  select
                </option>

                {FilterField?.map((val) => {
                  return (
                    <>
                      <option value={val.id} key={val.id}>
                        {val.name}
                      </option>
                    </>
                  );
                })}
              </Form.Select>
      </Form.Group>
    </Col>

    {item.searchfield.includes("12") ? (
              <Col lg={2} md={2} sm={3} xs={12} className="my-1">
                <Form.Group>
                  {/* Replace the Operator select field with a button */}
                  <Form.Label htmlFor="" className="my-0 label-tag">
                  HSN Locate
                  </Form.Label>
                  <button className='w-100 btn-sm-yellow' type="button" onClick={() => handleShow(item.id)}>
                  HSN Locate
                  </button>
                </Form.Group>
              </Col>
            ) : (
              <Col lg={2} md={2} sm={3} xs={12}>
                <Form.Group>
                  <Form.Label htmlFor="" className="my-0 label-tag">
                    Operator
                  </Form.Label>
                  <Form.Select
                    name="operator"
                    id=""
                    className="bd-all"
                    onChange={(e) => handleChange(item.id, e.target.name , e.target.value)}
                    defaultValue={item.searchfield} // Use defaultValue here

                  >
                    <option value="" disabled selected>
                      select
                    </option>
                    {FilterOperator?.map((val) => {
                      return (
                        <option value={val.id} key={val.id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            )}



          <Col lg={index===0 ? 6 : 3} md={4} sm={6} xs={12}>
            <Form.Group>
              <Form.Label htmlFor="" className="my-0 label-tag">
                Search Text
              </Form.Label>

              <Tagselector
      suggestions={getFilteredSuggestions(item.searchfield)} // Use the formatted suggestions here
      tags={item.searchtext}
      setTags={(tags) => handleChange(item.id, 'searchtext', tags)}
    />

            </Form.Group>
          </Col>

          <Col lg={1} md={1} sm={1} xs={12} className='d-flex align-items-end'>
            {index !== 0 && (
              <Form.Group>

              <Button
                          variant="danger"
                          onClick={() => handleDelete(item.id)} >
                          <i class="far fa-trash-alt"></i>
                        </Button>


              </Form.Group> )}
          </Col>
        </Row>
      ))}
              <div className="my-1">
              <button type='button' onClick={handleAdd} className="me-2 border-0 btn-green">Add</button>
      <button type='button' onClick={handleReset} className="btn-yellow" >Reset</button>
              </div>
    </Col>


      {/* ============ hsn model start ============ */}

      <Modal show={show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>HS Code Locator {popupaction?.type === 'repeater'  ? 'from repeater' : 'main'} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            className="d-flex mb-2"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            ref={formmodelRef}
          >
            {/* <Form.Control type="text" className="rounded-0" placeholder="Enter value to search" ref={searchRef} /> */}
            <Form.Control type="text" className="rounded-0" placeholder="Enter value to search" ref={searchRef} name='searchtext' onChange={handelHnschange}  />

            <Form.Select
              className="rounded-0"
              ref={typeRef}
              style={{ width: "30%" }}
              name='type' 
              onChange={handelHnschange}
              value={hsnSelect} 
            >
              <option value="1">Product Keyword</option>
              <option value="2">HS Code</option>
            </Form.Select>
            <button
              className="btn-yellow"
              style={{minWidth : '120px'}}
              onClick={() => {
                searchHsn();
              }}
            >
              Search
              {hsnspin ? (
                <Spinner animation="border" className="mx-1" size="sm" />
              ) : null}
            </button>
          </Form>

    
          <CustomeTable
            SFN={handleCheckChange}
            columns={hsncolumns}
            select={true}
            filterData={hsnData}
            search_data={false}
          />
     

          <Col sm={12} className=" text-center">
            <button
              className="btn-green mx-auto"
              onClick={() => { addHsnFn(); handleClose(); }} >
              Add Item
            </button>
          </Col>
        </Modal.Body>
      </Modal>
      {/* ============ hsn model ends ============ */}

  </>);
};

export const CountBox = (props) => {
  return (
    <>
      <div className="col-12 item-1 bg-light">
        <div className="row">
          <div className="col-12 my-3 d-flex align-items-center flex-wrap">
            <ul className="list-unstyled row  li-card-list">
              <li className="super-li col-xl-1 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>Shipments</li>
                  <li>{props.shipments}</li>
                </ul>
              </li>

              <li className="super-li col-xl-2 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>Expoter Name</li>
                  <li>{props.exportername}</li>
                </ul>
              </li>

              <li className="super-li col-xl-2 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>Impoter Name</li>
                  <li>{props.importername}</li>
                </ul>
              </li>

              <li className="super-li col-xl-1 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>HS Code</li>
                  <li>{props.hscode}</li>
                </ul>
              </li>

              <li className="super-li col-xl-2 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>Country of Origin</li>
                  <li>{props.origincountry}</li>
                </ul>
              </li>

              <li className="super-li col-xl-2 col-lg-3 col-sm-4 col-6">
                <ul className="card-body list-unstyled">
                  <li>Port of Destination</li>
                  <li>{props.destinationport}</li>
                </ul>
              </li>
            </ul>

            <div className="form-group d-flex align-items-end justify-content-end ms-auto col-md-3 ">
              <div className="btnBox d-flex  flex-wrap my-2">
                <SaveData data={props.tableSelectedRow} tablefulldata={props.tablefulldata} searchdata={props.searchData} />
                


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};



const SaveData = (props) =>{

  // let {folderCollection} = useContext(GlobalData);


  /* model */
const [modelAction, setmodelAction] = useState('save');
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = (data) =>{
  setmodelAction(data)
  setShow(true);
  getFolder();
}


const [selectedType, setselectedType] = useState('row');
const [downloadType, setdownloadType] = useState('noworkspace');

const [switchTab, setswitchTab] = useState(false);
const [folders, setfolders] = useState(false);
const [selectedFolder, setselectedFolder] = useState(null);

let getFolder = async () =>{
  let res = await userFolderApi();
  if(res.status === "TXN"){
    setfolders(res.data)
  }
  // setfolders(folderCollection)
}

useEffect(()=>{
  getFolder();
}, [props]);


const submitSave = async () =>{
  let rowData;

  if(!selectedFolder && modelAction !=='favorite'){
    return SweetAlert({
      title: "Workshop folder missing",
      msg: "Workshop folder is required",
      icon: "warning",
      btn: "close",
      dangerMode: true,
    });
  }


  if(selectedType==="row"){
    rowData = props.data?.selectedRows?.map((val)=> val.id );
  }
  else{
      rowData = props.tablefulldata?.map((val)=> val.id);
  }
 
  let res = await savedataApi({

    user_id : props.searchdata.user_id,
    datatype : props.searchdata.datatype,
    period : props.searchdata.period,
    searchtext : props.searchdata.searchtext,
    searchfield : props.searchdata.searchfield,
    operator : props.searchdata.operator,
    folder_id : modelAction==='favorite' ? 'favorite' :  selectedFolder,
    row_id : rowData
    
});

if(res.status===true){
  handleClose();
    SweetAlert({
      title: "Data saved",
      msg: "Data is saved at Workshop folder",
      icon: "success",
      btn: "close",
      dangerMode: true,
    });
}
else{
    SweetAlert({
    title: "Data not saved",
    msg: res.message,
    icon: "warning",
    btn: "close",
    dangerMode: true,
  });
  
}
}

const submitDownload = async () =>{
 

  let rowData;
  if(!props.data?.selectedRows || props.data?.selectedRows.length === 0){
    return SweetAlert({
      title: "Table Data missing",
      msg: "Table Data cannot be empty",
      icon: "warning",
      btn: "close",
      dangerMode: true,
    });
  }


  rowData = props.data?.selectedRows?.map((val)=> val.id );

  let res = await downnloaddataApi({

    user_id : props.searchdata.user_id,
    datatype : props.searchdata.datatype,
    // period : props.searchdata.period,
    searchtext : props.searchdata.searchtext,
    searchfield : props.searchdata.searchfield,
    // operator : props.searchdata.operator,
    // folder_id : selectedFolder,
    row_id : rowData

    
});

if(res.status===true){
  handleClose();
    SweetAlert({
      title: "Data saved",
      msg: "Data is saved at Workshop folder",
      icon: "success",
      btn: "close",
      dangerMode: true,
    });


    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a');
    link.href = res.data;
    link.download = 'exported_data.xlsx';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log('File downloaded successfully.');


  
}
else{
    SweetAlert({
    title: "Data not saved",
    msg: res.message,
    icon: "warning",
    btn: "close",
    dangerMode: true,
  });
  
}




}


  return(<>

      <button className="my-1 bg-transparent border-0"   onClick={()=>{handleShow('save'); }}>
      <div className="d-flex flex-column align-items-center">
      <span  className="rounded-1 text-white border-0 p-1" style={{background : '#009646'}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg></span>
      </div>
      </button>
      <button className="my-1  bg-transparent border-0 mx-3"  onClick={()=>{handleShow('download'); }}> 
      
      <div className="d-flex flex-column align-items-center">
      <span  className="rounded-1 text-white border-0 p-1" style={{background : '#057aee'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"/></svg>
      </span>
      </div>

      
      
      </button>


      <button className="my-1  bg-transparent border-0 " onClick={()=>{handleShow('favorite'); }} > 
      <div className="d-flex flex-column align-items-center">
      <span  className="rounded-1 text-white border-0 p-1" style={{background : '#fe7513'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
      </span>
      </div>
      </button>



      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="border-0 bg-light text-uppercase" closeButton>  {modelAction==='save' ? 'Save Workspace' : modelAction==='favorite' ? 'favorite' : 'Download' } </Modal.Header>
        <Modal.Body>
          {modelAction==='save' ? 
          
          <Form onSubmit={(e)=>{e.preventDefault();}}>
            {
              !switchTab ? 
              
              <>
              <Form.Group>
              <span className="d-flex"><Form.Check value='row' type="radio" name="savedata" className="cursor-pointer me-2" defaultChecked={selectedType==='row' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Save Selected ({props.data?.selectedRows?.length || 0}) </span>
              <span className="d-flex"><Form.Check value='all' type="radio" name="savedata" className="cursor-pointer me-2" defaultChecked={selectedType==='all' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Save All ({props.tablefulldata?.length || 0}) </span>
            </Form.Group>
            <p className="my-3">Shipment which you have already saved will not be charged, to see savings if any, please click Check button.</p>
            <Form.Group>
              <button type='button'  className='btn-yellow' onClick={()=>{setswitchTab(true)}}>Check Now</button>
            </Form.Group>
              </>

            :
              <>
                  {
                    folders.length > 0 ?

                    <> 
                    <Form.Group className="my-3">
                <Form.Label>Select Workshop</Form.Label>
                <Form.Select onChange={(e)=>{setselectedFolder(e.target.value)}}>
                    <option>select</option>
                  { folders?.map((val,index)=>{
                    return(<option value={val.id} key={index}>{val.name}</option>)
                  }) }
                </Form.Select>
              </Form.Group>
            
            <Form.Group>
            <button type='button' onClick={()=>{setswitchTab(false)}}  className="me-2 btn-green">Back</button>
              <button type='button' onClick={()=>{submitSave()}} className='btn-yellow'>Save Now</button>
            </Form.Group>
            
            </>


            :

            <>  
            
            <Form.Group>
            <p  className="my-3" >You need to create a Workshop folder first to save the data.</p>
              <button type='button' onClick={()=>{handleClose()}} className='btn-green'>Close</button>
            </Form.Group>
            
             </>


                  }
              </>

            }
          </Form> 


          
          :

          modelAction==='favorite' ? 
          
          <Form onSubmit={(e)=>{e.preventDefault();}}>
              <Form.Group>
              <span className="d-flex"><Form.Check value='row' type="radio" name="savedata" className="cursor-pointer me-2" defaultChecked={selectedType==='row' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Save Selected ({props.data?.selectedRows?.length || 0}) </span>
              <span className="d-flex"><Form.Check value='all' type="radio" name="savedata" className="cursor-pointer me-2" defaultChecked={selectedType==='all' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Save All ({props.tablefulldata?.length || 0}) </span>
            </Form.Group>
            <p className="my-3">Shipment which you have already saved will not be charged, to see savings if any, please click Check button.</p>
            <Form.Group>
            <button type='button' onClick={()=>{submitSave()}} className='btn-yellow'>Save Now</button>
            </Form.Group>
          </Form> 
          
          :

          <>
      
              <Form.Group>
              {/* <span className="d-flex"><Form.Check value='noworkspace' type="radio" name="downloaddata" className="cursor-pointer me-2" checked={downloadType==='noworkspace' ? true : false} onChange={(e)=>{setdownloadType(e.target.value)}} /> Download without saving workspace (Only Shipments) </span>
              <span className="d-flex"><Form.Check value='workspace' type="radio" name="downloaddata" className="cursor-pointer me-2" checked={downloadType==='workspace' ? true : false} onChange={(e)=>{setdownloadType(e.target.value)}} /> Save current workspace and download </span> */}

              <span className="d-flex"><Form.Check value='row' type="radio" name="downloaddata" className="cursor-pointer me-2" defaultChecked={selectedType==='row' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Download Selected  ({props.data?.selectedRows?.length || 0}) </span>
              <span className="d-flex"><Form.Check value='all' type="radio" name="downloaddata" className="cursor-pointer me-2" defaultChecked={selectedType==='all' ? true : false} onChange={(e)=>{setselectedType(e.target.value)}} /> Download All ({props.tablefulldata?.length || 0}) </span>

            </Form.Group>

            {
              downloadType === 'workspace' ?

              <>
              {
                    folders.length > 0 ?

                    <> 
                    <Form.Group className="my-3">
                <Form.Label>Select Workshop</Form.Label>
                <Form.Select onChange={(e)=>{setselectedFolder(e.target.value)}}>
                    <option>select</option>
                  { folders?.map((val,index)=>{
                    return(<option value={val.id} key={index}>{val.name}</option>)
                  }) }
                </Form.Select>
              </Form.Group>
          
            
            </>


            :

            <>  
            
            <Form.Group>
            <p  className="my-3" >You need to create a Workshop folder first to save the data.</p>
              <button type='button' onClick={()=>{handleClose()}} className='btn-green'>Close</button>
            </Form.Group>
            
             </>


                  }

                  
              </>

              : null
            }

            


            <Form.Group className="my-3">
            <strong>Please click 'Ok' to Proceed</strong>
            </Form.Group>

            <Form.Group>
              <button type='button' className='btn-yellow' onClick={()=>{submitDownload()}}>OK</button>
            </Form.Group>
         

            
          </> 
          
          }
        </Modal.Body>
      </Modal>

  </>)
}






/* ========== table impoter column define start ========== */

const columnsImp = [
  // {
  //   name: "S.N",
  //   cell: (row, index) => index + 1,
  //   sortable: true,
  //   width: "80px",
  // },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    width: "110px",
  },
  {
    name: "HS Code",
    selector: (row) => row.hsCode,
    sortable: true,
    width: "130px",
  },
  {
    name: "Product Description",
    selector: (row) => (
      <textarea
        name=""
        className="p-1 rounded-0 border-0"
        id=""
        style={{ width: "400px", background : "transparent", fontSize: "12px" }}
      >
        {row.productDescription}
      </textarea>
    ),
    sortable: true,
    width: "430px",
  },
  {
    name: "Exporter Name",
    selector: (row) => 
    <textarea
    name=""
    className="p-1 rounded-0 border-0"
    id=""
    style={{ width: "300px", background : "transparent", fontSize: "12px" }}
  >
    {row.exporterName}
  </textarea>
    ,
    sortable: true,
    width: "330px",
  },
  {
    name: "Exporter Country",
    selector: (row) => row.exporterCountry,
    sortable: true,
    width: "190px",
  },
  {
    name: "Importer Name",
    selector: (row) =>
    <textarea
    name=""
    className="p-1 rounded-0 border-0"
    id=""
    style={{ width: "300px", background : "transparent", fontSize: "12px" }}
  >
    {row.importerName}
  </textarea>,
    sortable: true,
    width: "330px",
  },
  {
    name: "Importer City",
    selector: (row) => row.importerCity,
    sortable: true,
    width: "170px",
  },
  // {
  //   name: "Country",
  //   selector: (row) => row.originCountry,
  //   sortable: true,
  //   width: "170px",
  // },
  {
    name: "Qty.",
    selector: (row) => row.quantity,
    sortable: true,
  },
  {
    name: "Unit",
    selector: (row) => row.unit,
    sortable: true,
    width: "170px",
  },
  // {
  //   name: "Unit Rate (INR)",
  //   selector: (row) => row.unitRateInr,
  //   sortable: true,
  //   width: "170px",
  // },
  {
    name: "Unit Rate (USD)",
    selector: (row) => row.unitRateDoller,
    sortable: true,
    width: "180px",
  },
  {
    name: "Unit Rate FC",
    selector: (row) => row.UnitRateFc,
    sortable: true,
    width: "170px",
  },
  {
    name: "Unit FC",
    selector: (row) => row.unitFc,
    sortable: true,
    width: "170px",
  },
  // {
  //   name: "Total CIF INR",
  //   selector: (row) => row.totalCifInr,
  //   sortable: true,
  //   width: "170px",
  // },
  {
    name: "Total CIF $",
    selector: (row) => row.totalCifDoller,
    sortable: true,
    width: "170px",
  },
  {
    name: "Applicable Duty %",
    selector: (row) => row.applicableDutyPercentage,
    sortable: true,
    width: "230px",
  },
  // {
  //   name: "Applicable Duty INR",
  //   selector: (row) => row.applicableDutyInr,
  //   sortable: true,
  //   width: "230px",
  // },
  // {
  //   name: "CIF + Duty ( INR)",
  //   selector: (row) => row.cifPlsDutyInr,
  //   sortable: true,
  //   width: "170px",
  // },
  {
    name: "CIF + Duty ($)",
    selector: (row) => row.cifPlsDutyDoller,
    sortable: true,
    width: "170px",
  },
  {
    name: "Shipment Mode",
    selector: (row) => row.shipmentMode,
    sortable: true,
    width: "170px",
  },
  {
    name: "Origin Port",
    selector: (row) => row.originPort,
    sortable: true,
    width: "170px",
  },
  {
    name: "Origin Country",
    selector: (row) => row.originCountry,
    sortable: true,
    width: "170px",
  },
  {
    name: "Destination Port",
    selector: (row) => row.destinationPort,
    sortable: true,
    width: "190px",
  },
  {
    name: "Duplicate Record",
    selector: (row) => row.isDuplicate,
    sortable: true,
    width: "190px",
  },
];
/* ========== table impoter column define end ========== */

/* ========== table expoter column define start ========== */
const columnsExp = [
  // {
  //   name: "S.N",
  //   cell: (row, index) => index + 1,
  //   sortable: true,
  //   width: "80px",
  // },
  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    width: "110px",
  },
  {
    name: "HS Code",
    selector: (row) => row.hsCode,
    sortable: true,
    width: "110px",
  },
  {
    name: "Product Description",
    selector: (row) => (
      <textarea
        name=""
        className="p-1  rounded-0 border-0"
        id=""
        style={{ width: "400px", background : "transparent", fontSize: "12px" }}
      >
        {row.productDescription}
      </textarea>
    ),
    sortable: true,
    width: "500px",
  },
  {
    name: "Exporter Name",
    selector: (row) => 
    <textarea
    name=""
    className="p-1 rounded-0 border-0"
    id=""
    style={{ width: "300px", background : "transparent", fontSize: "12px" }}
  >
    {row.exporterName}
  </textarea>,
    sortable: true,
    width: "170px",
  },
  {
    name: "Exporter Country",
    selector: (row) => row.exporterCountry || "N/A",
    sortable: true,
    width: "170px",
  },
  {
    name: "Importer Name",
    selector: (row) => 
    <textarea
    name=""
    className="p-1 rounded-0 border-0"
    id=""
    style={{ width: "330px", background : "transparent", fontSize: "12px" }}
  >
    {row.importerName}
  </textarea>,
    sortable: true,
    
    width: "330px",
  },
  {
    name: "Importer City",
    selector: (row) => row.importerCity || "N/A",
    sortable: true,
    width: "150px",
  },
  // {
  //   name: "Country",
  //   selector: (row) => row.originCountry || "N/A",
  //   sortable: true,
  // },
  {
    name: "Qty.",
    selector: (row) => row.quantity,
    sortable: true,
  },
  {
    name: "Unit",
    selector: (row) => row.unit,
    sortable: true,
  },
  // {
  //   name: "Unit Rate (INR)",
  //   selector: (row) => row.unitRateInr,
  //   sortable: true,
  //   width: "170px",
  // },
  {
    name: "Unit Rate (USD)",
    selector: (row) => row.unitRateDoller,
    sortable: true,
    width: "170px",
  },
  {
    name: "Unit Rate FC",
    selector: (row) => row.UnitRateFc,
    sortable: true,
    width: "170px",
  },
  {
    name: "Unit FC",
    selector: (row) => row.unitFc,
    sortable: true,
  },
  {
    name: "F.O.B Value (INR)",
    selector: (row) => row.fobValueInr || "N/A",
    sortable: true,
    width: "170px",
  },
  {
    name: "F.O.B. Value ($)",
    selector: (row) => row.fobValueDoller || "N/A",
    sortable: true,
    width: "170px",
  },
  {
    name: "Origin Port",
    selector: (row) => row.originPort,
    sortable: true,

    width: "170px",
  },
  {
    name: "Origin Country",
    selector: (row) => row.originCountry,
    sortable: true,
    width: "170px",
  },
  {
    name: "Mode",
    selector: (row) => row.mode,
    sortable: true,
    width: "170px",
  },
  {
    name: "Destination Port",
    selector: (row) => row.destinationPort,
    sortable: true,
    width: "170px",
  },
  {
    name: "Destination Country",
    selector: (row) => row.destinationCountry || "N/A",
    sortable: true,
    width: "200px",
  },
  {
    name: "Duplicate Record",
    selector: (row) => row.isDuplicate,
    sortable: true,
    width: "170px",
  },
];
/* ========== table expoter column define end ========== */