import React from 'react'

import AdvanceSearch from './userhome/AdvanceSearch'
import UniversalSearch from './userhome/UniversalSearch'

const UserHome = (props) => {

  return (<>
  
            <div className="container-fluid userHome-wrapper">
                <div className="row block1" >
               

                    <div className="col-12 tabs-wrapper" >
                    <ul className="nav nav-tabs" id="myTab" role="tablist" >
  {/* <li className="nav-item" role="presentation">
    <button className="nav-link" id="universalSearch-tab" data-bs-toggle="tab" data-bs-target="#universalSearch-tab-pane" type="button" role="tab" aria-controls="universalSearch-tab-pane" aria-selected="true">Universal Search</button>
  </li> */}
  <li className="nav-item" role="presentation">
    <button className="nav-link tx-1 active" id="advanceSearch-tab" data-bs-toggle="tab" data-bs-target="#advanceSearch-tab-pane" type="button" role="tab" aria-controls="advanceSearch-tab-pane" aria-selected="false">Advance Search</button>
  </li>
</ul>
<div className="tab-content my-4" id="myTabContent" >
  {/* <div  className="tab-pane fade show active" id="universalSearch-tab-pane" role="tabpanel" aria-labelledby="universalSearch-tab" tabindex="0">
  <UniversalSearch/>
  </div> */}

  <div  className="tab-pane fade  show active" id="advanceSearch-tab-pane" role="tabpanel" aria-labelledby="advanceSearch-tab" tabindex="0">
 <AdvanceSearch/>
  
  </div>
</div>

                    </div>
                        
            
                </div>
            </div>
  
  </>)
}

export default UserHome