import React, { createContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import ForgetPassword from './component/ForgetPassword';
import HelmetTitle from './component/helmet/HelmetTitle';
import Login from './component/Login';

import Registration from './component/Registration';
import ResetPassword from './component/ResetPassword';
import {  allpackageApi, getSitesettingsApi, profileApi, userFolderApi } from './component/serviceapi/Service.home';
import './component/style/main.css'

import PrivateComponent from './PrivateComponent';
import Home from './component/Home';
import Ticket from './component/Ticket';
import OpenTicket from './component/OpenTicket';
import AllPackages from './component/packages/AllPackages';
import CreatePackages from './component/packages/CreatePackages';
import AddNewCustomers from './component/users/AddNewCustomers';
import Permission from './component/users/Permission';
import UserRole from './component/users/UserRole';
import SearchPage from './component/SearchPage';
import CreateChapter from './component/chapter/CreateChapter';
import AllChapter from './component/chapter/AllChapter';
import MyWorkspace from './component/workspace/MyWorkspace';
import UserHome from './component/UserHome';
import AllUsers from './component/users/AllUsers';
import Subscription from './component/subscription/Subscription';
import UploadData from './component/uploaddata/UploadData';
import Settings from './component/sitesettings/Settings';
import Terms from './component/sitesettings/Terms';
import Privacy from './component/sitesettings/Privacy';
import AdminSettings from './component/AdminSettings';
import Announcement from './component/Announcement';
import Dashboard from './component/Dashboard';
import Demo from './component/utils/Demo';
import Test from './component/utils/Test';
import AccessLog from './component/accesslog/AccessLog';
import MyDownloads from './component/mydownloads/MyDownloads';
import Notification from './component/notification/Notification';
import Faq from './component/faq/Faq';
import UserDashboard from './component/userhome/UserDashboard';
import Loading from './utils/Loading';

export const GlobalData = createContext();
const App = () => {
  let navigate = useNavigate()
  const location = useLocation();



  const [profiledata , setProfileData] = useState([])
  const [profileRole , setProfileRole] = useState()
  const [sitedata , setsitedata] = useState([])
  const [siteloading , setsiteloading] = useState(false);


  let uid;
  let token;
  const logoutFn = () =>{
  
    if(location.pathname === '/resetpassword' || location.pathname === '/registration' || location.pathname === '/forgetpassword') return;
    else{
      console.log('path found' , location.pathname);
      navigate('/login');
      localStorage.clear('access_token');
      localStorage.clear('apptoken');
      localStorage.clear('userID');
    }


  }


  const sitesettingsApi =  async () =>{
        let res = await getSitesettingsApi();
        if(!res.data) return;
        setsitedata(res.data)
  }
  const profilFn = async () =>{
    // console.log('profile called')
    const uid = localStorage.getItem('userID');
    const apptoken = localStorage.getItem('apptoken');
    let res = await profileApi(uid,apptoken);

    if(res.status==='TXN'){
      setProfileData(res.data);
      setProfileRole(res.data.user?.role)
      setProfileRole(res.data.user?.role.guard_name);
    }
    else{
      setProfileData([]);
      setProfileRole([])
      setProfileRole([]);
      logoutFn();
    }

  }

  const [folderCollection,setfolderCollection] = useState([]);

  const getFolder = async () =>{
  let res = await userFolderApi();
  if(res.status === "TXN"){
    setfolderCollection(res.data)
  }



  }
  useEffect(()=>{
    sitesettingsApi();

    if(localStorage.getItem('approle')==='user'){
      getFolder();
    }

  }, [])



  let [accountData, setaccountData] = useState({
    account_type: "admin" // admin or user
  })



  let [packageData, setpackageData] = useState([])
  
  const packageApi = async () =>{
  
    let res = await allpackageApi();
    if(res.status === 'TXN'){
      setpackageData(res.data.package_data);
    }
  }

  useEffect(()=>{
    packageApi();
  }, []);



  /* sidebar */
  
  let [sidebarshow , setsidebarShow] = useState(true);


  let [worksapceSearch , setworksapceSearch] = useState({
    status:false,
    data: ""
  });

  
  return (<>
  <HelmetTitle icon={sitedata.favicon} />
  <GlobalData.Provider value={{sidebarshow , setsidebarShow,packageData,packageApi, sitedata:sitedata,sitesettingsApi:sitesettingsApi,
   fdata:profileRole, profilFn:profilFn, myprofile:profiledata , callprofile:profileApi ,
   user_id:uid, usertoken:token , folderCollection,
  worksapceSearch , setworksapceSearch,siteloading , setsiteloading
   }}>


  {
  location.pathname.includes('/login') || location.pathname.includes('/forgetpassword') || location.pathname.includes('/resetpassword') || location.pathname.includes('/change-password')  || location.pathname.includes('/registration') ?  

  <Routes>
    <Route path='/login' element={<Login/>} />
    <Route path='/registration' element={<Registration/>} />
    <Route path='/forgetpassword' element={<ForgetPassword/>} />
    <Route path='/resetpassword/:id' element={<ResetPassword/>} />
  </Routes>

  :

  <Dashboard>
{siteloading &&  <Loading />}
          <Routes>

          <Route element={<PrivateComponent/>}>
          <Route path='/' element={ localStorage.getItem('approle')==="admin" ?  <Home/> : <UserDashboard />} />
          <Route path='/home' element={<UserHome/>} />
          <Route path='/Ticket' element={<Ticket/>} />
          <Route path='/OpenTicket' element={<OpenTicket/>} />
          <Route path='/AllPackages' element={<AllPackages/>} />
          <Route path='/CreatePackages' element={<CreatePackages/>} />
          <Route path='/AddNewCustomers' element={<AddNewCustomers/>} />
          <Route path='/Permission' element={<Permission/>} />
          <Route path='/UserRole' element={<UserRole/>} />
          <Route path='/SearchPage' element={<SearchPage/>} />
          <Route path='/CreateChapter' element={<CreateChapter/>} />
          <Route path='/AllChapter' element={<AllChapter/>} />
          <Route path='/MyWorkspace' element={<MyWorkspace/>} />
          <Route path='/UserHome' element={<UserHome/>} />
          <Route path='/AllUsers' element={<AllUsers/>} />
          <Route path='/Subscription' element={<Subscription/>} />
          <Route path='/UploadData' element={<UploadData/>} />
          <Route path='/Settings' element={<Settings/>} />
          <Route path='/Terms' element={<Terms/>} />
          <Route path='/Privacy' element={<Privacy/>} />
          <Route path='/AdminSettings' element={<AdminSettings/>} />
          <Route path='/Announcement' element={<Announcement/>} />
          <Route path='/demo' element={<Demo/>} />
          <Route path='/test' element={<Test/>} />
          <Route path='/access-log' element={<AccessLog/>} />
          <Route path='/my-downloads' element={<MyDownloads/>} />
          <Route path='/notification' element={<Notification/>} />
          <Route path='/faq' element={<Faq/>} />

          </Route>

          </Routes>
    </Dashboard>

  }


{/* <Suspense  fallback={"Loading /.. . ."}>
  <Routes>
    <Route path='/login' element={<Login/>} />

    <Route path='*' element={<Error/>} />
    <Route element={<PrivateComponent/>}>



 


    <Route path='/'  element={  <Header classPf={profilFn} myprofile={profiledata} />}  />
    </Route>
  </Routes>
</Suspense> */}
  </GlobalData.Provider>


  </>)
}

export default App