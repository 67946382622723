import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { addNotificationApi, addannouncementApi, allannouncementApi, deleteNotificationApi, editNotificationApi, editannouncementApi, getadminNotificationApi, statusannouncementApi, userAllApi } from '../serviceapi/Service.home';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { GlobalData } from '../../App';
import { SweetAlert } from '../sweetalert/SweetAlert';
import CustomeTable from '../utils/CustomeTable';
import { SweetAlertSingle } from '../../utils/SweetAlert';
import Select from "react-select";


const Notification = () => {
  const [openMode , setOpenmode] = useState('all');
  const [EditData , setEditData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  /* get api */
  const getApi = async () =>{
    let res = await getadminNotificationApi();
    if(res.status="TXN"){
      setfilterData(res.data);
    }
  }
  useEffect(()=>{
    getApi();
  }, [])

            
  const deleteApi = async (id) =>{
    let res = await deleteNotificationApi({id});
    if(res.status=true){
      getApi();
      SweetAlertSingle({title:'Notification deleted' , msg : res.message , icon : 'success', showCancelButton:false});
    }
    else{
      SweetAlertSingle({title:'Notification failed deleted' , msg : res.message , icon : 'success', showCancelButton:false});
    }
  }
  
  
    const columns = [
      {
        name : "S.N",
        cell: (row, index) => index + 1,
        sortable: true,
        width: "80px"
      },
                  {
                    name : "Subject",
                    selector : row => row.Subject,
                    sortable: true,
                  },
                  {
                    name : "Notification Type",
                    selector : row => row.notificationType,
                    sortable: true,
                  },
                  {
                    name : "Display Condition",
                    selector : row => row.displayCondition,
                    sortable: true,
                  },
                  {
                    name : "Schedule At",
                    selector : row => row.ScheduleDate,
                    sortable: true,
                  },
                  {
                    name : "Action",
                    selector : row => <div  className='icon-tag d-flex align-items-center'><i class="fa-solid fa-user-pen i-x cursor-pointer" onClick={()=>{openEditFn(row); setOpenmode('edit')}}></i>
                    <i className="fa-solid fa-trash" onClick={()=>{deleteApi(row.notificationID)}}></i>
                    </div> ,
                    sortable: true,
                  },
                  
    ]




    const openEditFn = (row) =>{
      setEditData(row);
    };
          

    
    let globaldata = useContext(GlobalData);

  return (<>
  
  <div className="container-fluid admin-settings-wrapper">
    <div className="row block1">

    {
          globaldata.fdata==='admin' ? 
          
          <>
          <div className="col-12">
        
        {openMode ==='all' ?  
        <>
       <div className='d-flex justify-content-between mb-2'>  <p>All Notification</p> <span><button onClick={()=>{setOpenmode('add')}} className='btn-green'>Add New</button></span></div>
        <CustomeTable columns={columns} filterData={filterData} search_data={false} />
        
        </>
        : openMode ==='add' ?
 
        <>
        <div className='d-flex justify-content-between mb-2'>  <p>Add Notification</p> <span><button onClick={()=>{setOpenmode('all'); }} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
        <NotificationForm  action="add" getApi={getApi} />
         </div>
        </>
         :
         openMode ==='edit' ?
         <>
        <div className='d-flex justify-content-between mb-2'>  <p>Edit Notification</p> <span><button onClick={()=>{setOpenmode('all')}} className='btn-green'>Back to list</button></span></div>
        <div className="col-12">
        <NotificationForm data={EditData} action="update" getApi={getApi} />

         </div>
        </>
 
        : null
 
        }
     </div>

          </> : 
          
          <>

          {/* <CustomeTable title='All Announcement' columns={columnsuser} filterData={filterData} search_data={false} /> */}

          </>
    }
     
    </div>

  </div>
  
  
  </>)
}

export default Notification;


const NotificationForm = ({data,action,getApi}) => {
  const [value, setValue] = useState("");
  let formRef = useRef();

  const [newData, setnewData] = useState({
    Subject: "",
    NotificationType: "",
    DisplayCondition: "",
    selectUser : "",
    message: "",
    user_id: "",
    users_id: "",
    ScheduleDate: ""
  });
  const handelChange = (e) => {
    let name = e.target.name;
    let type = e.target.type;
    let value;
    if (type === "file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    setnewData({ ...newData, [name]: value });
  };

  let submitAction = async (e) => {
    e.preventDefault();
    newData.message = value;
    newData.selectUser = selectCondition;
    if(selectCondition==='All Users'){
       newData.users_id = alluser?.map((val)=>  (val.id) );
    }

    if(action==="add"){
    let res = await addNotificationApi(newData);
    if(res.status===true){
      SweetAlertSingle({title:'Notification' , msg : res.message , icon : 'success', showCancelButton:false});
      formRef.current.reset();
      setValue(()=> "")
      setalluser([]);
      setSelectCondition("single");
    }
    else{
      SweetAlertSingle({title:'Notification' , msg : res.message , icon : 'warning', showCancelButton:false})
    }
  }

  else{
    newData.notificationID = data.notificationID;
    console.log(newData , 'newData')
    let res = await editNotificationApi(newData);
    if(res.status===true){
      SweetAlertSingle({title:'Notification' , msg : res.message , icon : 'success', showCancelButton:false});
      formRef.current.reset();
      setValue(()=> "")
      setalluser([]);
      setSelectCondition("single");
    }
    else{
      SweetAlertSingle({title:'Notification' , msg : res.message , icon : 'warning', showCancelButton:false})
    }
  }

    getApi();
  };
  useEffect(() => {
    if (action === "update") {
    setValue(data.Message);
    newData.Subject = data?.Subject 
    newData.NotificationType = data?.notificationType 
    newData.DisplayCondition = data?.displayCondition 
    newData.selectUser  = data?.selectUser  
    newData.message = data?.Message 
    newData.user_id = data?.user_id 
    newData.users_id = data?.users_id 
    newData.ScheduleDate = data?.ScheduleDate
    }

   


  }, [data]);

  const [alluser, setalluser] = useState([]);
  const allusersApi = async () => {
    if (localStorage.getItem("approle") === "admin") {
      let res = await userAllApi();
      if (res.status === 200) {
        setalluser(res.data);
      }
    }
  };
  useEffect(() => {
    allusersApi();
  }, []);

  /* */
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectCondition, setSelectCondition] = useState("single");

  const options = alluser?.map((user) => ({
    value: user.email,
    label: `${user.username} (${user.email})`,
    id: user.id,
  }));

  const handleuserChange = (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions");
    setSelectedUsers(selectedOptions);

    if(selectCondition==="single"){
        newData.user_id = selectedOptions.id;
    }
    if(selectCondition==="multiple"){
        let data = selectedOptions?.map((val)=>  (val.id) );
        newData.users_id = data
    }
  };

  const handleSelectConditionChange = (e) => {
    setSelectCondition(e.target.value);
    setSelectedUsers([]);
  };

  return (
    <>
      <Container fluid className="openTicket-wrapper">
        <Row className="block1">
          <Col sm={12} className="title-col">
            <Row>
              <Col lg={8} className="mx-auto">
              <>{action !== "update" && <h4>Notification</h4>}</>

                <Form className="row" onSubmit={submitAction} ref={formRef} >
{console.log(data,action,newData)}
                  <Form.Group className="form-group  col-12  my-2">
                    <Form.Label htmlFor="" className="label-tag">
                      Subject
                    </Form.Label>
                    <input
                      type="text"
                      id=""
                      className="form-control rounded-0 bd-all"
                      name="Subject"
                      value={newData.Subject}
                      onChange={handelChange}
                    />
                  </Form.Group>

                  <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                      <Form.Label htmlFor="" className="label-tag">
                        Notification type
                      </Form.Label>
                      <Form.Select
                        id=""
                        className=" rounded-0 bd-all"
                        name="NotificationType"
                        value={newData.NotificationType}
                        onChange={handelChange}
                      >
                        <option value="" disabled>
                          -- Select type --
                        </option>
                        <option value="General">General</option>
                        <option value="Billing information">Billing information</option>
                        <option value="Offers">Offers</option>
                        <option value="Important alert">Important alert</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                      <Form.Label htmlFor="" className="label-tag">
                        Display condition
                      </Form.Label>
                      <Form.Select
                        id=""
                        className="rounded-0 bd-all"
                        name="DisplayCondition"
                        value={newData.DisplayCondition}
                        onChange={handelChange}
                      >
                      <option value="" disabled>
                          -- Select type --
                        </option>
                        <option value="welcomemessage">Welcome Message</option>
                        <option value="lowaccountbalance">Low Account Balance</option>
                        <option value="7 Days">7 Days</option>
                        <option value="Schedule">Schedule Date</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group col-12  my-2">
                      <Form.Label htmlFor="" className="label-tag">
                        Select user
                      </Form.Label>
                      <Form.Select
                        id=""
                        className="rounded-0 bd-all"
                        onChange={handleSelectConditionChange}
                        value={selectCondition}
                      >
                        <option value="Individual User">Individual User</option>
                        <option value="Multiple User">Multiple User</option>
                        <option value="New Users">New Users</option>
                        <option value="All Users">All Users</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {selectCondition !== "All Users" ? (
                    <Col lg={6}>
                      <Form.Group className="form-group col-12  my-2">
                        <Form.Label htmlFor="" className="label-tag">
                          User
                        </Form.Label>
                        <Select
                          isMulti={selectCondition === "multiple"}
                          options={options}
                          onChange={handleuserChange}
                          value={selectedUsers}
                          isSearchable
                          placeholder={`Select ${selectCondition} user`}
                          className="rounded-0 bd-all"
                        />
                      </Form.Group>
                    </Col>
                  ) : null}


                  {newData.DisplayCondition === "Schedule" ? (
                    <Col lg={6}>
                      <Form.Group className="form-group col-12  my-2">
                        <Form.Label htmlFor="" className="label-tag">
                          Date
                        </Form.Label>
                        <input
                      type="date"
                      id=""
                      className="form-control rounded-0 bd-all"
                      name="ScheduleDate"
                      // defaultValue={
                      //   action === "update" ? data.ScheduleDate : ""
                      // }
                      value={newData.ScheduleDate}
                      onChange={handelChange}
                    />
                      </Form.Group>
                    </Col>
                  ) : null}


               
                  <Form.Group className="form-group  col-12  my-2">
                    <Form.Label htmlFor="" className="label-tag">
                      Message
                    </Form.Label>
                    <div style={{ height: "180px" }}>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        style={{ height: "180px" }}
                        onChange={setValue}
                        className="rounded-0"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="form-group col-12 d-flex justify-content-center  my-5">
                    <button className="btn-yellow" type="submit" >
                      Submit
                    </button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};


export const quill_modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"],
    [{ 'color': [] }, { 'background': [] }],  
    [{ 'script': 'sub'}, { 'script': 'super' }],  
    ['code']
  ],
  clipboard: {
    matchVisual: false
  }
  };