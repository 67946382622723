import React, { useContext, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import { GlobalData } from '../../App'
import { quill_modules } from '../Announcement'
import { postSitesettingsApi } from '../serviceapi/Service.home'
import { SweetAlert } from '../sweetalert/SweetAlert'
const Terms = () => {
  let globaldata = useContext(GlobalData);
  const [spin, setspin] = useState(false);
  const [value, setValue] = useState(globaldata.sitedata.termsconditions);


  const settingsPostApi = async (e) =>{
    e.preventDefault();
    setspin(true);
    let res = await postSitesettingsApi({
      apptoken: localStorage.getItem('apptoken'),
      user_id: localStorage.getItem('userID'),
      termsconditions: value
    });

    if(res.status="TXN"){
      SweetAlert({title:'Terms & Condition' , msg : "success" , icon : 'success', btn : 'close', dangerMode: true});
    }
    else{
      SweetAlert({title:'Terms & Condition' , msg : res.message , icon : 'success', btn : 'close', dangerMode: true});
    }
    setspin(false);

  }


  return (
    <Container fluid>
        <Row>
          <Col>
          <Form onSubmit={settingsPostApi}>
              <Form.Group>
                <Form.Label>Terms & Conditions</Form.Label>
                <div style={{'height' : '250px'}} >
                    <ReactQuill  modules={quill_modules} theme="snow" value={value} style={{'height' : '250px'}}  onChange={setValue} className='rounded-0' />
                    </div>
              </Form.Group>


              <Form.Group className='mt-5'>
                <button type='submit'  className='btn-yellow'>Save {spin ? <Spinner animation="border" className='mx-1' size="sm" /> : null}</button>
              </Form.Group>

            </Form>
          </Col>
        </Row>
    </Container>
  )
}

export default Terms