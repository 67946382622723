import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { addpackageApi, editpackageApi, getChapterApi } from '../serviceapi/Service.home'
import Select from 'react-select'
import { convertDateFormat } from '../utils/inputdateformat'
import { GlobalData } from '../../App'
import { SweetAlertSingle } from '../../utils/SweetAlert'

const CreatePackages = (props) => {
  let {packageApi} = useContext(GlobalData);

  let formRef = useRef();
  let [chapterData,setchapterData] = useState([]);

  let getApi = async () =>{
    let res = await getChapterApi();
    res.status==='TXN' && setchapterData(res.data?.map((val)=>({label:val.chaptercode, value:val.chaptercode , id: val.id })) )
  }
  useEffect(()=>{
    getApi();
  }, []);

  let [selectedChapter,setselectedChapter] = useState([]);
  const chapterChange = (data) =>{
    console.log(data , 'select')
    setselectedChapter(data)
  };


          /* -- post api -- */
          const [postData , setpostData] = useState({
            name : "",
            dataType : "import",
            packageType : "pointbased",
            no_of_download : "",
            no_of_search : "",
            package_startDate : "",
            package_endDate : "",
            database_access_startDate : "",
            database_access_endDate : "",
            chaptercode : "",
            no_of_points : ""
            });
        
            const handelInp = (e) =>{
            let type = e.target.type;
            let name = e.target.name;
            let value;
            if(type==='file'){
              value = e.target.files[0];
            }
            else{
              value = e.target.value
            }
            setpostData({... postData,[name]:value })
          }


          const actionSubmitApi = async (e) =>{
            e.preventDefault();

         
            postData.chaptercode = selectedChapter.map((val)=> (val.label));
            postData.apptoken = localStorage.getItem('apptoken');
            postData.user_id = localStorage.getItem('userID');

            if(props.actiontype==="update"){
              postData.packageid = props.data.id;



                let res = await editpackageApi(postData);
                if(res.status==="TXN"){
                  SweetAlertSingle({title:'Package Updated' , text : res.message , icon : 'success', showCancelButton:false});
                    packageApi();
                    props.setmode('list')
                    formRef.current.reset();
                    setpostData({
                    name : "",
                    dataType : "import",
                    packageType : "pointbased",
                    no_of_download : "",
                    no_of_search : "",
                    package_startDate : "",
                    package_endDate : "",
                    database_access_startDate : "",
                    database_access_endDate : "",
                    chaptercode : "",
                    no_of_points : ""
                  });
                }
                else{
                  SweetAlertSingle({title:'Package Updation failed' , text : res.message , icon : 'warning', showCancelButton:false});
                }
            }
            else{
                let res = await addpackageApi(postData);
                if(res.status==="TXN"){
                  SweetAlertSingle({title:'Package created' , text : res.message , icon : 'success', showCancelButton:false});
                  
                    formRef.current.reset();
                    setselectedChapter(()=> [])
                    setpostData({
                    name : "",
                    dataType : "import",
                    packageType : "pointbased",
                    no_of_download : "",
                    no_of_search : "",
                    package_startDate : "",
                    package_endDate : "",
                    database_access_startDate : "",
                    database_access_endDate : "",
                    chaptercode : "",
                    no_of_points : ""
                  });
                }
                else{
                  SweetAlertSingle({title:'Package addition failed' , msg : res.message , icon : 'warning', showCancelButton:false})
                }
            }
            
            
          
          }

          useEffect(()=>{
          if(props.actiontype === 'update'){
            setpostData(()=>  props.data)
          }
          let defaultChapter = props.data?.chaptercode?.map((val)=> ({value : val.chaptercode , label :val.chaptercode , id: val.id })) 
          setselectedChapter( ()=> defaultChapter) 
          }, [props]
          )
          


  return (
    <Container>
      <Row>
       {props.actiontype !=='update' && <Col xs={12}>Create Packages</Col>}

        <Col xs={12}>
          <Form className='row' ref={formRef} onSubmit={actionSubmitApi}>
              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Package Name</Form.Label>
                <Form.Control type='text' className='rounded-0 bd-all' placeholder='' name="name" defaultValue={ props.actiontype==='update' ? props.data.name : '' }  onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Database Access Starts</Form.Label>
                <Form.Control type='date' className='rounded-0 bd-all' placeholder='' name="database_access_startDate" defaultValue={ props.actiontype==='update' ? convertDateFormat(props.data.database_access_startDate) : '' } onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Database Access Ends</Form.Label>
                <Form.Control type='date' className='rounded-0 bd-all' placeholder='' name="database_access_endDate" defaultValue={ props.actiontype==='update' ? convertDateFormat(props.data.database_access_endDate) : '' } onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Package Starts</Form.Label>
                <Form.Control type='date' className='rounded-0 bd-all' placeholder='' name="package_startDate" defaultValue={ props.actiontype==='update' ? convertDateFormat(props.data.package_startDate) : '' } onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Package Ends</Form.Label>
                <Form.Control type='date' className='rounded-0 bd-all' placeholder='' name="package_endDate" defaultValue={ props.actiontype === 'update' ? convertDateFormat(props.data.package_endDate) : '' } onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Data Type</Form.Label>
                <Form.Select  className='rounded-0 bd-all' name='dataType' defaultValue={ props.actiontype==='update' ? props.data.dataType : '' } onChange={handelInp}  >
                  <option>select</option>
                  <option value="import">Import</option>
                  <option value="export">Export</option>
                  <option value="both">Both</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Package Type</Form.Label>
                <Form.Select  className='rounded-0 bd-all' name='packageType' defaultValue={ props.actiontype==='update' ? props.data.packageType : '' } onChange={handelInp}   >
                  <option>select</option>
                  <option value="pointbased">Point Based</option>
                  <option value="hsnbased">HSN Based</option>


                </Form.Select>
              </Form.Group>
              <Form.Group className='my-2 col-sm-6'>
                <Form.Label className='w-100 my-0'>Chapters</Form.Label>
                <Select options={chapterData} isMulti  className="rounded-0 bd-all " styles={{'border' : 'none'}} value={selectedChapter}  onChange={(item) => chapterChange(item)}  />
              </Form.Group>

              <Form.Group className='my-2 col-sm-4'>
                <Form.Label className='w-100 my-0'>Number of downloads</Form.Label>
                <Form.Control type='text' className='rounded-0 bd-all' placeholder='' name="no_of_download" defaultValue={ props.actiontype==='update' ? props.data.no_of_download : '' } onChange={handelInp} />
              </Form.Group>
              <Form.Group className='my-2 col-sm-4'>
                <Form.Label className='w-100 my-0'>Number of searches</Form.Label>
                <Form.Control type='text' className='rounded-0 bd-all' placeholder='' name="no_of_search" defaultValue={ props.actiontype==='update' ? props.data.no_of_search : '' } onChange={handelInp} />
              </Form.Group>
              <Form.Group className='my-2 col-sm-4'>
                <Form.Label className='w-100 my-0'>Number of points</Form.Label>
                <Form.Control type='text' className='rounded-0 bd-all' placeholder='' name="no_of_points" defaultValue={ props.actiontype==='update' ? props.data.no_of_points : '' } onChange={handelInp} />
              </Form.Group>

              <Form.Group className='my-2 col-sm-4'>
               
                <button className="btn-yellow" type='submit'>{ props.actiontype === 'update' ? 'Update' : 'Submit' }</button>
              </Form.Group>
          </Form>
        </Col>

      </Row>
    </Container>
  )
}

export default CreatePackages