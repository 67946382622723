import React, { useEffect, useState } from 'react'
import { getadminSubscriptionApi, getuserSubscriptionApi } from '../serviceapi/Service.home';
import CustomeTable from '../utils/CustomeTable';
import { Form, Modal, Table } from 'react-bootstrap';

const Subscription = () => {


    let [getData,setgetData] = useState([]);

    let [activeplans,setactiveplans] = useState([]);
    let [expiredplans,setexpiredplans] = useState([]);


    let getApi = async () =>{
        if(localStorage.getItem('approle') === "admin"){
            let res = await getadminSubscriptionApi();
            if(res.status===true){
                setgetData(res.data);
                let actiiveData = res.data?.filter((val)=> val.status == 'active')
                let expiredData = res.data?.filter((val)=> val.status !== 'active')
    
                setactiveplans( ()=>actiiveData )
                setexpiredplans( ()=>expiredData )
    
            }
        }
        else{
        let res = await getuserSubscriptionApi();
        if(res.status===true){
            setgetData(res.data);
            let actiiveData = res.data?.filter((val)=> val.status == 'active')
            let expiredData = res.data?.filter((val)=> val.status !== 'active')

            setactiveplans( ()=>actiiveData )
            setexpiredplans( ()=>expiredData )

        }
        }
        
    }
    useEffect(()=>{
        getApi();
    }, []);


    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const columns= [
        {
            name : "Plan Name",
            selector : row => row.packagename,
            sortable: true,
            width: "280px"
        },
        {
            name : "Plan Type",
            selector : row => row.packageType,
            sortable: true,
            width: "180px"
        },

        {
            name : "No of download",
            selector : row => row.no_of_download,
            sortable: true,
            width: "180px"
          },
          {
            name : "No of search",
            selector : row => row.no_of_search,
            sortable: true,
            width: "180px"
          },
          {
            name : "Start date",
            selector : row => row.package_startDate,
            sortable: true,
            width: "180px"
          },
          {
            name : "Start date",
            selector : row => row.package_endDate,
            sortable: true,
            width: "180px"
          },
          {
            name : "Points",
            selector : row => row.no_of_points,
            sortable: true,
            width: "180px"
          },
          {
            name : "Email",
            selector : row => row.email,
            sortable: true,
            width: "280px"
          },

          {
            name : "Used search",
            selector : row => row.used_search,
            sortable: true,
            width: "180px"
          },

          {
            name : "Used download",
            selector : row => row.used_download,
            sortable: true,
            width: "180px"
          },

          {
            name : "Expired",
            selector : row => row.Expired,
            sortable: true,
            width: "180px"
          },

          {
            name : "Status",
            selector : row => row.status,
            sortable: true,
            width: "180px"
          },

          {
            name : "Action",
            selector : row => <button className="btn-save" onClick={()=>{handleShow()}}> <i class="fa-solid fa-arrow-up i-x"></i> Renew</button>,
            sortable: true,
            width: "180px"
          },


        {
          name : "Created At",
          selector : row => row.created_at.slice(0,10),
          sortable: true,
        },
                  
                    
      ]

   
  return (<>
  
  <div className="container-fluid subscription-container">
    <div className="row block1">
        <div className="col-12">
            <div className="table-responsive">

            <CustomeTable columns={columns} filterData={activeplans} search_data={false} tableTitle={'Active Subscriptions'} />
            </div>
        </div>

        { expiredplans.length > 0 && <div className="col-12 my-md-4 my-3">
            <div className="table-responsive">
                <p className='bg-dark my-0 px-2 py-1 text-white' >Expired Subscriptions</p>
                <table className="table table-hover">
                <thead className="thead-dark">
                        <tr>
                        <td>Plan Name</td>
                            <td>Plan Type</td>
                            <td>No of download</td>
                            <td>No of search</td>
                            <td>Validaty</td>
                            <td>Points</td>
                            <td>Status</td>
                            <td>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                    { expiredplans?.map((val,index)=>{
                            return(<>
                            <tr key={index}>
                            <td>{val.packagename}</td>
                            <td>{val.packageType}</td>
                            <td>{val.no_of_download}</td>
                            <td>{val.no_of_search}</td>
                            <td>{val.package_startDate}</td>
                            <td>{val.no_of_points}</td>
                            <td>{val.Expired}</td>
                            <td><div  className='icon-tag'> <i class="fa-solid fa-pen cursor-pointer i-x"></i> <i class="fa-regular fa-trash-can cursor-pointer i-x"></i></div></td>
                        </tr>
                            </>)
                        })}
                       
                    </tbody>
                </table>
            </div>
        </div>}


     
    </div>
  </div>
  <Modal size='xl' show={show} onHide={handleClose}>
 <Modal.Header closeButton>
   <Modal.Title>Renew Supscription</Modal.Title>
 </Modal.Header>
 <Modal.Body>


                      <Table bordered hover responsive striped >
                        <thead>
                          <tr>
                            <td className='tx-2 text-uppercase' style={{minWidth : 150}}>Plan Features</td>
                            <td className='tx-2 text-uppercase' style={{minWidth : 200}}>Basic</td>
                            <td className='tx-2 text-uppercase' style={{minWidth : 150}}>Growth</td>
                            <td className='tx-2 text-uppercase' style={{minWidth : 150}}>Corporate</td>
                          </tr>
                        </thead>


                        <tbody>
                          <tr>
                            <td>Cost</td>
                            <td>750 USD</td>
                            <td>1200 USD</td>
                            <td>1800 USD</td>
                          </tr>

                          <tr>
                            <td>Validity </td>
                            <td>1 Year</td>
                            <td>1 Year</td>
                            <td>1 Year</td>
                          </tr>
                          <tr>
                            <td>Download Points</td>
                            <td>0 </td>
                            <td>100000</td>
                            <td>500000</td>
                          </tr>


                          <tr>
                            <td>Workspace</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                          </tr>
                          <tr>
                            <td>Searches</td>
                            <td>5000</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                          </tr>

                       

                          <tr>
                            <td>Analysis</td>
                            <td>No </td>
                            <td>Yes</td>
                            <td>Yes</td>
                          </tr>

                          <tr>
                            <td>Data Access</td>
                            <td>Limited</td>
                            <td>Limited</td>
                            <td>All</td>
                          </tr>
                          <tr>
                            <td>User  Access</td>
                            <td>No</td>
                            <td>No</td>
                            <td>Yes</td>
                          </tr>

                          <tr>
                            <td>Display Values </td>
                            <td>All</td>
                            <td>All</td>
                            <td>All</td>
                          </tr>

                          <tr>
                            <td>Buyer Supplier Profile access</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>Buyer Supplier Contact Details</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td> <button className='btn-green'>BUY NOW</button> </td>
                            <td> <button className='btn-green'>BUY NOW</button> </td>
                            <td> <button className='btn-green'>BUY NOW</button> </td>
                          </tr>

                       


                        </tbody>


                      </Table>
      {/* <div className="modal-body">
            <div className="card-body">
            <div className="title-box">
            <h4>One Subscription - Multiple Users - 78+ Countries And More Added Every Month!</h4>
<small>Honest usage based pricing - irrespective of numbers of countries or users.</small>
            </div>
            <div className="row my-4">
                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
  <li className="list-group-item list-group-item-action active">Subscription Plan 1</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Free Trial</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>

                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
  <li className="list-group-item list-group-item-action active">Subscription Plan 2</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Start Up</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>

                <div className="col-md-4">
                    <ul>
                    <ul className="list-group rounded-0">
                  
  <li className="list-group-item list-group-item-action active">Subscription Plan 3</li>
  <li className="list-group-item d-flex align-items-center justify-content-center"> <button className='btn-all bg-dark'>Standared</button> </li>
  <li className="list-group-item">Dapibus ac facilisis in</li>
  <li className="list-group-item">Morbi leo risus</li>
  <li className="list-group-item">Porta ac consectetur ac</li>
  <li className="list-group-item">Vestibulum at eros</li>
</ul>
                    </ul>
                </div>
            </div>
            </div>
      </div> */}
     </Modal.Body>
             </Modal>
  
  </>)
}

export default Subscription