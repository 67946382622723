import React from 'react';
import {Helmet} from "react-helmet";

const HelmetTitle = (props) => {
  return (<>
  
    <Helmet>
        <meta charSet="utf-8" />
        <title>{props.meta_title}</title>
        <meta name="description" content={props.meta_description} />
        <meta name="keywords" content={props.meta_keyword} />
        <link rel="icon" href={props.icon} />

    </Helmet>
  
  
  </>)
}

export default HelmetTitle