import axios from "axios"

export const userID = localStorage.getItem('userID');
export const userToken = localStorage.getItem('access_token');

export const config = {
    headers : {    
    'Authorization' :`Bearer ${userToken}`,
    'userID' : userID
    }
}

export const loginApi = async(apidata) =>{
    try{
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/login` , apidata);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const registerApi = async(apidata) =>{
    try{
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/register` , apidata);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const profileApi = async(apidata,apptoken) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/getprofiledata`,
            {
                user_id : apidata,
                apptoken : apptoken
            }
          
            , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}




export const profileUpdateApi = async(apidata) =>{
    try{    
        
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
        
        let formData = new FormData();
            formData.append('firstname' , apidata.firstname)
            formData.append('lastname' , apidata.lastname)
            formData.append('email' , apidata.email)
            formData.append('user_id' , localStorage.getItem('userID'))
            formData.append('username' , apidata.username)
            formData.append('phone' , apidata.phone)
            formData.append('profile_image' , apidata.profile_image)
            formData.append('sales_executive' , apidata.sales_executive)
            formData.append('designation' , apidata.designation)
            formData.append('company' , apidata.company)




            formData.append('apptoken' , localStorage.getItem('apptoken'))
            // formData.append('_method' , 'PATCH')



            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/update`, formData  , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const allUserUpdateApi = async(apidata) =>{
    try{    
        
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
        
        let formData = new FormData();
            formData.append('firstname' , apidata.firstname)
            formData.append('lastname' , apidata.lastname)
            formData.append('email' , apidata.email)
            formData.append('user_id' , apidata.user_id)
            formData.append('username' , apidata.username)
            formData.append('phone' , apidata.phone)
            formData.append('profile_image' , apidata.profile_image)
            formData.append('sales_executive' , apidata.sales_executive)
            formData.append('designation' , apidata.designation)
            formData.append('company' , apidata.company)
            formData.append('apptoken' , localStorage.getItem('apptoken'))
            // formData.append('_method' , 'PATCH')



            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/update`, formData  , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const resetpasswordApi = async (oldpassword,newpassword) =>{
    try{   
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
        
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/changepassword`, {
         
                oldpassword: oldpassword,
                password: newpassword,
                user_id: userID,
                apptoken :  localStorage.getItem('apptoken')

            } , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const userStatusApi = async(userID,apidata) =>{
    try{    
        
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }




            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/changestatus`,{
                user_id: userID,
                status: apidata
            }   , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const userFolderApi = async(apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID'),
            'apptoken' : localStorage.getItem('apptoken')
            }
        }

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/getfolders`,
            { apptoken :  localStorage.getItem('apptoken'),
            user_id : localStorage.getItem('userID'),
            },
           
           config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const userFolderCreateApi = async(apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/foldercreation`,
             {
            name:apidata,  
            user_id : localStorage.getItem('userID'),
            apptoken :  localStorage.getItem('apptoken')}
             ,config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const userFolderUpdateApi = async(apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.patch(`${process.env.REACT_APP_APIURL}/folderupdation`, apidata , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const userFolderDeleteApi = async(apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/folderdeletion`,
             { 
            apptoken :  localStorage.getItem('apptoken'),
             user_id : localStorage.getItem('userID'),
             folderid : apidata
            }, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


// export const exportUploadDataApi = async(apidata) =>{
//     try{  

//         const config = {
//             headers : {    
//             'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
//             'userID' : localStorage.getItem('userID')
//             }
//         }

//         let formData = new FormData();
//         formData.append('file' , apidata);
//         formData.append('user_id' , userID);
//         formData.append('apptoken' , localStorage.getItem('apptoken'))


//             let response = await axios.post(`${process.env.REACT_APP_APIURL}/file-export`, formData, config);
//             return response.data
//     }
//     catch(err){
//         return err.response.data
//     }
// }


export const exportUploadDataApi = async(apidata,setProgress2) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('file' , apidata);
        formData.append('user_id' , userID);
        formData.append('apptoken' , localStorage.getItem('apptoken'))


            let response = await axios.post(`${process.env.REACT_APP_APIURL}/file-export`, formData, {
            onUploadProgress: (progressEvent) => {
              const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
              setProgress2(percentComplete.toFixed(2)); // Keep two decimal places

            },
          } , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const importUploadDataApi = async(apidata,setProgress1) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('file' , apidata);
        formData.append('user_id' , userID);
        formData.append('apptoken' , localStorage.getItem('apptoken'))


            let response = await axios.post(`${process.env.REACT_APP_APIURL}/file-import` , formData , {
                onUploadProgress: (progressEvent) => {
                  const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
                  setProgress1(percentComplete.toFixed(2)); // Keep two decimal places
                },
              }, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const keywordsUploadDataApi = async(apidata,setProgress1) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('file' , apidata);
        formData.append('user_id' , userID);
        formData.append('apptoken' , localStorage.getItem('apptoken'))


            let response = await axios.post(`${process.env.REACT_APP_APIURL}/ImportKeywords` , formData , {
                onUploadProgress: (progressEvent) => {
                  const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
                  setProgress1(percentComplete.toFixed(2)); // Keep two decimal places
                },
              }, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const deletekeywordsUploadDataApi = async(apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
        let response = await axios.post(`${process.env.REACT_APP_APIURL}/removeKeywords` , apidata, config);
        return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const confirmUploadDataApi = async(apidata,setProgress1) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('file' , apidata);
        formData.append('user_id' , userID);
        formData.append('apptoken' , localStorage.getItem('apptoken'))


            let response = await axios.post(`${process.env.REACT_APP_APIURL}/Overwrite` , formData , {
                onUploadProgress: (progressEvent) => {
                  const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
                  setProgress1(percentComplete.toFixed(2)); // Keep two decimal places
                },
              }, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const searchApi = async (apidata) =>{
    try{  

        const config = {
            headers : {   
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        apidata.searchtext = apidata.searchtext.map((item)=> item.label);

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/search-data` ,apidata, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const searchHistoryDataApi = async (apidata) =>{
    try{  

        const config = {
            headers : {   
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/search-data` ,apidata, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}



/* user filter */


export const userAllApi = async (apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

            let response = await axios.get(`${process.env.REACT_APP_APIURL}/users`, config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}

export const userRegApi = async (apidata) =>{
    try{  

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/register` , 
            apidata
            , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


/* data filter */
export const datafilterApi = async (apidata) =>{
    try{  
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/getfilter`,
            {
                ddtype : `${apidata}`
            }
            , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}


export const roleaddApi = async(apidata,rolename,apptoken) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/roleadd`,
            {
                user_id : apidata,
                apptoken : apptoken,
                name : rolename
            }
          
            , config);
            return response.data
    }
    catch(err){
        return err.response.data
    }
}



/* package */

export const allpackageApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/packagelist`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const addpackageApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/addpackage`,
            apidata
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const editpackageApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/updatepackage`,
            apidata
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const assignpackageApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/asignpackage`,
            apidata
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const deletepackageApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/deletepackage`,
            apidata
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

/* announcement */
export const addannouncementApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/addannounce`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
                title: apidata.title,
                notice: apidata.notice
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const editannouncementApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/editannounce`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
                id: apidata.id,
                title: apidata.title,
                notice: apidata.notice
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const statusannouncementApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/changeannouncestatus`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
                id: apidata.id,
                status:apidata.status
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const allannouncementApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/announcelist`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


/* search hsn */

export const searchHsnApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/searchhsn`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
                type: apidata.type,
                searchtext: apidata.searchtext,
                datatype: apidata.datatype,
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

/* get xl uploaded api */

export const getXlApi = async() =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/getxlupload`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const getKeywordsXlApi = async() =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.get(`${process.env.REACT_APP_APIURL}/getKeywords`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const deleteXlApi = async(apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/deletexlupload`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
                id : apidata
            }
          
            , config);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}




/* get sitesettings api */

export const postSitesettingsApi = async (apidata) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/settingsupdate`,apidata , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const getSitesettingsApi = async () =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.get(`${process.env.REACT_APP_APIURL}/getsettingdetails` , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


/* get chapter api */

export const getChapterApi = async () =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/chapterlist`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            } , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


/* autocomplete api */

export const autocompleteApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/autocomplete`,
            collectData , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}



/* savedata api */

export const savedataApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/savedata`,
            collectData , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const downnloaddataApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/exportToExcel`,
            collectData , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const savedworkspaceApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/getsavedata`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}




export const supportApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.get(`${process.env.REACT_APP_APIURL}/supportTicketList`,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const supportCreateApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('subject' , collectData.subject ) ;
        formData.append('department' , collectData.department ) ;
        formData.append('priority' , collectData.priority ) ;
        formData.append('message' , collectData.message ) ;
        formData.append('user_id' , collectData.user_id) ;
        formData.append('attachment' , collectData.attachment ) ;


        let response = await axios.post(`${process.env.REACT_APP_APIURL}/insertSupportticket`,formData,config
        );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const supportPutApi = async (collectData) =>{
    try{


        
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let formData = new FormData();
        formData.append('subject' , collectData.subject ) ;
        formData.append('ticket_id' , collectData.ticket_id ) ;
        formData.append('department' , collectData.department ) ;
        formData.append('priority' , collectData.priority ) ;
        formData.append('message' , collectData.message ) ;
        formData.append('user_id' , collectData.user_id) ;
        formData.append('attachment' , collectData.attachment ) ;

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/updateSupportticket`,formData,config
        
        );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const supportDeleteApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/deleteSupportticket`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const supportStatusApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/updateTicketStatus`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const supportreplyApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/insertTicketReplyData`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const supportreplylistApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/ticketReply`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const supportuserlistApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/userSupportticket`,collectData,config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const suggestionApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/Getautocomplete`,collectData,config,);
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


/* get accesslog api */

export const getaccesslogApi = async (collectData) =>{
    try{

        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }
            let response = await axios.get(`${process.env.REACT_APP_APIURL}/LoginHistory?${collectData}`,
            {
                apptoken: localStorage.getItem('apptoken'),
                user_id: localStorage.getItem('userID'),
            } , config
          
            );
            return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const forgetpasswordApi = async (collectData) =>{
    try{
        let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/initiatetoken`, collectData );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}



export const forgetresetpasswordApi = async (collectData) =>{
    try{
        let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/forgotpassword`, collectData );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}




export const getuserSubscriptionApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/GetSubscriptionPlan`, {user_id : localStorage.getItem('userID')} , config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const getadminSubscriptionApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.get(`${process.env.REACT_APP_APIURL}/user/manageSubscriptionPlanbyID`, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const getDownloadHistoryApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.get(`${process.env.REACT_APP_APIURL}/ShowHistory?user_id=${localStorage.getItem('userID')}&type=download&days=${collectData || 30}`, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}



export const getNotificationApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/AddNotification`, collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const addNotificationApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/AddNotification`, collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const editNotificationApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/EditNotification`, collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const getadminNotificationApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/getAllNotifications`, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}
export const deleteNotificationApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/deleteNotification`,collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}


export const getFaqApi = async () =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.get(`${process.env.REACT_APP_APIURL}/FaQ`, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const deleteFaqApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/deletefAQ`,collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const addFaqApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/AddFaQ`, collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}

export const updateFaqApi = async (collectData) =>{
    try{
        const config = {
            headers : {    
            'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
            'userID' : localStorage.getItem('userID')
            }
        }

        let response = await axios.post(`${process.env.REACT_APP_APIURL}/EditFaQ`, collectData, config );
        return response.data
    }
    catch(err){
        return  err.response.data
    }
}




    export const aftersearchFilterApi = async (apidata) =>{
        try{

            const config = {
                headers : {   
                'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
                'userID' : localStorage.getItem('userID')
                }
            }

            // apidata.searchtext = apidata.searchtext.map((item)=> item.label);
            let response = await axios.post(`${process.env.REACT_APP_APIURL}/search-data-filter` ,apidata, config);
            return response.data;
            
        }
        catch(err){
            return err.response.data
        }
    }



    export const showhistorydataApi = async(apidata) =>{
        try{  
    
            const config = {
                headers : {    
                'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
                'userID' : localStorage.getItem('userID'),
                'apptoken' : localStorage.getItem('apptoken')
                }
            }
    
                let response = await axios.get(`${process.env.REACT_APP_APIURL}/ShowHistory?user_id=${localStorage.getItem('userID')}&type=${apidata.type}&days=${apidata.days}`,
               config);
                return response.data
        }
        catch(err){
            return err.response.data
        }
    }


    export const userdashbaorddataApi = async(apidata) =>{
        try{  
    
            const config = {
                headers : {    
                'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
                'userID' : localStorage.getItem('userID'),
                'apptoken' : localStorage.getItem('apptoken')
                }
            }
    
                let response = await axios.post(`${process.env.REACT_APP_APIURL}/user/Dashboard`,{ user_id : localStorage.getItem('userID')},
               config);
                return response.data
        }
        catch(err){
            return err.response.data
        }
    }




    export const searchhistorybyfolderApi = async(apidata) =>{
        try{  
    
            const config = {
                headers : {    
                'Authorization' :`Bearer ${localStorage.getItem('access_token')}`,
                'userID' : localStorage.getItem('userID'),
                'apptoken' : localStorage.getItem('apptoken')
                }
            }
    
                let response = await axios.get(`${process.env.REACT_APP_APIURL}/SearchtextByFolderId?user_id=${localStorage.getItem('userID')}&folder_id=${apidata.folder}&days=${apidata.days}`,
               config);
                return response.data
        }
        catch(err){
            return err.response.data
        }
    }